import {HEADERMENU,LOGIN,REGISTER,LOGOUT,USERDETAILS, ORDERS,SHIPPINGADRESS} from '../constants';

export const hederMenu = data => {
    return{
        type : HEADERMENU,
        data : data
    }
}

export const register = (data) =>{
    return {
        type:REGISTER,
        data:data
    }
} 

export const login = (data1) =>{
    return {
        type:LOGIN,
        data1
    }
} 

export const logout = () =>{
    return {
        type:LOGOUT
    }
}

export const userDetail = (data1) =>{
    return {
        type:USERDETAILS,
        data1
    }
} 

export const addDeliveryAddress = (data1) =>{
    return {
        type:USERDETAILS.SHIPPINGADRESS,
        data1
    }
} 


export const OrderData = (data1) =>{
    return {
        type:ORDERS,
        data1
    }
} 