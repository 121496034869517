import { createContext } from 'react';

export const CartContext = createContext({
    cartCount: 0,
    setCartCount: () => { },
});
export const SubTotalContext = createContext({
    subTotal: 0,
    setSubTotal: () => { },
});

export const WishListCountContext = createContext({
    wishListCount: 0,
    setWishListCount: () => { },
});

export const SearchContext = createContext({
    search: 0,
    setSearch: () => { },
});

export const AuthUserContext = createContext({
    authUser: 0,
    setAuthUser: () => { },
});

export const BreadCrumbsContext = createContext({
    breadCrumbValue: 0,
    setBreadCrumbValue: () => { },
});
