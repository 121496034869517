import Home from "../Component/Home";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { getDataById, getAllData } from "../../src/Component/SupplierServices";

const mapStatetoProps = state => ({
    data:state
})

const mapDispatchToProps = dispatch => ({
    GetWishListByCustomerId: async (id) => {
        return getDataById('cart/getWishListByCustomer',id);
    },
    GetFetchFeaturedProducts: async () => {
        return getAllData('product/featuredProducts');
    },
    GetMostSellingProducts: async () => {
        return getAllData('product/bestSellerProducts');
    },
  
})

export default connect(mapStatetoProps,mapDispatchToProps)(Home)