import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "react-bootstrap";
import ForgotPassword from './ForgotPassword';
import VerifyEmail from "./VerifyEmail";
import NewPassword from "./NewPassword";
import { environment } from "../environment";
import { CartContext,SubTotalContext, AuthUserContext,WishListCountContext } from "../Component/CartContext";
import { Context } from "../Component/LoadingContext";
import { calculateSubTotal } from "../Component/Common";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
export default function Login(props) {
  const location = useLocation(); // Hook to access the current location
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [error, setError] = React.useState(false);
  const [isCustomer, setcustomner] = React.useState(true);
  const [showEmailModal, setEmailModal] = React.useState(false);
  const [showVerifyEmailModal, setVerifyEmailModal] = React.useState(false);
  const [showNewPasswordModal, setNewPasswordModal] = React.useState(false);
  const [tempStoreEmail, settempStoreEmail] = useState("");
  const [emailerror, setemailError] = React.useState(false);
  const [cartItems, setCartItems] = React.useState([]);
  const { cartCount, setCartCount } = useContext(CartContext);
  const [loading, setLoading] = useContext(Context);
  const { subTotal, setSubTotal } = useContext(SubTotalContext);
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { wishListCount, setWishListCount } = useContext(WishListCountContext);
  const handleEmailModalClose = () => setEmailModal(false);
  const handleEmailModalShow = () => setEmailModal(true);
  const handleVerifyEmailModalClose = () => setVerifyEmailModal(false);
  const handleVerifyEmailModalShow = () => setVerifyEmailModal(true);
  const handleNewPasswordModalClose = () => setNewPasswordModal(false);
  const handleNewPasswordModalShow = () => setNewPasswordModal(true);
  let auth = null;
  let UpdatedcartData = [];
  let tempCartData = [];


  function isValidEmail() {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErrorEmail(true) || setPasswordError(true)
      return false;
    } else {

      if (!isValidEmail(email)) {
        setemailError(true);
        return;
      } else {
        setLoading(1);
        setError(null);
        props.loginHandler({ email, password, isCustomer }).then(async (val) => {
          if (val.success) {
            setAuthUser(val?.data);
            if (localStorage.getItem('authUser')) {
              auth = JSON.parse(localStorage.getItem('authUser'));
            }
            if (auth) {
              if (location.pathname !== "/" )  {
              getWishListByCustomer();
              }              
              if (cartItems && cartItems.length > 0) {
                props.AddtoCart(cartItems).then((res) => {
                  if (res.success) {

                    localStorage.removeItem('cartItems');
                    setCartItems([]);
                    props.GetCartProductByUser().then((ls) => {
                      // setCartItems(ls.data);

                      UpdatedcartData = ls.data;
                      if (UpdatedcartData && UpdatedcartData.length > 0) {
                        tempCartData = [];
                        UpdatedcartData.forEach((ls) => {

                          let CartObj = {
                            id: null,
                            quantity: '',
                            isSaveOrLater: false,
                            isActive: "true",
                            isDeleted: "false",
                            customer: { id: null },
                            product: {
                              id: null,
                              name: null
                            },
                            productVariantCombinations: { id: null, price: 0, weight: 0, productVariantDetails: [] }
                          };
                          CartObj.id = ls.id;
                          CartObj.quantity = ls.quantity;
                          CartObj.customer.id = auth.referenceId;
                          CartObj.product.id = ls.product.id;
                          CartObj.product.name = ls.product.name;
                          CartObj.productVariantCombinations.id = ls.productVariantCombinations.id;
                          CartObj.productVariantCombinations.price = ls.productVariantCombinations.price;
                          CartObj.productVariantCombinations.weight = ls.productVariantCombinations.weight;
                          if (ls.productVariantCombinations.productVariantDetails && ls.productVariantCombinations.productVariantDetails.length > 0) {
                            ls.productVariantCombinations.productVariantDetails.forEach((res) => {
                              let attrObj = {};
                              attrObj.attribute = res.attribute.name;
                              attrObj.attributeValue = res.attributeValue.name;
                              CartObj.productVariantCombinations.productVariantDetails.push(attrObj);
                            })
                          }

                          tempCartData.push(CartObj);
                        })
                      }
                      setCartItems(tempCartData);
                      setCartCount(tempCartData.length);
                      const subtotal = calculateSubTotal(tempCartData);
                      setSubTotal(subtotal);
                      localStorage.setItem("cartItems", JSON.stringify(tempCartData));
                    })
                  }
                })
              } else {
                props.GetCartProductByUser().then((ls) => {
                  // setCartItems(ls.data);

                  UpdatedcartData = ls.data;
                  if (UpdatedcartData && UpdatedcartData.length > 0) {
                    tempCartData = [];
                    UpdatedcartData.forEach((ls) => {
                      let CartObj = {
                        id: null,
                        quantity: '',
                        isSaveOrLater: false,
                        isActive: "true",
                        isDeleted: "false",
                        customer: { id: null },
                        product: {
                          id: null,
                          name: null
                        },
                        productVariantCombinations: { id: null, price: 0, productVariantDetails: [] }
                      };
                      CartObj.id = ls.id;
                      CartObj.quantity = ls.quantity;
                      CartObj.customer.id = auth.referenceId;
                      CartObj.product.id = ls.product.id;
                      CartObj.product.name = ls.product.name;
                      CartObj.productVariantCombinations.id = ls.productVariantCombinations.id;
                      CartObj.productVariantCombinations.price = ls.productVariantCombinations.price;
                      CartObj.productVariantCombinations.weight = ls.productVariantCombinations.weight;
                      if (ls.productVariantCombinations.productVariantDetails && ls.productVariantCombinations.productVariantDetails.length > 0) {
                        ls.productVariantCombinations.productVariantDetails.forEach((res) => {
                          let attrObj = {};
                          attrObj.attribute = res.attribute.name;
                          attrObj.attributeValue = res.attributeValue.name;
                          CartObj.productVariantCombinations.productVariantDetails.push(attrObj);
                        })
                      }

                      tempCartData.push(CartObj);
                    })
                  }
                  setCartItems(tempCartData);
                  setCartCount(tempCartData.length);
                  const subtotal = calculateSubTotal(tempCartData);
                  setSubTotal(subtotal);
                  localStorage.setItem("cartItems", JSON.stringify(tempCartData));
                })
              }
            }
            const cartData = localStorage.getItem('cartItems');
            // if (cartData && JSON.parse(cartData).length > 0) {

            //   setTimeout(() => {
            //     navigate("/checkout");
            //   }, 500);
            // } else {

            //   setTimeout(() => {
            //     navigate("/");
            //   }, 500);
            // }
            if (location.pathname === "/login" || location.pathname === "/profile"  || location.pathname === "/myOrder" || location.pathname === "/shipping-address-list")  {
              setTimeout(() => {
                navigate("/");
              }, 100);
            }
            window.scrollTo(0, 0);
            props.onLoginSuccess();
          }
          setLoading(0);
        }).catch((e) => {
          setLoading(0);
          setError(true);
        });
        environment.checkoutRedirect = false;
      }

    }
  }

  const getWishListByCustomer = async () => {
    if (props?.data?.UserDetail?.id) {
        props.GetWishListByCustomerId(props?.data?.UserDetail?.id).then((res) => {
            if (res.success && res.data) {
                setWishListCount(res?.data?.length);
                return
            } else {
                setWishListCount(0);
                return
            }
        })
    } else {
        setWishListCount(0);
        return
    }
}


  useEffect(() => {
    if (localStorage.getItem('cartItems')) {
      setCartItems(JSON.parse(localStorage.getItem('cartItems')));
    } else {
      setCartItems([]);
    }
  }, [])

  const getMail = (data) => {

    settempStoreEmail(data)
    handleEmailModalClose();
    handleVerifyEmailModalShow();
  }

  const onVerify = () => {
    handleVerifyEmailModalClose();
    handleNewPasswordModalShow();
  }

  const onNewPwdFun = () => {
    handleNewPasswordModalClose();
  }

  // const storedToken = localStorage.getItem("token");
  // useEffect(() => {
  //   if (storedToken) {
  //     navigate("/");

  //   } else {

  //   }
  // })
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const handleChangeEmail = (e) => {
    const inputValue = e.target.value;
    if (/^\S+@\S+\.\S+$/.test(inputValue)) {
      setEmail(inputValue);
      setErrorEmail(false);
      setEmailError(false);
    } else if (inputValue === '') {
      setEmail('');
      setErrorEmail(true);
      setEmailError(false);
    } else {
      setEmail(inputValue);
      setErrorEmail(false);
      setEmailError(true);
    }
  };
  const [passwordError, setPasswordError] = useState(false);
  const onChangePassword = (e) => {
    const value = e.target.value;
    setPass(value);
    if (!value) {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>

      <div className="login-popup">
        <div className="form-box">
          <div className="tab tab-nav-simple tab-nav-boxed form-tab">
            <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5" role="tablist">
              <li className="nav-item">
                <p className="nav-link active border-no lh-1 ls-normal anchorBlack">
                Login
                  </p>
              </li>
            </ul>
            <div className="login">
              <div className="tab-content">

                <div className="tab-pane active" id="signin">
                  <form action="#">
                    <div className="form-group mb-4">
                      <label className="simple-label" style={{ fontSize: '15px' }}>Email Address&nbsp;<small style={{ color: 'red' }}>*</small></label>
                      <input type="email" value={email} onChange={handleChangeEmail}
                        className="form-control" id="login-email" name="login-email" placeholder="Email Address" required />
                      {errorEmail && !email && <><label className="error-label">Email Address is required</label><br /></>}
                      {emailError && email && <><label className="error-label SetFont">Email Address is invalid</label><br /></>}

                      <label className="simple-label" style={{ fontSize: '15px' }}>Password&nbsp;<small style={{ color: 'red' }}>*</small></label>
                      <div className="password-input-container">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={onChangePassword}
                          className="form-control"
                          id="login-password"
                          name="login-password"
                          placeholder="Password"
                          required
                        />
                        <span
                          className="password-toggle-icons"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>

                      {passwordError && !password && <><label className="error-label">Password is required</label><br /></>}
                    </div>

                 
                 

                    <div className="form-footer">
                      <div className="form-checkbox">
                        <input type="checkbox" className="custom-checkbox" id="signin-remember" name="signin-remember" />
                        <label className="form-control-label" htmlFor="signin-remember">Remember
                          me</label>
                      </div>
                      <a className="form-control-label anchorlightBlack" style={{ cursor: 'pointer' }} onClick={handleEmailModalShow}>Lost your password?</a>
                    </div>
                    <button className="btn btn-dark btn-block btn-rounded" type="submit" onClick={handleSubmit}>Login</button>

                    {/*Send Email Modal*/}
                    <Modal show={showEmailModal} animation={false} onHide={handleEmailModalClose}>
                      <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}>Send Mail
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleEmailModalClose}></button>
                      </Modal.Header>
                      <ForgotPassword sendMail={getMail} />
                    </Modal>

                    {/*Verify Email Modal*/}
                    <Modal show={showVerifyEmailModal} animation={false} onHide={handleVerifyEmailModalClose}>
                      <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}>Verify Email
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleVerifyEmailModalClose}></button>
                      </Modal.Header>
                      <VerifyEmail verifiedEmail={tempStoreEmail} verifyOTP={onVerify} />
                      {/* <ForgotPassword sendMail={getMail}/> */}
                    </Modal>

                    {/*Create New Password*/}
                    <Modal show={showNewPasswordModal} animation={false} onHide={handleNewPasswordModalClose}>
                      <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}>Reset Password
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleNewPasswordModalClose}></button>
                      </Modal.Header>
                      <NewPassword verifiedEmail={tempStoreEmail} onNewPwd={onNewPwdFun} />
                      {/* <ForgotPassword sendMail={getMail}/> */}
                    </Modal>
                    <ToastContainer></ToastContainer>
                  </form>
                  <div className="form-choice text-center">
                    <label className="ls-m">or Login With</label>
                    <div className="social-links">
                      <a href="#" title="social-link" className="social-link social-google fab fa-google border-no"><FontAwesomeIcon icon={faGoogle} /></a>
                      <a href="#" title="social-link" className="social-link social-facebook fab fa-facebook-f border-no"><FontAwesomeIcon icon={faFacebook} /></a>
                      <a href="#" title="social-link" className="social-link social-twitter fab fa-twitter border-no"><FontAwesomeIcon icon={faTwitter} /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    </>
  )
}


