import React, { useState, useEffect,useContext } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { Context } from "../Component/LoadingContext";

export default function (props) {
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [mobileNo, setMobile] = useState("");
    //const [shippingAddresses, setShippingAddresses] = useState([]);
    const [error, setError] = React.useState(false);
    const [mobileerror, setMobileError] = React.useState(false);
    const [loading,setLoading ] = useContext(Context);
    let shippingAddresses = [];
    let auth = null;
    let userId = null;
    if (localStorage.getItem('authUser')) {
        auth = JSON.parse(localStorage.getItem('authUser'));
        userId = auth.referenceId;
    }

    let UserData = {};
    UserData = useSelector(state => state.UserDetail);
    useEffect(() => {
        if (UserData) {
            setId(UserData.id);
            setName(UserData.name)
            setMobile(UserData.mobileNo)
            setEmail(UserData.email)
        }

    }, [UserData])



    function isValidEmail() {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !mobileNo || !email) {
            setError(true);
            return false;
        } else {

            if (mobileNo && mobileNo.length !== 10) {
                setMobileError(true);
                return false
            }
            else if (!isValidEmail(email)) {
                setError("Email is invalid");
            } else {
                setError(null);
                setMobileError(false);
                setLoading(1);
                props.ShippingAddressHandler(userId).then((res) => {
                    if(res.success == true || mobileNo == res.data){
                        setLoading(0);
                        shippingAddresses = res.data;
                        setLoading(1);
                        props.UpdateProfileHandler({id,name,mobileNo,email,shippingAddresses}).then((result)=>{
                            if(result.success){
                                setLoading(0);
                            } else {
                                setLoading(0);
                            }
                        })
                    }else{
                        setLoading(0);
                    }
                });
            }
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <main className="main account">
                    {/* <nav className="breadcrumb-nav">
                        <div className="container" style={{ fontSize: '15px' }}>
                            <ul className="breadcrumb">
                                <li><Link to={"/"}><i className="d-icon-home" style={{fontSize:'20px'}}/></Link></li>
                                <li>&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleRight} />&nbsp;&nbsp;Profile</li>
                            </ul>
                        </div>
                    </nav> */}
                    <div className="page-content mt-4 pb-6 ml-15">
                        <div className="container">

                            <div className="tab tab-vertical gutter-lg">

                                <div className="d-flex flex-column align-items-center text-center align-self-center col-lg-12">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin"
                                        className="rounded-circle" width="150" />
                                    <div className="mt-3">
                                        <h4>{UserData.name}</h4>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 style={{ textAlign: 'left' }}>PERSONAL DETAILS</h1>
                                <form className="theme-form">
                                    <div className="row gutters-sm">
                                        <div className="form-group col-md-6 mt-4">
                                            <b><label htmlFor="name" style={{ textAlign: 'left' }}>Name</label></b>
                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="name" placeholder="Enter Your First Name" required />
                                            {error && !name && <label className="error-label">Name is required</label>}
                                        </div>
                                        {/* <div className="col-md-6">
                            <label for="email">Last Name</label>
                            <input type="text" className="form-control" id="last-name" placeholder="Enter Your Last Name" required="">
                        </div> */}
                                        <div className="form-group col-md-6 mt-4">
                                            <b><label htmlFor="review" style={{ textAlign: 'left' }}>Mobile No</label></b>
                                            <input type="number" value={mobileNo} onChange={(e) => setMobile(e.target.value)} className="form-control" id="review" placeholder="Enter mobile no" required />
                                            {error && !mobileNo && <label className="error-label">Mobile No. is required</label>}
                                            {mobileerror && <label className="error-label">Mobile No. should be 10 digit</label>}
                                        </div>
                                        <div className="form-group col-md-6 mt-4">
                                            <b><label htmlFor="email" style={{ textAlign: 'left' }}>Email</label></b>
                                            <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email" required disabled/>
                                            {error && !email && <><label className="error-label">Email is required</label></>}
                                            {error && email && <><label className="error-label">{error}</label></>}
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <button type="submit" className="btn btn-primary mt-4" style={{ float: 'left',fontSize: '13px' }} onClick={handleSubmit}><b>UPDATE</b></button>
                                        </div>
                                        <ToastContainer style={{fontSize:'15px'}}></ToastContainer>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
