import React, { useState, useContext,useEffect  } from 'react'
import { Badge } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { Modal } from "react-bootstrap";
import LoginContainer from '../../Containers/LoginContainer';
import { ErrorMsg } from "../messages/AllMessages";


export default function ReviewList(props) {
  const [toggleForm, setToggleForm] = useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const handleLoginModalClose = () => setShowLoginModal(false);
  const handleLoginModalShow = () => setShowLoginModal(true);
  //const { authUser, setAuthUser } = useContext(AuthUserContext);
//   useEffect(() => {
//     setAuthUser(getAuthUser);
// }, []);

// const getAuthUser = () => {
//     if (localStorage.getItem('authUser')) {
//        return JSON.parse(localStorage.getItem('authUser'));
//     }
//     return null;
// }
let auth = null;
if (localStorage.getItem('authUser')) {
  auth = JSON.parse(localStorage.getItem('authUser'));
}


  const handleToggleForm = () => {
    if (auth && auth.referenceId) {
      setToggleForm(!toggleForm);
      props.onToggleForm(!toggleForm); // Call the function passed from the parent with the updated count
    } else {
      handleLoginModalShow();
    }
  };
  const handleLoginSuccess = () => {
    setShowLoginModal(false); // Close the login modal
   // window.location.reload(); // Reload the page
};

  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <div className="toolbox-item m-0">
            <p className="m-0">Reviews</p>
          </div>
        </div>
        <div className='col-6'>
          <div className="toolbox-item m-0 float-end">
            {props?.isRateProductBtn && (
              <a className="btn btn-dark btn-rounded submit-review-toggle" style={{ color: 'white', cursor: 'pointer' }} onClick={handleToggleForm}>Rate Product</a>
            )
            }
          </div>
        </div>
      </div>
      <hr></hr>
      <ul className="comments-list">
        {props?.customerRatingBasedOnCurrentProduct?.content && props?.customerRatingBasedOnCurrentProduct?.content.length > 0 ? (
          props?.customerRatingBasedOnCurrentProduct?.content.map((res) => {
            if ((auth && auth.referenceId) !== res?.customerId) {
              return (
                <li className='pb-2 mb-2' key={res.id}>
                  {/* <div className="comment">
                    <div className="comment-body p-0">
                      <div className='row'>
                        <div className='col-5 d-flex'>
                          <div className="comment-rating ratings-container ml-2" style={{ fontSize: '14px', Display: 'inline-flex' }}>
                            <Badge bg="success ml-0">
                              <span className='pt-1'>{res?.rating}</span><FaStar />
                            </Badge>
                          </div>
                          <span className="font-weight-semi-bold text-dark ml-2 pt-2" style={{ fontSize: '14px' }}> {res?.title}</span>
                        </div>
                        <div className='col-7 text-end'>
                          <div className="comment-user">
                            <span className="comment-date">
                              by <span className="font-weight-semi-bold text-dark">{res?.customerName} </span>
                              on
                              <span className="font-weight-semi-bold text-dark"> {res?.createdDate}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row text-justify'>
                        <div className='col-12 comment-content'>
                          <p className='ml-2 text-justify'>{res?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-8'>
                      <div className='row'>
                        <div className='col-1' style={{ fontSize: '14px'}}>
                        <Badge bg="success ml-0">
                          <span className='pt-1'>{res?.rating}</span><FaStar />
                        </Badge>
                        </div>
                        <div className='col-11'  style={{ fontSize: '13px', textAlign: 'left' }}>
                        <span className="font-weight-semi-bold text-dark ml-2 pt-2" style={{ overflowWrap: 'break-word' }}> {res?.title}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 text-end'>
                      <div className="comment-user">
                        <span className="comment-date">
                          by <span className="font-weight-semi-bold text-dark">{res?.customerName} </span>
                          on
                          <span className="font-weight-semi-bold text-dark"> {res?.createdDate}</span>
                        </span>
                      </div>
                    </div>
                    <div className='row text-justify'>
                      <div className='col-12 comment-content'>
                        <p className='ml-2 text-justify'>{res?.description}</p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="product-wrap" style={{ textAlign: 'center' }}>
            <div className="product" >
              <h2 style={{fontSize:'small'}}>{ErrorMsg.REVIEW_NOT_AVAILABLE}</h2>
            </div>
          </div>
        )}
        {props?.customerRatingBasedOnCurrentProduct?.content?.length === 1 && props?.customerRatingBasedOnCurrentProduct?.content[0]?.customerId === (auth && auth.referenceId) && (
          <div className="product-wrap" style={{ textAlign: 'center' }}>
            <div className="product" >
              <h2 style={{ fontSize: 'small' }}>{ErrorMsg.REVIEW_NOT_AVAILABLE}</h2>
            </div>
          </div>
        )}
      </ul>

      <Modal show={showLoginModal} size="md" animation={false} onHide={handleLoginModalClose}>
        <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}><span>Login</span>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleLoginModalClose}></button>
        </Modal.Header>
        <LoginContainer onLoginSuccess={handleLoginSuccess}/>
      </Modal>
    </div>
  )
}