import './App.css';
import HeaderContainer from './Containers/HeaderContainer';
import Footer from './Containers/FooterContainer';
import Cart from './Component/Cart';
import 'react-toastify/dist/ReactToastify.css';
import CheckOut from './Component/CheckOut';
import Order from './Component/Order';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RegisterContainer from './Containers/RegisterContainer';
import LoginContainer from './Containers/LoginContainer';
import HomeContainer from './Containers/HomeContainer';
import ProfileContainer from './Containers/ProfileContainer';
import MyOrderContainer from './Containers/MyOrderContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateComponent from './Component/PrivateComponent';
import OrderDetails from './Component/OrderDetails';
import DeliveryAddressContainer from './Containers/DeliveryAddressContainer';
import ShopProductsContainer from './Containers/ShopProductsContainer';
import ProductRatingContainer from './Containers/ProductRatingContainer';
import ProductContainer from './Containers/ProductContainer';
import { useState, useEffect } from 'react';
import { CartContext, SubTotalContext, WishListCountContext, SearchContext, AuthUserContext, BreadCrumbsContext } from './Component/CartContext';
import { LoadingContext } from './Component/LoadingContext';
import SearchProducts from './Component/SearchProducts';
import WishListContainer from './Containers/WishListContainer';
import ScrollTop from './ChildComponent/Scroll/ScrollTop';

function App() {
  const [cartCount, setCartCount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [wishListCount, setWishListCount] = useState(0);
  const [search, setSearch] = useState();
  const [authUser, setAuthUser] = useState();
  const [breadCrumbValue, setBreadCrumbValue] = useState();
  const contextValue = {
    cartCount,
    setCartCount,
    subTotal,
    setSubTotal,
    wishListCount,
    setWishListCount,
    search,
    setSearch,
    authUser,
    setAuthUser,
    breadCrumbValue,
    setBreadCrumbValue
  };

  useEffect(() => {
    // Check if the current URL doesn't contain a hash
    if (!window.location.hash) {
      // If it doesn't, add a hash to the URL and reload the page
      let newUrl = `#${window.location.pathname}`;
      window.location.href = `${window.location.origin}${newUrl}`;
    }
  }, []);

  return (
    <div className="App">
      <LoadingContext>
      <AuthUserContext.Provider value={contextValue}>
          <BreadCrumbsContext.Provider value={contextValue}>
        <SearchContext.Provider value={contextValue}>
        <CartContext.Provider value={contextValue}>
          <WishListCountContext.Provider value={contextValue}>
            <SubTotalContext.Provider value={contextValue}>
              <Router forceRefresh={true}>
              <ScrollTop />
                <HeaderContainer></HeaderContainer>
                <Routes>
                  <Route path='/' element={<HomeContainer></HomeContainer>}></Route>
                  <Route path='/register' element={<RegisterContainer></RegisterContainer>}></Route>
                  <Route path='/login' element={<LoginContainer></LoginContainer>}></Route>
                  <Route exact path='/cart' element={<Cart></Cart>}></Route>
                  <Route exact path='/wishlist' element={<WishListContainer></WishListContainer>}></Route>
                  <Route exact path='/search' element={<SearchProducts></SearchProducts>}></Route>
                  <Route path='/checkout' element={<CheckOut></CheckOut>}></Route>

                  <Route exact path='/shipping-address-list' element={<DeliveryAddressContainer></DeliveryAddressContainer>}></Route>
                  <Route exact path='/shop/products/:id' element={<ShopProductsContainer></ShopProductsContainer>}></Route>
                  <Route exact path='/reviews/:id' element={<ProductRatingContainer></ProductRatingContainer>}></Route>
                  <Route path='/product/:id' element={<ProductContainer></ProductContainer>}></Route>
                  <Route element={<PrivateComponent></PrivateComponent>}>
                    <Route path='/profile' element={<ProfileContainer></ProfileContainer>}></Route>
                    <Route path='/myOrder' element={<MyOrderContainer></MyOrderContainer>}></Route>
                    <Route path='/order' element={<Order></Order>}></Route>
                    <Route path='/order-details/:id' element={<OrderDetails></OrderDetails>}></Route>
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />
              </Router>
            </SubTotalContext.Provider>
          </WishListCountContext.Provider>
        </CartContext.Provider>
        </SearchContext.Provider>
          </BreadCrumbsContext.Provider>
        </AuthUserContext.Provider>
      </LoadingContext>

    </div >
  );
}

export default App;
