
export function calculateSubTotal(cartList) {
    let total = 0;

    if (cartList && cartList.length > 0) {
        cartList.forEach((item) => {
            total += item.productVariantCombinations.price * item.quantity;
        });
    }
    return total; // Return the calculated total
}