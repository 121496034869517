import {HEADERMENU,LOGIN,LOGOUT,REGISTER,USERDETAILS,ORDERS} from '../constants';

const initialHeaderMenu = {
    HeaderMenu:{}
}

const initialRegisterdata = {
    RegisterData:[]
}

const initiallogindata = {
    LoginData:{}
}

const initialuserdata = {
    UserData:{}
}

const initialorderdata = {
    OrderData:[]
}

export const HeaderMenu = (state = [], action) =>{
    switch(action.type){
        case HEADERMENU:
            return [ 
                ...state,
                {HeaderMenu:action.data}
            ]
        default:
            return state;
    }
}

export const Register = (state = [],action) =>{
    switch(action.type){
        case REGISTER:
            return [
                ...state,
                action.data
            ]
        case LOGOUT:
            return []
        default:
            return state;
    }
}

export const Login = (state1 = {},action1) => {
    switch(action1.type){
        case LOGIN:
            return action1.data1
            // return [
            //     ...state1,
            //     action1.data1
            // ]
        case LOGOUT:
            return {}
        default:
            return state1;
    }
}

export const UserDetail = (state1 = {},action1) => {
    switch(action1.type){
        case USERDETAILS:
            return action1.data1
        case LOGOUT:
            return {}
        default:
            return state1;
    }
}

export const OrderData = (state1 = [],action1) => {
    switch(action1.type){
        case ORDERS:
         
            return {
                ...state1,
                OrderData:[action1.data1]
            }
        case LOGOUT:
            return []
        default:
            return state1;
    }
}