import MyOrder from "../Component/MyOrder";
import { connect } from 'react-redux';
import { hederMenu, logout, OrderData, userDetail } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";

const mapStatetoProps = state => ({
    data: state.OrderData
})

let auth = null;
if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
}

const mapDispatchToProps = dispatch => ({
    GetOrders: async data => {
        try {
        let auth = null;
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
        }
        
        let result = await fetch(environment.SERVER_URL +"/order/filters",{
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        dispatch(OrderData(result.data.content))
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    }
})

export default connect(mapStatetoProps, mapDispatchToProps)(MyOrder)