import DeliveryAddress from "../Component/DeliveryAddress/DeliveryAddressList";
import { connect } from 'react-redux';
import { login, userDetail } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";

let auth = null;
let user1 = null;
const mapStatetoProps = state => ({
    data: state
})

const mapDispatchToProps = dispatch => ({

    DeleteAddressHandler: async (data, user) => {
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
        }

        try {
            let result = await fetch(environment.SERVER_URL + "/shippingAddress/delete", {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    "Authorization": `OEC` + ' ' + auth.token,
                    "Content-Type": "application/json"
                },
            });
            result = await result.json();
            if (result.success) {
                let shippingAddress = result.data;
                /* User Object From State*/

                user1 = user;
                user1.shippingAddresses = user.shippingAddresses;
                user1.shippingAddresses = user1.shippingAddresses.filter((res) => res.id !== shippingAddress.id);
                dispatch(userDetail(user1))


            } else {
                toast.error(result.responseMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            return result;
        } catch (error) {
            toast.error("something is wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return error;
        }


    },

})

export default connect(mapStatetoProps, mapDispatchToProps)(DeliveryAddress)