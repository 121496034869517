import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { environment } from "../environment";
import ReactPaginate from 'react-paginate';
import { Context } from "../Component/LoadingContext";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { BiChevronDown } from "react-icons/bi";
import { toast } from 'react-toastify';
import ListingProduct from '../ChildComponent/Product/ListingProduct';
import { ErrorMsg } from "../../src/ChildComponent/messages/AllMessages";
import { AuthUserContext, BreadCrumbsContext } from '../Component/CartContext';
export default function ShopProducts(props) {
  let params = useParams();

  const navigate = useNavigate();
  const sortby = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "20", label: "20" },
  ];
  const options = sortby.map((option) => {
    return <option value={option.value}>{option.text}</option>
  })

  let page = 1;
  let pageSize = 10;
  const [sortByValue, setSortByValue] = useState(pageSize);
  const [products, setProducts] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [sortBy, setSortBy] = React.useState("id");
  const [sortOrder, setSortOrder] = React.useState(1);
  const [filters, setFilters] = React.useState(null);
  const [totalElement, settotalElement] = React.useState(0);
  const [loading, setLoading] = useContext(Context);
  const [imgSrc, setImageSrc] = React.useState();
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { breadCrumbValue, setBreadCrumbValue } = useContext(BreadCrumbsContext);

//   useEffect(() => {
//     setAuthUser(getAuthUser);
// }, []);

// const getAuthUser = () => {
//     if (localStorage.getItem('authUser')) {
//        return JSON.parse(localStorage.getItem('authUser'));
//     }
//     return null;
// }

let auth = null;
if (localStorage.getItem('authUser')) {
  auth = JSON.parse(localStorage.getItem('authUser'));
}


  useEffect(() => {
    if (params.id) {
      let filterData = {
        filterColumn: 'categoryId',
        filterValue: '',

      }
      filterData.filterValue = params.id;
      setFilters([filterData]);

    }
  }, [params.id, authUser])

  // useEffect(() => {
  //   if (params.id) {
  //     let filterData = {
  //       filterColumn: 'categoryId',
  //       filterValue: '',

  //     }
  //     filterData.filterValue = params.id;
  //     setFilters([filterData]);

  //   }
  // }, [ authUser ])

  useEffect(() => {
    if (filters !== null) {
      getProductByCategoryId();
    }
  }, [filters])

 

  const getProductByCategoryId = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json"
  };
  // Check if auth.token exists and is not null
  if (auth && auth.token) {
      headers["Authorization"] = `OEC ${auth.token}`;
  }
    try {
      let result = await fetch(environment.SERVER_URL + "/product/filterProductsForCustomer", {
        method: 'post',
        body: JSON.stringify({ page, pageSize, sortBy, sortOrder, filters }),
        headers: headers,
      });
      result = await result.json();
      if (result.success) {
        if (result && result.data && result.data.content) {
          setTempProducts(result.data.content);
          setProducts(result.data.content);
          settotalElement(result.data.totalPages);
          setBreadCrumbs(result.data.content[0].category)
          setLoading(false);
        } else {
          setTempProducts([]);
          setProducts([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error("something is wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return error
    }



  }

  const redirectHome = () => {
    navigate("/");
  }


  const handlePageClick = (data) => {

    let pagenumber = data.selected + 1;
    page = pagenumber;
    pageSize = sortByValue;
    getProductByCategoryId();
  }

  const onChangeSortBy = (e) => {
    setSortByValue(e);
    pageSize = e;
    getProductByCategoryId();
  }
  const state = useSelector(state => state);
  let menudata = {};
  let menuss = [];
  menudata = useSelector(state => {
    if (state.HeaderMenu) {
      state.HeaderMenu.forEach(element => {
        element.HeaderMenu.forEach((ls) => {
          menuss.push(ls);
        })
      });
    } else {
      menuss = []
    }
  })
  const setUpdatedProduct = async (product) => {
    setProducts(products.map(item => item.id === product.id ? product : item));
};

const setBreadCrumbs = async (name) => {
  setBreadCrumbValue(name);
};

  return (
    <div>
      <div className="page-wrapper">
        <main className="main">
          <div
            className="page-header"
            style={{
              backgroundImage: 'url("/images/shop/page-header-back.jpg")',
              // backgroundColor: "#3C63A4"
            }}
          >
            {/* <h3 className="page-subtitle">Products</h3> */}
            <h1 className="page-title">Products</h1>
            <ul className="breadcrumb">
              <li>
                <a onClick={redirectHome}>
                  <i className="d-icon-home" />
                </a>
              </li>
              <li className="delimiter">/</li>
              <li>
                <a>Products</a>
              </li>
              <li className="delimiter">/</li>
              {/* {
                products && products.length > 0 ? (
                  products.reduce((uniqueCategories, item) => {
                    if (!uniqueCategories.includes(item.category)) {
                      uniqueCategories.push(item.category);
                    }
                    return uniqueCategories;
                  }, []).map((category, i) => (
                    <li key={`category-${category?.id}-${i}`} >
                      <a>{category}</a>
                    </li>
                  ))
                ) : (
                  null
                )
              } */}

              {
                breadCrumbValue && 
                (
                  <li >
                      <a>{breadCrumbValue}</a>
                    </li>
                )
              }
            </ul>
          </div>
          {/* End PageHeader */}
          <div className="page-content mb-10 pb-6">
            <div className="container">
              <div className="row gutter-lg main-content-wrap">
                <aside className="col-lg-3 sidebar sidebar-fixed sidebar-toggle-remain shop-sidebar sticky-sidebar-wrapper">
                  <div className="sidebar-overlay" />
                  <a className="sidebar-close" href="#">
                    <i className="d-icon-times" />
                  </a>
                  {/* <a href="#" className="sidebar-toggle">
                    <i className="fas fa-chevron-right" />
                  </a> */}
                  <div className="sidebar-content" style={{ textAlign: 'left' }}>
                    <div className="sticky-sidebar" data-sticky-options="{'top': 10}">
                      <div className="widget widget-collapsible">
                        <h3 className="widget-title">All Categories</h3>
                        <ul className="widget-body filter-items search-ul">
                          <li>
                            {/* <a href="#" className='anchorlightBlack'>Accessosries</a> */}
                          </li>
                          {
                            menuss.length > 0
                              ? menuss.map((item, i) =>
                                <li key={`menu-${item?.id}-${i}`}>
                                  <a onClick={() => { navigate('/shop/products/' + item.id); setBreadCrumbs(item.name) }} ><span>{item.name}</span>{
                                    item.children ? <BiChevronDown size={25} style={{ paddingBottom: '9px' }} /> : null
                                  }</a>
                                </li>
                              )
                              : null
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </aside>
                <div className="col-lg-9 main-content m-auto">
                  <nav className="toolbox  sticky-toolbox sticky-content fix-top">
                    <div className="toolbox-left">
                    </div>
                    {
                      products && products.length > 0 ?
                      <div className="toolbox-right">
                      <div className="toolbox-item toolbox-show text-dark shipping-address">
                        <label>Show :</label>
                        <div className='select-box'>
                          {/* <select value={sortByValue} onChange={(e) => onChangeSortBy(e.target.value)}>
                            {options}
                          </select> */}
                              <select value={sortByValue} onChange={(e) => onChangeSortBy(e.target.value)}>
                                {sortby.map(option => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                        </div>
                      </div>
                    </div>
                    : null
                    }
                  </nav>
                  <div className="row  product-wrapper " style={{ textAlign: 'left' }}>
                    {products && products.length > 0
                      ?
                      products.map((res, i) =>
                        <div key={`product-${res?.id}-${i}`} className='col-sm-4 col-lg-3 col-md-3 '>
                          <div className="product text-center">   
                            <ListingProduct product={res} data={props?.data} onUpdateProduct={setUpdatedProduct}></ListingProduct>
                          </div>
                        </div>
                      )
                      : <div className="product-wrap" style={{ textAlign: 'center' }}>
                      <div className="product">
                        <h2 style={{    fontSize: 'medium'}}>{ErrorMsg.PRODUCT_NOT_AVAILABLE}</h2>
                      </div>
                    </div>
                    }
                  </div>
                  {products && products.length > 0 ?
                  <nav className="toolbox toolbox-pagination">
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalElement}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-end'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link page-link-prev'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link page-link-next'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                    style={{ fontSize: '20px' }}
                  />
                </nav>
                 :
                 null}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
