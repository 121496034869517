
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import WishList from '../Component/WishList';
import { getDataById } from "../../src/Component/SupplierServices";
const mapStatetoProps = state => ({
    data: state
})

let auth = null;
if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
}

const mapDispatchToProps = dispatch => ({
    
    GetWishListByCustomerId: async (id) => {
        return getDataById('cart/getWishListByCustomer',id);
    },
})

export default connect(mapStatetoProps, mapDispatchToProps)(WishList)