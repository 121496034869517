import React, { useState , useEffect ,useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";
import { Context } from "../Component/LoadingContext";
export default function VerifyEmail(props) {
    const navigate = useNavigate();
    const [email, setEmail] = useState(props.verifiedEmail);
    const [otp1, setotp1] = useState("");
    const [otp2, setotp2] = useState("");
    const [otp3, setotp3] = useState("");
    const [otp4, setotp4] = useState("");
    const [otp5, setotp5] = useState("");
    const [otp6, setotp6] = useState("");
    const [resendOtp, setresendOtp] = useState(false);
    const [error, setError] = React.useState(false);
    const [display, setdisplay] = useState();
    const [displayTimer, setdisplayTimer] = useState(false);
    const [loading,setLoading ] = useContext(Context);

    useEffect(()=>{
        start(1);
    },[])

    const start = async(minute)=>{
      
        setdisplayTimer(true);
        setresendOtp(false);
      
        let seconds = minute * 60;
        let textSec = '0';
        let statSec = 60;
    
        const prefix = minute < 10 ? '0' : '';
    
        const timer = setInterval(() => {
          seconds--;
          if (statSec != 0) statSec--;
          else statSec = 59;
       
    
          if (statSec < 10) {
            textSec = '0' + statSec;
          } else {
            textSec = statSec;
          }
    
          setdisplay(`${prefix}${Math.floor(seconds / 60)}:${textSec}`);
          if (seconds == 0) {
         
            clearInterval(timer);
            setresendOtp(true);
            setdisplayTimer(false);
           
          }
        }, 1000);
    }

    const resendOTP = async() =>{
        const formdata = new FormData();
        formdata.append('email', email);
        formdata.append('isForCustomer', 'true');
        setLoading(1);
        try {
        let result = await fetch(environment.SERVER_URL +'/customer/sendmail', {
            method: 'post',
            body: formdata,
           
        });
        result = await result.json();
    
        if (result.success) {
            setLoading(0);
            toast.success("OTP sent Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setdisplayTimer(true);
            start(1);
        } else {
            setLoading(0);
            toast.error(result.responseMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",

            });
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setLoading(0);
            return error; // Rethrow the error to handle it in the calling code
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
            setError(true);
            toast.error("Enter Valid Verification Code",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false;
        } else {
            const formdata = new FormData();
            formdata.append('email',email);
            formdata.append('otp', otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
            setLoading(1);
            try {
            let result = await fetch(environment.SERVER_URL +'/customer/verifyOtp', {
                method: 'post',
                body: formdata,
               
            });
            result = await result.json();
    
            if (result.success) {
                setLoading(0);
                props.verifyOTP(email);
            } else {
                setLoading(0);
                toast.error(result.responseMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
    
                });
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(0);
                return error; // Rethrow the error to handle it in the calling code
            }
        }
    }

    const numberOnly = (event) =>{
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
    
        return true;
    }

    const onDigitInput = (event) =>{
        
        const charCode = (event.which) ? event.which : event.keyCode;
        let element = null;
        if (event.code !== 'Backspace' && !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)))
        element = event.nativeEvent.srcElement.nextElementSibling;

        if (event.code === 'Backspace' && !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)))
        element = event.nativeEvent.srcElement.previousElementSibling;

        if (element == null)
        return;
        else
        element.focus();
    }
    return (
        <div>
            <Modal.Body style={{ fontWeight: 'bold', fontSize: '12px' }}>
                <div className="container">
                    <div className="row">
                        <form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="container height-100 d-flex justify-content-center align-items-center" style={{ fontSize: '12px' }}>
                                            <div className="position-relative">
                                                <div className="card p-2 text-center">
                                                    <h6>Please enter the one time password <br /> to verify your account</h6>
                                                    <div> <span>A code has been sent to</span> <small>&nbsp;{email}</small> </div>
                                                    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                                                        <input className="m-2 text-center form-control rounded" type="text" inputMode="numeric" id="first" maxLength={1}
                                                            value={otp1} onChange={(e) => setotp1(e.target.value)} onKeyPress={numberOnly} onKeyUp={onDigitInput} />
                                                        <input className="m-2 text-center form-control rounded" type="text" inputMode="numeric" id="second" maxLength={1}
                                                            value={otp2} onChange={(e) => setotp2(e.target.value)} onKeyPress={numberOnly} onKeyUp={onDigitInput} />
                                                        <input className="m-2 text-center form-control rounded" type="text" inputMode="numeric" id="third" maxLength={1}
                                                            value={otp3} onChange={(e) => setotp3(e.target.value)} onKeyPress={numberOnly} onKeyUp={onDigitInput} />
                                                        <input className="m-2 text-center form-control rounded" type="text" inputMode="numeric" id="fourth" maxLength={1}
                                                            value={otp4} onChange={(e) => setotp4(e.target.value)} onKeyPress={numberOnly} onKeyUp={onDigitInput} />
                                                        <input className="m-2 text-center form-control rounded" type="text" inputMode="numeric" id="fifth" maxLength={1}
                                                            value={otp5} onChange={(e) => setotp5(e.target.value)} onKeyPress={numberOnly} onKeyUp={onDigitInput} />
                                                        <input className="m-2 text-center form-control rounded" type="text" inputMode="numeric" id="sixth" maxLength={1}
                                                            value={otp6} onChange={(e) => setotp6(e.target.value)} onKeyPress={numberOnly} onKeyUp={onDigitInput} />
                                                    </div>

                                                    <div className="mt-4"> <button className="btn btn-danger px-4 validate bg-primary text-white border border-white rounded" onClick={handleSubmit}>Validate</button> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-2 footer-copyright text-center">
                                <span className="footer-copyright">
                                    {!resendOtp ? <><p className="footer-copyright" >Wait for {display} seconds to resend OTP</p></> 
                                    :
                                    <a href="javscript:void(0)" onClick={resendOTP}>Resend OTP</a> 
                                    }
                                   
                                    
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body >
        </div >
    )
}
