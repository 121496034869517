import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import ListingProduct from '../ChildComponent/Product/ListingProduct';
import { WishListCountContext, AuthUserContext } from '../Component/CartContext';
import ListingCategory from '../ChildComponent/Category/ListingCategory';
import { Context } from "../Component/LoadingContext";
export default function Home(props) {
    const { wishListCount, setWishListCount } = useContext(WishListCountContext);
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [bestSellerProducts, setBestSellerProducts] = useState([]);
    const [loading, setLoading] = useContext(Context);
    
    const categories = useSelector(state => {
        if (state.HeaderMenu) {
            const allCategories = [];
            state.HeaderMenu.forEach(element => {
                allCategories.push(...element.HeaderMenu);
            });
            return allCategories;
        } else {
            return [];
        }
    });

    useEffect(() => {
        getWishListByCustomer();
        fetchFeaturedProducts();
        fetchMostSellingProducts();
    }, [authUser]);
    
    const getWishListByCustomer = async () => {
        if (authUser && authUser.referenceId) {
            setLoading(true)
            props.GetWishListByCustomerId(authUser.referenceId).then((res) => {
                if (res.success && res.data) {
                    setWishListCount(res?.data?.length);
                    setLoading(false)
                    return
                } else {
                    setWishListCount(0);
                    setLoading(false)
                    return
                }
            })
        } else {
            setWishListCount(0);
            setLoading(false)
            return
        }
    }

    const fetchFeaturedProducts = async () => {
        setLoading(true)
        props.GetFetchFeaturedProducts().then((res) => {
            if (res.success && res.data) {
                setFeaturedProducts(res.data);
                setLoading(false)
                return
            } else {
                setFeaturedProducts([]);
                setLoading(false)
                return
            }
        })
    }

    const fetchMostSellingProducts = async () => {
        setLoading(true)
        props.GetMostSellingProducts().then((res) => {
            if (res.success && res.data) {
                setBestSellerProducts(res.data);
                setLoading(false)
                return
            } else {
                setBestSellerProducts([]);
                setLoading(false)
                return
            }
        })
    }


    const setUpdatedProduct = async (product) => {
        setBestSellerProducts(bestSellerProducts.map(item => item.id === product.id ? product : item));
        setFeaturedProducts(featuredProducts.map(item => item.id === product.id ? product : item));
    };

    return (
        <div>

            <div className="page-content">
                <section className="intro-section">
                    <div className="owl-carousel owl-theme d-flex owl-dot-inner owl-dot-white intro-slider animation-slider cols-1 gutter-no" data-owl-options="{
                        'nav': false,
                        'dots': true,
                        'loop': false,
                        'items': 1,
                        'autoplay': false,
                        'autoplayTimeout': 8000
                    }">
                        <div className="banner banner-fixed intro-slide1" style={{ backgroundColor: '#46b2e8' }}>
                            <figure>
                                <img src="images/demos/demo1/slides/slide1.jpg" alt="intro-banner" width={1903} height={630} style={{ backgroundColor: '#34ace5' }} />
                            </figure>
                            <div className="container">
                                <div className="banner-content y-50" style={{ textAlign: 'left' }}>
                                    <h4 className="banner-subtitle font-weight-bold ls-l">

                                        {/* <span className="d-inline-block">Buy
                                            2 Get</span>
                                        <span className="d-inline-block label-star bg-white text-primary">1
                                            Free</span> */}
                                    </h4>
                                    <h2 className="banner-title font-weight-bold text-white lh-1 ls-md" data-animation-options="{'name': 'fadeInUpShorter', 'duration': '1.2s', 'delay': '1s'}">
                                        Mortise</h2>
                                    <h3 className="font-weight-normal lh-1 ls-l text-white" data-animation-options="{'name': 'fadeInUpShorter', 'duration': '1.2s', 'delay': '1s'}">
                                        Locksets</h3>
                                    <p className="text-white ls-s mb-7" data-animation-options="{'name': 'fadeInUpShorter', 'duration': '1.2s', 'delay': '1s'}">
                                        Get Free Shipping on all orders over 30000.00</p>
                                    {/* <a className="btn btn-dark btn-rounded" style={{ color: 'white' }} data-animation-options="{'name': 'fadeInUpShorter', 'duration': '1s', 'delay': '1.8s'}">Shop
                                        Now<i className="d-icon-arrow-right" /></a> */}
                                </div>
                            </div>
                        </div>
                        <div className="banner banner-fixed intro-slide2" style={{ backgroundColor: '#dddee0' }}>
                            <figure>
                                <img src="images/demos/demo1/slides/slide2.jpg" alt="intro-banner" width={1903} height={630} style={{ backgroundColor: '#d8d9d9' }} />
                            </figure>
                            <div className="container">
                                <div className='banner-contents'>
                                    <div className="banner-content y-50 ml-auto text-right" >
                                        <h4 className="banner-subtitle ls-s mb-1 slide-animate" data-animation-options="{'name': 'fadeInUp', 'duration': '.7s'}"><span className="d-block text-uppercase mb-2">Coming soon</span><strong className="font-weight-semi-bold ls-m">Riode Birthday</strong></h4>
                                        <h2 className="banner-title mb-2 d-inline-block font-weight-bold text-primary slide-animate" data-animation-options="{'name': 'fadeInRight', 'duration': '1.2s', 'delay': '.5s'}">
                                            Sale</h2>
                                        <p className="slide-animate font-primary ls-s text-dark mb-4" data-animation-options="{'name': 'fadeInUp', 'duration': '1s', 'delay': '1.2s'}">
                                            Up to 70% off on all products <br />online &amp; Free Shipping over $90</p>
                                        <a className="btn btn-dark btn-rounded slide-animate" data-animation-options="{'name': 'fadeInUp', 'duration': '1s', 'delay': '1.4s'}">Shop
                                            Now<i className="d-icon-arrow-right" /></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="banner banner-fixed video-banner intro-slide3" style={{ backgroundColor: '#dddee0' }}>
                            <figure>
                                <video src="video/memory-of-a-woman.mp4" width={1903} height={630} />
                            </figure>
                            <div className="container">
                                <div className="banner-content x-50 y-50 text-center">
                                    <h4 className="banner-subtitle text-white text-uppercase mb-3 ls-normal slide-animate" data-animation-options="{'name': 'fadeIn', 'duration': '.7s'}">Check out our
                                    </h4>
                                    <h2 className="banner-title mb-3 text-white font-weight-bold text-uppercase ls-m slide-animate" data-animation-options="{'name': 'fadeInUp', 'duration': '.7s', 'delay': '.5s'}">
                                        Summer Season's</h2>
                                    <p className="slide-animate mb-7 text-white ls-s font-primary " data-animation-options="{'name': 'fadeInUp', 'duration': '1s', 'delay': '.8s'}">
                                        Up to 50% Off this Season’s &amp; Get free shipping<br />on all orders over
                                        $199.00</p>
                                    <a className="btn btn-dark btn-rounded slide-animate mb-1" data-animation-options="{'name': 'fadeInLeft', 'duration': '1s', 'delay': '1.5s'}">Shop
                                        Now<i className="d-icon-arrow-right" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-6">
                        <div className="service-list">
                            <div className="card-container">
                                <div className="cards">
                                    <i className="icon-box-icon d-icon-truck" />
                                    <h4 className="icon-box-title text-capitalize ls-normal lh-1">Free Shipping 
                                        {/* &amp; Return */}
                                    </h4>
                                    <p className="ls-s lh-1">Free shipping on orders over 30000.00</p>
                                </div>

                                <div className="cards">
                                    {/* <span className="service-icon">&#9733;</span> */}
                                    <i className="icon-box-icon d-icon-service" />
                                    <h4 className="icon-box-title text-capitalize ls-normal lh-1">Customer Support 24/7
                                    </h4>
                                    <p className="ls-s lh-1">Instant access to perfect support</p>
                                </div>

                                <div className="cards">
                                    {/* <span className="service-icon">&#9733;</span> */}
                                    <i className="icon-box-icon d-icon-secure" />
                                    <h4 className="icon-box-title text-capitalize ls-normal lh-1">100% Secure Payment
                                    </h4>
                                    <p className="ls-s lh-1">We ensure secure payment!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {categories && categories.length > 0 ?
                    <section >
                        <div className="container" style={{ marginTop: '20px' }}>
                            <h2 className="title title-center mb-5">Our Categories</h2>
                            <div className="row">
                                {categories && categories.length > 0 ? (
                                    categories.slice(0, 4).map((categoryItem,i) => (
                                        <div key={`category-${categoryItem?.id}-${i}`} className="col-xs-6 col-lg-3 mb-4">
                                            <ListingCategory category={categoryItem}></ListingCategory>
                                        </div>
                                    ))
                                ) : (
                                    null
                                )}
                            </div>
                        </div>
                    </section>
                    : null
                }

                {bestSellerProducts && bestSellerProducts.length > 0 ?
                    <section className="product-wrapper container mt-6 mt-md-10 pt-4 pb-8">
                        <h2 className="title title-center mb-5">Most Selling Products</h2>
                        <div className="owl-carousel owl-theme row owl-nav-full cols-2 cols-md-3 cols-lg-4" data-owl-options="{
                        'items': 5,
                        'nav': false,
                        'loop': false,
                        'dots': true,
                        'margin': 20,
                        'responsive': {
                            '0': {
                                'items': 2
                            },
                            '768': {
                                'items': 3
                            },
                            '992': {
                                'items': 4,
                                'dots': false,
                                'nav': true
                            }
                        }
                    }">
                            {bestSellerProducts && bestSellerProducts.length > 0 ?
                                bestSellerProducts.slice(0, 4).map((mostSellItem, i) => ((
                                    <div key={`product-${mostSellItem.id}-${i}`} className="product text-center">
                                        <ListingProduct product={mostSellItem} data={props?.data} onUpdateProduct={setUpdatedProduct}></ListingProduct>
                                    </div>
                                )))
                                :
                                null
                            }
                        </div>
                    </section>
                    : null
                }
                {
                    featuredProducts && featuredProducts.length > 0 ?
                        <section className="product-wrapper mt-6 mt-md-10 pt-4 mb-10 pb-2 container">
                            <h2 className="title title-center">Our Featured</h2>
                            <div className="owl-carousel owl-theme row cols-2 cols-md-3 cols-lg-4 cols-xl-5" data-owl-options="{
                        'items': 5,
                        'nav': false,
                        'loop': false,
                        'dots': true,
                        'margin': 20,
                        'responsive': {
                            '0': {
                                'items': 2
                            },
                            '768': {
                                'items': 3
                            },
                            '992': {
                                'items': 4
                            },
                            '1200': {
                                'items': 5,
                                'dots': false,
                                'nav': true
                            }
                        }
                    }">
                                {featuredProducts && featuredProducts.length > 0 ?
                                    featuredProducts.slice(0, 5).map((featuredItem,i) => ((
                                        <div key={`featured-${featuredItem.id}-${i}`} className="product text-center">
                                            <ListingProduct product={featuredItem} data={props?.data} onUpdateProduct={setUpdatedProduct}></ListingProduct>
                                        </div>
                                    )))
                                    :
                                    null
                                }
                            </div>
                        </section>
                        : null
                }
            </div>
        </div>
    )
}
