import React, { useState, useContext, useEffect } from 'react';
import { SearchContext } from '../Component/CartContext';
import { filterData, PaginationGridFilter } from '../Component/SupplierServices';
import ListingProduct from '../ChildComponent/Product/ListingProduct';
import PaginationComponent from '../ChildComponent/Pagination/PaginationComponent';
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from '../Component/CartContext';
import { Context } from '../Component/LoadingContext';
export default function SearchProducts() {
  const navigate = useNavigate();
  const { search } = useContext(SearchContext);
  const reportGridFilter = new PaginationGridFilter();
  const [searchedProducts, setSearchProduct] = useState('');
  const [loading, setLoading] = useContext(Context);
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  // Now you have access to the searched value in the search variable
  useEffect(() => {
    getSearchedProducts()
  }, [search,authUser]);

//   useEffect(() => {
//     setAuthUser(getAuthUser);
// }, []);

// const getAuthUser = () => {
//     if (localStorage.getItem('authUser')) {
//        return JSON.parse(localStorage.getItem('authUser'));
//     }
//     return null;
// }

// useEffect(() => {
//   getSearchedProducts()
// }, [authUser]);

  const getSearchedProducts = async () => {
    if (search?.searchInput) { // Check if search and searchInput are defined
      setLoading(true);
      reportGridFilter.pageSize = 1;
      reportGridFilter.filters[0].filterColumn = "productName";
      reportGridFilter.filters[0].filterValue = search.searchInput;
  
      const response = await filterData(`product/filterProductsForCustomer`, reportGridFilter);
      
      if (response.success && response?.data?.content) {
        setSearchProduct(response.data);
        setLoading(false);
      } else {
        setSearchProduct([]); // Set search product to an empty array or handle the error case accordingly
        setLoading(false);
      }
    } else {
      setSearchProduct([]); // Set search product to an empty array if searchInput is not defined
    }
  }

  // const setUpdatedProduct = async (product) => {
  //   debugger
  //   setSearchProduct(searchedProducts?.content && searchedProducts?.content.map(item => item.id === product.id ? product : item));
  // };

  const setUpdatedProduct = async (product) => {
    // Find the index of the product with the same ID as the updated product
    const index = searchedProducts?.content.findIndex(item => item.id === product.id);
  
    // If the product with the same ID is found, update it in the list
    if (index !== -1) {
      // Create a new array with the updated product
      const updatedProducts = [...searchedProducts.content];
      updatedProducts[index] = product;
  
      // Update the searchedProducts object
      setSearchProduct({ ...searchedProducts, content: updatedProducts });
    }
  };
  


  const redirecthome = () => {
    navigate("/");
  }

  const handlePageChange = (pageNumber) => {
    reportGridFilter.page = pageNumber; // Update the page property of reportGridFilter
    getSearchedProducts(); // Fetch data for the new page
  };



  return (
    <div>
      <div className="page-wrapper">
        <main className="main">
          <div
            className="page-header"
            style={{
              backgroundImage: 'url("/images/shop/page-header-back.jpg")',
              // backgroundColor: "#3C63A4"
            }}
          >
            {/* <h3 className="page-subtitle">Products</h3> */}
            <h1 className="page-title">Products</h1>

            <ul className="breadcrumb">

              <li>
                <a onClick={redirecthome}>
                  <i className="d-icon-home" />
                </a>
              </li>

              <li className="delimiter">/</li>
              <li>
                <a>Products</a>
              </li>
            </ul>
          </div>
          {/* End PageHeader */}
          <div className="page-content mb-10 pb-6 mt-5">
            <div className="container">
              <div className="row gutter-lg main-content-wrap">
                <div className="col-12 main-content">
                  <div className="row  product-wrapper " style={{ textAlign: 'left' }}>
                    {searchedProducts?.content && searchedProducts?.content.length > 0
                      ?
                      searchedProducts?.content.map((res, i) =>
                        <div className='col-sm-4 col-lg-3 col-md-3 ' key={res.id}>

                          <div  className="product text-center">
                            <ListingProduct product={res} data={search?.data?.data} onUpdateProduct={setUpdatedProduct}></ListingProduct>

                          </div>
                        </div>

                      )
                      : <div className="product-wrap" style={{ textAlign: 'center' }}>
                        <div className="product">
                          <h2>Data Not Available</h2>
                        </div>
                      </div>
                    }
                  </div>
                  <PaginationComponent list={searchedProducts} setCurrentPage={handlePageChange} />
                </div>
              </div>
            </div>
          </div>
        </main>

      </div>
    </div>
  )
}

