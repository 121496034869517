import React,{useState,useEffect,useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import no_image from "../../image/no_image_739X1000.jpg";
import { environment } from '../../environment';
import { BreadCrumbsContext } from '../../Component/CartContext';

export default function ListingCategory(props) {
    const navigate = useNavigate();
    const [categoryImage, setCategoryImage] = useState('');
    const { breadCrumbValue, setBreadCrumbValue } = useContext(BreadCrumbsContext);
    useEffect(() => {
        fetchCategoryImages();
    }, []);

    const fetchCategoryImages = async () => {
        try {
        const response = await fetch(
            `${environment.SERVER_URL}/fileDownload/getCategoryImage/${props?.category?.id}`
        );
        const blob = await response.blob();
        if (blob.type !== "application/json") {
            const imageUrl = URL.createObjectURL(blob);
            setCategoryImage(imageUrl);
        }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setCategoryImage(no_image); // Rethrow the error to handle it in the calling code
        }

    };
    const setBreadCrumbs = async (item) => {
        setBreadCrumbValue(item.name);
    };
    
    return (
        <div>
            <div className="category category-default1 category-absolute banner-radius overlay-zoom">
                <a onClick={() => { navigate('/shop/products/' + props?.category?.id); setBreadCrumbs(props?.category) }}>
                    <figure className="category-media">
                        <img
                            src={categoryImage ? categoryImage : no_image}
                            alt={props?.category?.name}
                            width={280}
                            height={280}
                            style={{ backgroundColor: props?.category?.img ? '#bcbdb7' : 'var(--bs-gray-200)', height:'280px' }}
                            onError={(e) => {
                                e.target.src = no_image; // Set the default image source if the image fails to load
                            }}
                        />
                    </figure>
                </a>
                <div className="category-content" onClick={() => { navigate('/shop/products/' + props?.category?.id); setBreadCrumbs(props?.category) }}>
                    <h4 className="category-name font-weight-bold ls-l" >
                        <a >{props?.category?.name}</a> {/* Add appropriate href */}
                    </h4>
                </div>
            </div>
        </div>
    )
}

