import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Select from "react-select";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { environment } from '../environment';
import { Context } from "../Component/LoadingContext";
import { faBan } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';

export default function MyOrder(props) {
    let auth = null;
    let UserOrders = [];


    if (localStorage.getItem('authUser')) {
        auth = JSON.parse(localStorage.getItem('authUser'));
    }

    let filterData = {
        filterColumn: '',
        filterValue: '',
        filterTypeId: '',
        idList: []
    }
    let searchIndex = 0;
    // let filters = [];
    // const Orders = [];

    //const [pageSize, setPageSize] = React.useState(10);
    const [sortBy, setSortBy] = React.useState("id");
    const [sortOrder, setSortOrder] = React.useState(0);
    const [status, setStatus] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [filters, setfilters] = React.useState([])
    const [Orders1, setOrders] = React.useState([]);
    const [serchOrder, setserchOrder] = React.useState();
    // const [page, setPage] = React.useState(1);
    const [paginationpage, setPaginationPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableData, setTableData] = React.useState([]);
    const [totalElement, settotalElement] = React.useState(0);
    const [rowId, setRowId] = React.useState();
    const [loading, setLoading] = useContext(Context);

    let page = 1;
    let pageSize = 10;

    const handleChangePage = (event, newPage) => {

        let pagenumber = newPage + 1;
        page = pagenumber;
        pageSize = rowsPerPage;
        setPaginationPage(newPage);
        setTableData([]);
        setPagination();
    };

    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(+event.target.value);
        pageSize = event.target.value;
        setPaginationPage(0);
        setTableData([]);
        setPagination();
    };

    useEffect(() => {
        getStatus();
        setPagination();
    }, []);

    const setPagination = () => {
        filterData.filterColumn = "order_customer_id";
        filterData.idList.push(auth.referenceId);
        filters[0] = filterData;
        setLoading(0);
        props.GetOrders({ page, pageSize, sortBy, sortOrder, filters }).then((res) => {
            //setOrders(res.data);
            if (res.success) {
                setLoading(0);
                if (res.data && res.data.content) {

                    setTableData(res.data.content);
                    settotalElement(res.data.totalElements);
                    setRowId((((page - 1) * rowsPerPage) + 1));
                    // filterData = {};
                } else {
                    setTableData([]);
                    settotalElement(0);
                    setRowId();
                }

                if (res.data && res.data.pageble) {
                    pageSize = res.data.pageble.pageSize;
                }

            } else {
                setLoading(0);
            }



        })
    }

    let colmFlags = false;
    const searchOrders = () => {

        let param = {};
        param.filterColumn = "any";
        param.filterValue = serchOrder;

        filters.filter(obj => {
            if (obj.filterColumn == 'any') {
                obj.filterValue = param.filterValue
                colmFlags = true;
            }
        });

        if (colmFlags != true) {
            filters.push(param);
        }
        setPagination();
    }

    let colmFlag = false;
    const onChangeStatus = (e) => {
        filterData.filterColumn = "status";
        if (e && e.length > 0) {
            e.forEach((rs) => {
                filterData.idList.push(rs.value.id);
            })
        } else {
            filterData.idList = [];
        }


        filters.filter(obj => {
            if (obj.filterColumn == 'status') {
                obj.idList = filterData.idList
                colmFlag = true;
            }
        });

        if (colmFlag != true) {
            filters.push(filterData);
        }
        // if(filters.length === 1){
        //     filters[1] = filterData;
        // } else {
        //     filters[2] = filterData;
        // }
        setLoading(1);
        props.GetOrders({ page, pageSize, sortBy, sortOrder, filters }).then((res) => {
            if (res.success) {
                setLoading(0);

                if (res && res.data && res.data.content) {
                    setTableData(res.data.content);
                    settotalElement(res.data.totalElements);
                    setRowId((((page - 1) * rowsPerPage) + 1));
                    // filterData = {};
                } else {
                    setTableData([]);
                    settotalElement(0);
                    setRowId();
                }

                if (res.data && res.data.pageble) {
                    pageSize = res.data.pageble.pageSize;
                }
            } else {
                setLoading(0);
            }


        })


    }

    const getStatus = async () => {
        setLoading(1)
        try {
        let result = await fetch(environment.SERVER_URL + "/status", {
            method: 'get',
            headers: {
                "Authorization": `OEC` + ' ' + auth.token
            },
        });
        result = await result.json();
        if (result.success) {
            setLoading(0);
    
            if (result) {
                var optionsTagsDb = [];
                result.data.forEach((res) => {
                    let optionobj = { label: null, value: null }
                    optionobj.label = res.name;
                    optionobj.value = res;
                    optionsTagsDb.push(optionobj);
                })
                setOptions(optionsTagsDb);
            }
        } else {
            setLoading(0);
    
        }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setLoading(0);
            return error; // Rethrow the error to handle it in the calling code
        }
    }

    const cancelMyOrder = async (order) => {
        swal({
            textAlign: 'center',
            title: "Are you sure?",
            text: "Do you want to cancel the order?",
            icon: "warning",
            buttons: {
                confirm: { text: 'Yes,cancel it!', className: 'swal-yes' },
                cancel: 'No'

            },
            // dangerMode: true,
        })
            .then(async (willChange) => {
                if (willChange) {
                    setLoading(1);
                    try {
                    let result = await fetch(environment.SERVER_URL + '/order/cancelOrder/' + order.id, {
                        method: 'get',
                        headers: {
                            "Authorization": `OEC` + ' ' + auth.token,
                            "Content-Type": "application/json"
                        },
                    });
                    result = await result.json();

                    if (result.success) {
                        setLoading(0);
                        setLoading(1);
                        setPaginationPage(0);
                        setTableData([]);
                        props.GetOrders({ page, pageSize, sortBy, sortOrder, filters }).then((res) => {
                            if (res.success) {


                                if (res && res.data && res.data.content) {
                                    setTableData(res.data.content);
                                    settotalElement(res.data.totalElements);
                                    setRowId((((page - 1) * rowsPerPage) + 1));
                                    // filterData = {};
                                } else {
                                    setTableData([]);
                                    settotalElement(0);
                                    setRowId();
                                }

                                if (res.data && res.data.pageble) {
                                    pageSize = res.data.pageble.pageSize;
                                }
                            } else {
                                setLoading(0);
                            }
                            toast.success("Order has been cancelled successfully", {
                                position: "top-right",
                                autoClose: 800,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                                className: 'toaster-size'
                            });
                            setLoading(0);


                        })

                    } else {

                        toast.error(result.responseMessage, {
                            position: "top-right",
                            autoClose: 800,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            className: 'toaster-size'
                        });
                        setLoading(0);
                    }
                    } catch (error) {
                        console.error("Error fetching user data:", error);
                        setLoading(0);
                        return error; // Rethrow the error to handle it in the calling code
                    }
                } else {

                }
            });
    }

    return (
        <div>
            <div className="page-wrapper">
                <main className="main account">
                    {/* <nav className="breadcrumb-nav">
                        <div className="container" style={{ fontSize: '15px' }}>
                            <ul className="breadcrumb">
                                <li><Link to={"/"}><i className="d-icon-home" style={{ fontSize: '10px' }} /></Link></li>
                                <li>&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleRight} />&nbsp;&nbsp;My Orders</li>
                            </ul>
                        </div>
                    </nav> */}
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-12">
                                <form action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&id=082f74cbda" target="_blank" method="post">
                                    <div className="form-group">

                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <div className="row col-lg-12 col-md-12">
                            <div className="col-lg-8">
                                <input type="text" className="form-controls" placeholder="Search here" onChange={(e) => setserchOrder(e.target.value)} style={{ width: '200px' }} />
                                <button type="button" className="btn btn-primary searchBtn1 " onClick={searchOrders} style={{ width: '150px' }}><span >Search</span></button>
                            </div>
                            <div className=" col-12 col-md-4 mt-2 " style={{ textAlign: 'left' }}>


                                <Select
                                    isMulti
                                    defaultOptions
                                    options={options}
                                    value={status}
                                    onChange={(e) => { setStatus(e); onChangeStatus(e) }}
                                    placeholder="Select Status"
                                />


                            </div>
                        </div> */}
                        <div className="search-containerSearch">
                            <input className="search-boxSearch" type="text" placeholder="Search..." onChange={(e) => setserchOrder(e.target.value)} />
                            <button className="search-buttonSearch" type="submit" onClick={searchOrders} >Search</button>
                            <Select
                                className="search-dropdownSearch"
                                isMulti
                                defaultOptions
                                options={options}
                                value={status}
                                onChange={(e) => { setStatus(e); onChangeStatus(e) }}
                                placeholder="Select Status"
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-12" style={{ padding: "10px" }}>
                                <Paper sx={{ width: '100%', overflow: 'hidden', fontFamily: "'Poppins', sans-serif" }} >
                                    <TableContainer component={Paper} className='order-table' >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ fontFamily: "'Poppins', sans-serif" }}>
                                            <TableHead style={{ fontFamily: "'Poppins', sans-serif" }}>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center" className='order-tablehead' style={{ fontFamily: "'Poppins', sans-serif" }}>Order</TableCell>
                                                    <TableCell align="center" className='order-tablehead' style={{ fontFamily: "'Poppins', sans-serif" }}>Scope Of Supply</TableCell>
                                                    <TableCell align="center" className='order-tablehead' style={{ fontFamily: "'Poppins', sans-serif" }}>Status</TableCell>
                                                    <TableCell align="center" className='order-tablehead' style={{ fontFamily: "'Poppins', sans-serif" }}>Cancel Order</TableCell>
                                                    <TableCell align="center" className='order-tablehead'>Action</TableCell>
                                                    <TableCell align="center" className='order-tablehead' style={{ display: 'none' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData.length > 0
                                                    ?
                                                    tableData
                                                        .map((row, i) => (
                                                            <TableRow
                                                                key={`order-${row?.id}-${i}`}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="center" component="td" scope="row" className="order-number Ordercolumn order-tabletd" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}>
                                                                    <Link to={'/order-details/' + row.id}><span>{row.orderTxnId}</span></Link>
                                                                </TableCell>
                                                                <TableCell className="order-number Ordercolumn order-tabletd" align="center" width='50%' style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}>{row.scopeOfSupply}</TableCell>
                                                                <TableCell className="order-number Ordercolumn order-tabletd" align="center" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}>
                                                                    {
                                                                        row.statusKeyName == 'order_placed' ? <Badge bg="success">{row.status}</Badge> : ''
                                                                    }
                                                                    {
                                                                        row.statusKeyName == 'order_pending' ? <Badge bg="primary">{row.status}</Badge> : ''
                                                                    }
                                                                    {
                                                                        row.statusKeyName == 'order_cancelled' ? <Badge bg="danger">{row.status}</Badge> : ''
                                                                    }
                                                                    {
                                                                        row.statusKeyName == 'order_failed' ? <Badge bg="danger">{row.status}</Badge> : ''
                                                                    }
                                                                    {
                                                                        row.statusKeyName == 'picked_up' ? <Badge bg="info">{row.status}</Badge> : ''
                                                                    }
                                                                    {
                                                                        row.statusKeyName == 'refund_failed' ? <Badge bg="danger">{row.status}</Badge> : ''
                                                                    }
                                                                    {
                                                                        row.statusKeyName == 'delivered' ? <Badge bg="dark">{row.status}</Badge> : ''
                                                                    }
                                                                </TableCell>
                                                                {/* <FontAwesomeIcon icon={faBan} style={{ fontSize: 12 }} /> */}
                                                                <TableCell className="order-number Ordercolumn order-tabletd" align="center" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}>
                                                                    {
                                                                        (row?.statusKeyName == 'order_placed' || row?.statusKeyName == 'order_pending') && !row.isOrderPickedUp
                                                                            ?
                                                                            <button className='btn btn-outline-danger btn-sm mt-1 text-center btncancel' style={{ padding: '10px', fontWeight: 'bold', borderRadius: '10px' }} onClick={() => cancelMyOrder(row)}>Cancel&nbsp;</button>
                                                                            : "-"
                                                                    }
                                                                </TableCell>
                                                                <TableCell className="order-number Ordercolumn order-tabletd" align="center"><span className='Ordercolumn' style={{ fontWeight: 'bold', width: '20px' }}><Link to={'/order-details/' + row.id}>View</Link></span></TableCell>
                                                            </TableRow>
                                                        ))

                                                    : (
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="center" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif" }}>
                                                                Data Not Available
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        style={{ marginTop: '-40px', fontSize: '14px' }}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        component="div"
                                        count={totalElement}
                                        rowsPerPage={rowsPerPage}
                                        page={paginationpage}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div >
                    <ToastContainer></ToastContainer>
                </main >
            </div >
        </div >
    )
}
