import Reviews from "../Component/Review/ReviewComponent";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

const mapStatetoProps = state => ({
    data: state
})

// let auth = null;
// if (localStorage.getItem('authUser')) {
//     auth = JSON.parse(localStorage.getItem('authUser'));
// }

const mapDispatchToProps = dispatch => ({

})


export default connect(mapStatetoProps, mapDispatchToProps)(Reviews)