import Product from "../Component/Product";
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";
import { getDataById } from "../../src/Component/SupplierServices";


const mapStatetoProps = state => ({
    data: state
})

let auth = null;
if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
}

const mapDispatchToProps = dispatch => ({
    AddtoCart: async data => {  
        try {
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
        }
        let obj = {
            quantity: data.quantity,
            isSaveOrLater: false,
            isActive: "true",
            isDeleted: "false",
            customer: { id: data.customer.id },
            product: { id: data.product.id },
            productVariantCombinations: { id: data.productVariantCombinations.id }
        };
        let result = await fetch(environment.SERVER_URL + '/cart/save', {
            method: 'post',
            body: JSON.stringify(obj),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result; 
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        } 
    },
    getProductRatingAverage: async (id) => {
        return getDataById('productRating/productRatingAverageByProductId',id);
    },
    getProductById: async(id) =>{
        return getDataById('product',id);
    }
})

export default connect(mapStatetoProps, mapDispatchToProps)(Product)