import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { Modal } from "react-bootstrap";
import { ErrorMsg } from "../../ChildComponent/messages/AllMessages";

// import sweetalert from "react-bootstrap-sweetalert";
import AddUpdateDeliveryAddressContainer from '../../Containers/AddUpdateDeliveryAddressContainer';
import swal from 'sweetalert';
import { environment } from '../../environment';
import { Context } from "./../LoadingContext";

export default function DeliveryAddress(props) {

  const [showAddUpdateAddressModal, setshowAddUpdateAddressModal] = React.useState(false);

  const [AddressList, setAddressList] = useState([]);
  const [isUpdateComponent, setUpdateComponent] = useState(false);
  const [loading, setLoading] = useContext(Context);
  const [Id, setId] = useState(null);
  let auth = null;
  let userId = null;
  let id = null;
  let user = null;
  const navigate = useNavigate();
  // let isUpdateComponent = false;
  if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
    userId = auth.referenceId;
  }

  const handleAddUpdateAddressModalClose = () => setshowAddUpdateAddressModal(false);
  const handleAddUpdateAddressModalShow = () => setshowAddUpdateAddressModal(true);

  useEffect(() => {
    if (userId != null) {
      getShippingAddress();
    }
  }, [])

  user = useSelector(state => state.UserDetail)
  const getShippingAddress = async () => {
    // setLoading(1);
    try {
    let result = await fetch(environment.SERVER_URL + "/shippingAddress/getShippingAddressByCustomer/" + userId, {
      method: 'get',
      headers: {
        "Authorization": `OEC` + ' ' + auth.token,
  
      },
    });
    result = await result.json();
    if (result.success) {
      setLoading(0);
      setAddressList(result.data);
    } else {
      setLoading(0);
    }  
    } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(0);
        return error; // Rethrow the error to handle it in the calling code
    }

  }

  const setPrimaryAddress = async (e) => {
    e.isPrimary = true;
    setLoading(1);
    try {
      let result = await fetch(environment.SERVER_URL + "/shippingAddress/update/", {
        method: 'post',
        body: JSON.stringify(e),
        headers: {
          "Authorization": `OEC` + ' ' + auth.token,
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success == false) {
        setLoading(0);
        toast.error(result.responseMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      } else {
        setLoading(0);
      }
      getShippingAddress();
    } catch (error) {
      setLoading(0);


    }

  }

  const saveDeliveryAddress = (data) => {
    handleAddUpdateAddressModalClose();
    getShippingAddress();
  }

  const cancelDeliveryAddress = (data) => {
    handleAddUpdateAddressModalClose();
  }

  const AddShippingAddress = () => {

    setUpdateComponent(false);
    setId(null);
    handleAddUpdateAddressModalShow();
  }

  const EditShippingAddress = async (e) => {

    setUpdateComponent(true);
    setId(e);
    handleAddUpdateAddressModalShow();
  }

  const DeleteShippingAddress = async (data) => {

    swal({
      textAlign: 'center',
      title: "Are you sure?",
      text: "You want to delete this record?",
      icon: "warning",
      buttons: {
        confirm: { text: 'Yes', className: 'sweet-warning' },
        cancel: 'No'

      },
      // dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setLoading(1);
          props.DeleteAddressHandler(data, user).then((val) => {
            if (val.success) {
              setLoading(0);
              toast.success("Address deleted successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              getShippingAddress();
            }
          });
        } else {

        }
      });

    // 

  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };


  const storedToken = localStorage.getItem("token");
  useEffect(() => {
    if (storedToken) {
      navigate("/shipping-address-list");

    } else {
      navigate("/login");
    }
  }, [storedToken])

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="page-wrapper">
        <main className="main account">
          {/* <nav className="breadcrumb-nav">
            <div className="container" style={{ fontSize: '15px' }}>
              <ul className="breadcrumb">
                <li><Link to={"/"}><i className="d-icon-home" style={{ fontSize: '20px' }} /></Link></li>
                <li>&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleRight} />&nbsp;&nbsp;</li>
              </ul>
            </div>
          </nav> */}
        </main>
      </div>
      <section className="contact-page register-page mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1 style={{ float: 'left' }}>DELIVERY ADDRESS</h1>
            </div>
            <div className="col-lg-4">
              <span style={{ cursor: 'pointer', float: 'right', padding: '13px 29px', fontSize: '14px' }} className="btn btn-primary mb-2" onClick={AddShippingAddress}>ADD</span>
            </div>
          </div>

          <div className="row items mb-3">
            <div className="col-sm-12 mt-3" >
              <div className="collection-product-wrapper" id="products" >
                {
                  AddressList.length > 0
                    ?
                    AddressList.map((data, i) =>
                      <div key={`address-${data?.id}-${i}`} className="top-banner-content small-section" style={{ fontSize: '14px' }}>
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12" style={{ textAlign: 'left' }} >
                            <b>{data.firsName + ' ' + data.lastName + ' , ' + data.mobileNo}<br /></b>
                            {data.address + ' , ' + data.city + ', ' + data.state.name + ',' + data.country.name}&nbsp;-&nbsp;<b>{data.pincode}</b>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-10">
                            {
                              data.isPrimary == false
                                ?
                                <button className="buttonss1" onClick={() => { setPrimaryAddress(data) }}>Make
                                  Default</button>
                                :
                                <Badge style={{ cursor: 'pointer' }} bg="success">Default</Badge>
                            }


                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-2 dropdown1" onMouseLeave={closeDropdown}>
                            <FontAwesomeIcon
                              icon={faEllipsisV}
                              onClick={toggleDropdown}
                              style={{ cursor: 'pointer' }}
                            />
                            {isDropdownOpen && (
                              <div className="dropdown-content">
                                <a onClick={() => EditShippingAddress(data.id)} style={{ cursor: 'pointer' }}>
                                  Edit
                                </a>
                                <a onClick={() => DeleteShippingAddress(data)} style={{ cursor: 'pointer' }}>
                                  Delete
                                </a>
                              </div>
                            )}
                          </div>
                          <ToastContainer></ToastContainer>


                        </div>
                      </div>
                    )
                    : <div className="product-wrap" style={{ textAlign: 'center' }}>
                    <div className="product">
                      <h2>{ErrorMsg.DATA_NOT_AVAILABLE}</h2>
                    </div>
                  </div>
                }
                <Modal show={showAddUpdateAddressModal} size="xl" animation={false} onHide={handleAddUpdateAddressModalClose}>
                  <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}>{isUpdateComponent == true ? <span>EDIT DELIVERY ADDRESS</span> : <span>ADD DELIVERY ADDRESS</span>}
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleAddUpdateAddressModalClose}></button>
                  </Modal.Header>
                  <AddUpdateDeliveryAddressContainer id={Id} isUpdateComponent={isUpdateComponent} save={saveDeliveryAddress} cancel={cancelDeliveryAddress} />
                </Modal>
              </div>




            </div>
          </div>
        </div>
      </section >
    </div >

  )
}
