import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Component/LoadingContext';
import { PaginationGridFilter, filterData, fetchData } from '../../../src/Component/SupplierServices';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { format, parse } from 'date-fns';
import no_image from "../../image/no_image.png";
import { environment } from '../../environment';
import ReactImageMagnify from 'react-image-magnify';
import ReviewList from '../../ChildComponent/Review/ReviewList'
import PaginationComponent from '../../ChildComponent/Pagination/PaginationComponent';
export default function ReviewComponent(props) {
  const [productDetails, setProductDetails] = useState({});
  const [productImage, setProductImage] = useState(null);
  const [productImageGallery, setProductImageGallery] = useState([]);
  const [customerRatingBasedOnCurrentProduct, setCustomerRatingsBasedOnCurrentProduct] = useState({});
  const reportGridFilter = new PaginationGridFilter();
  const [loading, setLoading] = useContext(Context);
  const [price, setPrice] = useState();
  const [Attribute, setAttribute] = useState([]);
  const [variantMatch, setVariantMatch] = useState(false);
  const state = useLocation();
  const Category = state;
  const [tempProductDetails, setTempProductDetails] = useState({
    quantity: '',
    isSaveOrLater: false,
    isActive: "true",
    isDeleted: "false",
    customer: { 'id': null },
    product: {
        id: null,
        name: null
    },
    productVariantCombinations: { id: null, price: 0, weight: 0, productVariantDetails: [] }
});
const [VariantCombinations, setVariantCombinations] = useState([]);
  let params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      getProductDetails();
      getProductReviewByProductId();
    }
  }, [params.id]);

  const getProductDetails = async () => {
    setLoading(1);
    const response = await fetchData(`product/${params.id}`);
    if (response.success && response.data) {
      setLoading(0);
      response.data.defaultVariant = response.data.productVariantCombinations.find(item => item.isDefaultVariant);
      setProductDetails(response.data);
    } else {
      setLoading(0);
    }
  };


  const getProductReviewByProductId = async () => {
    reportGridFilter.pageSize = 10;
    reportGridFilter.filters[0].filterColumn = 'productId';
    reportGridFilter.filters[0].idList.push(params.id);
    setLoading(1);
    const response = await filterData(`productRating/filters`, reportGridFilter);
    if (response.success && response?.data && response?.data?.content) {
      const tempCustomerRatings = setParseDateString(response.data);
      setCustomerRatingsBasedOnCurrentProduct(tempCustomerRatings);
      setLoading(0);
    } else {
      setLoading(0);
    }
  };

  //  Function to convert created Date in readable format start
  const setParseDateString = (data) => {
    data.content = data.content && data.content.map(res => {
      res.createdDate = format(
        parse(res.createdDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date()),
        "MMMM dd, yyyy"
      );
      return res;
    });
    return data;
  };
  //  Function to convert created Date in readable format end


  useEffect(() => {

    if (productDetails) {
      const fetchProductImages1 = async () => {
        const imageList = [];
        const fetchImagePromises = [];
        if (productDetails.image) {
          setLoading(true)
          const fetchImagePromiseMain = fetch(
            `${environment.SERVER_URL}/fileDownload/getProductImage/${productDetails.id}`
          )
            .then((response) => response.blob())
            .then((blob) => {
              if (blob.type !== "application/json") {
                const imageUrl = URL.createObjectURL(blob);
                imageList.push(imageUrl);
                setProductImage(imageUrl);
                setLoading(false)
              }
            }).catch((error) => {
              console.error("Error fetching product image:", error);
              setLoading(false)
              // Handle the error here, set a default image or perform any other appropriate action
              // For example, you can set a default image URL
              setProductImage(no_image);
          });
            setLoading(false)
          fetchImagePromises.push(fetchImagePromiseMain);
        }
        if (productDetails.images && productDetails.images.length > 0) {
          for (const img of productDetails.images) {
            setLoading(true)
            const fetchImagePromise = fetch(
              `${environment.SERVER_URL}/fileDownload/getProductGalleryImage/${productDetails.id}/${img.id}`
            )
              .then((response) => response.blob())
              .then((blob) => {
                if (blob.type !== "application/json") {
                  const imageUrl = URL.createObjectURL(blob);
                  imageList.push(imageUrl);
                  setLoading(false)
                }
              }).catch((error) => {
                console.error("Error fetching product image:", error);
                setLoading(false)
                // Handle the error here, set a default image or perform any other appropriate action
                // For example, you can set a default image URL
               return error
            });
              setLoading(false)
            fetchImagePromises.push(fetchImagePromise);
          }

          await Promise.all(fetchImagePromises);

          setProductImageGallery(imageList);
        }
      };

      fetchProductImages1();
    }
  }, [productDetails]);


  useEffect(() => {

    if (productDetails && productDetails.productVariantCombinations && productDetails.productVariantCombinations.length > 0) {
      productDetails.productVariantCombinations.forEach((rs) => {

        if (Category.state?.combinationId == null && rs.category.id == Category.state?.categoryId && rs.isDefaultVariant === true) {
          setVariantCombinations(rs);
          setPrice(rs.price);
          tempProductDetails.productVariantCombinations.id = rs.id;
          tempProductDetails.productVariantCombinations.price = rs.price;
          tempProductDetails.productVariantCombinations.weight = rs.weight;
          tempProductDetails.categoryId = rs.category.id;

          let obj = { attribute: '', attributeValue: '' }
          if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
            rs.productVariantDetails.forEach((e) => {
              obj = {};
              if (e && e.attribute) {
                obj.attribute = e.attribute.name;
              }
              if (e && e.attributeValue) {
                obj.attributeValue = e.attributeValue.name;
              }


              tempProductDetails.productVariantCombinations.productVariantDetails.push(obj);
            })
          }
          // tempProductDetails.productVariantCombinations.productVariantDetails = rs.productVariantDetails;
          // tempProductDetails.price = rs.price;
          if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
            rs.productVariantDetails.forEach((e) => {
              Attribute.push(e);

            })
          }

          //setAttribute(rs.productVariantDetails);

        }
        if (Category.state?.combinationId != null && rs.id === Category.state?.combinationId && rs.category.id === Category.state?.categoryId) {
          setVariantCombinations(rs);
          setPrice(rs.price);
          tempProductDetails.productVariantCombinations.id = rs.id;
          tempProductDetails.productVariantCombinations.price = rs.price;
          tempProductDetails.productVariantCombinations.weight = rs.weight;
          tempProductDetails.categoryId = rs.category.id;

          let obj = { attribute: '', attributeValue: '' }
          if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
            rs.productVariantDetails.forEach((e) => {
              obj = {};
              if (e && e.attribute) {
                obj.attribute = e.attribute.name;
              }
              if (e && e.attributeValue) {
                obj.attributeValue = e.attributeValue.name;
              }


              tempProductDetails.productVariantCombinations.productVariantDetails.push(obj);
            })
          }
          // tempProductDetails.productVariantCombinations.productVariantDetails = rs.productVariantDetails;
          // tempProductDetails.price = rs.price;
          if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
            rs.productVariantDetails.forEach((e) => {
              Attribute.push(e);

            })
          }

          //setAttribute(rs.productVariantDetails);

        }
        if (Attribute && Attribute.length > 0) {
          Attribute.forEach((attr) => {
            attr.productVariantValueDetails = [];
            if (productDetails && productDetails.productVariantCombinations && productDetails.productVariantCombinations.length > 0) {
              if (productDetails.productVariantCombinations && productDetails.productVariantCombinations.length > 0) {
                productDetails.productVariantCombinations.forEach((rs) => {
                  if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
                    rs.productVariantDetails.forEach((ls) => {
                      if (attr.attribute.id === ls.attribute.id) {
                        let find = attr.productVariantValueDetails.find(x => x.attributeValue.id === ls.attributeValue.id);
                        if (!find) {
                          ls.isDefaultVariant = true;
                          attr.productVariantValueDetails.push(ls);
                        }
                      }
                    })

                  }
                })
              }

            }

          })
        }

      })
    }
  }, [productDetails.productVariantCombinations])

  const handlePageChange = (pageNumber) => {
    reportGridFilter.page = pageNumber; // Update the page property of reportGridFilter
    getProductReviewByProductId(); // Fetch data for the new page
  };


  const activeImageStyle = {
    border: '2px solid #007bff',
  };

  return (
    <div>
      <div className="page-wrapper">
        <main className="main mt-6 single-product">
          <div className="page-content mb-10 pb-6 ">
            <div className="container">
              <div className="product product-single row mb-7" >
                <div className="col-md-5 sticky-sidebar-wrapper" style={{ zIndex: 1, border: '1px solid lightgray', borderRadius: '3px' }}>
                  <div className="product-gallery pg-vertical sticky-sidebar mb-0" data-sticky-options="{'minWidth': 767}" style={{ zIndex: 1, }}>
                    <div className="product-single-carousel  owl-theme owl-nav-inner row cols-1 gutter-no" style={{ zIndex: 1, }}>
                      <a onClick={() => { navigate('/product/' + productDetails.id, { state: { categoryId: productDetails?.defaultVariant?.category?.id , combinationId: productDetails?.defaultVariant?.id } }) }}>
                        <figure className="product-images">
                          {productImage ? (
                            <ReactImageMagnify
                              disableZoom={true}
                              smallImage={{
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: false,
                                src: productImage,
                                width: 325,
                                height: 400,
                              }}
                              largeImage={{
                                src: productImage,
                                width: 800,
                                height: 1200,
                              }}
                              enlargedImageContainerStyle={{
                                backgroundColor: 'white',
                              }}
                            />
                          ) : (
                            <img
                              src={no_image}
                              alt="No Image"
                              width={325}
                              height={400}
                            />
                          )}
                        </figure>
                      </a>
                    </div>
                    {/* sidebar img */}
                    <div className="product-thumbs-wrap">
                      <div className="product-thumbs" style={{ overflowY: 'auto' }}>
                        {productImageGallery.map((imageUrl, index) => (
                          <div className="product-thumb" key={index} onMouseEnter={() => {
                            setProductImage(imageUrl);
                          }}
                            onMouseLeave={() => {

                              setProductImage(productImage);
                            }}
                          >
                            <img src={imageUrl} alt="product thumbnail" width={109} height={122} style={productImage === imageUrl ? activeImageStyle : {}} />
                          </div>
                        ))}
                      </div>


                      <button className="thumb-up disabled"><i className="fas fa-chevron-left" /></button>
                      <button className="thumb-down disabled"><i className="fas fa-chevron-right" /></button>
                    </div>
                    {/* <div className="product-label-group">
                      <label className="product-label label-new">new</label>
                    </div> */}
                  </div>
                  <div className="product-details mt-4" >
                    <h1 className="product-name" style={{ textAlign: 'left', }}>
                      <a onClick={() => { navigate('/product/' + productDetails.id, { state: { categoryId: productDetails?.defaultVariant?.category?.id, combinationId: productDetails?.defaultVariant?.id } }) }}>
                        {productDetails.name}
                      </a>
                    </h1>
                    <div className="product-meta" style={{ textAlign: 'left' }}>
                      Code: <span className="product-sku">{productDetails.code}</span>
                      Series: <span className="product-brand">{productDetails.series}</span>
                    </div>
                    <div className="product-price" style={{ textAlign: 'left' }}>&#8377;{200}</div>
                    <p style={{ textAlign: 'left', overflowWrap: 'break-word' }} className="product-short-desc">{productDetails.longDescription}</p><br></br>
                    {
                      Attribute && Attribute.length > 0
                        ?
                        Attribute.map((res) =>
                          <div className="product-form product-variations product-color shipping-address" style={{ padding: '0px' }}>
                            <label style={{ position: 'relative' }} className='productColors'>{res.attribute.name}:</label>
                            <div className="select-boxs">
                              <select name="color"  >
                                {res.productVariantValueDetails && res.productVariantValueDetails.length > 0
                                  ?
                                  res.productVariantValueDetails.map((ls) =>

                                    <option value={ls.attributeValue.id} selected={res.attributeValue.id == ls.attributeValue.id}>{ls.attributeValue.name}</option>

                                  )
                                  :
                                  ''
                                }
                              </select>
                            </div>
                          </div>
                        )
                        : ''
                    }
                    <div className="product-variation-price">
                      <span>$239.00</span>
                    </div>
                    {
                      variantMatch
                        ?
                        <p style={{ textAlign: 'left', overflowWrap: 'break-word', color: 'red' }} className="product-short-desc">Combination not found, Please try different combination.</p>
                        : ''
                    }
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="comments pt-2 pb-10 border-no">
                    <ReviewList customerRatingBasedOnCurrentProduct={customerRatingBasedOnCurrentProduct}  data={props.data} isRateProductBtn={false} />
                    <PaginationComponent list={customerRatingBasedOnCurrentProduct} setCurrentPage={handlePageChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main >
      </div >
    </div >
  );
}
