import { environment } from '../environment';

const getAuthUser = () => {
    if (localStorage.getItem('authUser')) {
       return JSON.parse(localStorage.getItem('authUser'));
    }
    return null;
}
export const fetchData = async (key) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${key}`;
    const headers = {
        "Content-Type": "application/json"
    };
    // Check if auth.token exists and is not null
    if (auth && auth.token) {
        headers["Authorization"] = `OEC ${auth.token}`;
    }
    try {
        let result = await fetch(url, {
            method: 'get',
            headers: headers
        });
        return await result.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        return error; // Rethrow the error to handle it in the calling code
    }
};

export const saveData = async (key, data) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${key}/${data.id == null ? 'save' : 'update'}`;
    try {
        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result;
    } catch (error) {
        console.error('Error posting data:', error);
        return error; // Rethrow the error for the caller to handle
    }
};


export const getData = async (key) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${key}`;
    try {
        const result = await fetch(url, {
            method: 'get',
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        return await result.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        return error; // Rethrow the error to handle it in the calling code
    }
};

export const saveWishList = async (key, data) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${key}`;
    try {
        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result;
    } catch (error) {
        console.error('Error posting data:', error);
        return error; // Rethrow the error for the caller to handle
    }
};


export const save = async (key, data) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${key}`;
    try {
        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result;
    } catch (error) {
        console.error('Error posting data:', error);
        return error; // Rethrow the error for the caller to handle
    }
};


export const filterData = async (key, data) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${key}`;
    const headers = {
        "Content-Type": "application/json"
    };
    
    // Check if auth.token exists and is not null
    if (auth && auth.token) {
        headers["Authorization"] = `OEC ${auth.token}`;
    }
    try {
        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: headers,
        });
        result = await result.json();
        return result;
    } catch (error) {
        console.error('Error posting data:', error);
        return error; // Rethrow the error for the caller to handle
    }
};






export const getDataById = async (apiUrl,id) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${apiUrl}/${id}`;
    const headers = {
        "Content-Type": "application/json"
    };
    // Check if auth.token exists and is not null
    if (auth && auth.token) {
        headers["Authorization"] = `OEC ${auth.token}`;
    }
    try {
        let result = await fetch(url, {
            method: 'get',
            headers: headers
        });
        return await result.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        return error; // Rethrow the error to handle it in the calling code
    }
};
export const getAllData = async (apiUrl) => {
    const auth = getAuthUser();
    const url = `${environment.SERVER_URL}/${apiUrl}`;
    const headers = {
        "Content-Type": "application/json"
    };
    // Check if auth.token exists and is not null
    if (auth && auth.token) {
        headers["Authorization"] = `OEC ${auth.token}`;
    }
    try {
        let result = await fetch(url, {
            method: 'get',
            headers: headers
        });
        return await result.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        return error; // Return the error object
    }
};



export class PaginationGridFilter {
    constructor() {
        this.page = 1;
        this.pageSize = 5;
        this.sortBy = 'id';
        this.sortOrder = 0;
        this.filters = [{
            filterColumn : '',
            filterValue : undefined,
            idList : [],
            wordList : [],
        }];
    }
}