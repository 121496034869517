import React from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

export default function Footer(props) {
  const navigate = useNavigate();

  const categories = useSelector(state => {
    if (state.HeaderMenu) {
        const allCategories = [];
        state.HeaderMenu.forEach(element => {
            allCategories.push(...element.HeaderMenu);
        });
        return allCategories;
    } else {
        return [];
    }
});



  const redirectCart = () => {
    navigate("/cart");
  };

  const redirectWishList = () => {
    navigate("/wishlist");
  };

  const redirectOrderHistory = () => {
    if (localStorage.getItem('authUser')) {
      navigate("/myOrder");
    } else {
      navigate("/login");
    }
  }
  
  return (
    <div>
      <footer className="footer">
        <div className="container">
          {/* <div className="footer-top">
              <div className="row align-items-center" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div className="col-12 col-md-12 col-lg-3 col-xl-2">
                  <a href="" className="logo-footer">
                    <div className="row">
                      <div className="col-5 col-md-6 col-lg-4 logos text-align-end" >
                        <img src={logos} alt="logo" width={50} height={50} className='m-0  float-end'/>
                      </div>
                      <div className="col-7 col-md-6 col-lg-8 titleTexts logoTitles">
                          <span>MG</span> <span>Store</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-12 col-lg-9 col-xl-10">
                  <div className="row widget widget-newsletter form-wrapper form-wrapper-inline">
                    <div className="col-md-6 col-lg-6  col-xl-5  newsletter-info mx-auto mr-lg-2 ml-lg-4">
                      <h4 className="widget-title" style={{ textAlign: "left" }}>Subscribe to our Newsletter</h4>
                      <p style={{ textAlign: "left" }}>Get all the latest information, Sales and Offers.</p>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-7'>
                    <form action="#" className="input-wrapper input-wrapper-inline d-flex">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Email address here..." required />
                      <button className="btn btn-primary btn-rounded btn-md ml-2" type="submit">subscribe<i className="d-icon-arrow-right" /></button>
                    </form>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* End FooterTop */}
          <div className="footer-middle">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <div className="widget widget-info">
                  <h4 className="widget-title" style={{ textAlign: "left" }}>Contact Info</h4>
                  <ul className="widget-body" style={{ textAlign: "left" }}>
                    <li>
                      <label>Phone:</label>
                      <a href="tel:9426344388" className='anchorwhite'> 9426344388</a>
                    </li>
                    <li>
                      <label>Email:</label>
                      <a href="mailto:info.mymgstore@gmail.com" className='anchorwhite'> info.mymgstore@gmail.com</a>
                    </li>
                    <li>
                      <label>Address:</label>
                      <span className='anchorwhite'> SHED NO-26, 1ST FLOOR, VARUN INDUTRIAL AREA,
                        KOTHARIYA MAIN ROAD, KOTHARIYA, RAJKOT, Rajkot,
                        Gujarat, 360022</span>
                    </li>
                  </ul>
                </div>
                {/* End Widget */}
              </div>
              <div className="col-6 col-md-4  col-lg-4">
                <div className="widget ml-lg-4">
                  <h4 className="widget-title" style={{ textAlign: "left" }}>Categories</h4>
                  <ul className="widget-body" style={{ textAlign: "left" }}>
                    {categories && categories.slice(0, 7).map((item, index) => (
                      <li key={item?.id}>
                        <a onClick={() => { navigate('/shop/products/' + item?.id) }} className='anchorwhite'>{item?.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* End Widget */}
              </div>
              <div className="col-6 col-md-3 col-lg-4">
                <div className="widget ml-lg-4">
                  <h4 className="widget-title" style={{ textAlign: "left" }}>Others</h4>
                  <ul className="widget-body" style={{ textAlign: "left" }}>
                    <li onClick={redirectCart} >
                      <a className='anchorwhite'>View Cart</a>
                    </li>
                    <li onClick={redirectWishList}>
                      <a className='anchorwhite'>My Wishlist</a>
                    </li>
                    <li>
                      <a href="/" className='anchorwhite'>About Us</a>
                    </li>
                        <li onClick={redirectOrderHistory}>
                          <a className='anchorwhite'>Order History</a>
                        </li>
                    <li>
                      <a href="/" className='anchorwhite'>Terms &amp; Condition</a>
                    </li>
                  </ul>
                </div>
                {/* End Widget */}
              </div>
            </div>
          </div>
          {/* End FooterMiddle */}
          <div className="footer-bottom">
            <div className="footer-left">
              {/* <figure className="payment">
                  <img src="images/payment.png" alt="payment" width={159} height={29} />
                </figure> */}
            </div>
            <div className="footer-center">
              <p className="copyright">© 2024. Powered By <a target='_' href='https://www.techiebrothers.in/' style={{ color: 'white' }}>Techie Brothers</a></p>
            </div>
            <div className="footer-right">
              {/* <div className="social-links">
                  <a href="#" title="social-link" className="social-link social-facebook fab fa-facebook-f" />
                  <a href="#" title="social-link" className="social-link social-twitter fab fa-twitter" />
                  <a href="#" title="social-link" className="social-link social-linkedin fab fa-linkedin-in" />
                </div> */}
            </div>
          </div>
          {/* End FooterBottom */}
        </div>
      </footer>
    </div>
  )
}
