import React, { useEffect, useState, useContext } from 'react'
import Badge from 'react-bootstrap/Badge';
import { useParams } from 'react-router-dom';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { Number, Currency } from "react-intl-number-format"
import no_image from "../image/no_image.png";
import { environment } from '../environment';
import { Context } from "../Component/LoadingContext";
export default function OrderDetails() {
  const params = useParams()
  useEffect(() => {
    getOrderDetails();
  }, [])

  const [OrderDetail, setOrderDetail] = useState({});
  const [ProductData, setProductData] = useState([]);
  const [loading, setLoading] = useContext(Context);

  let auth = null;

  if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
  }


  const getOrderDetails = async () => {
    setLoading(1);
    try {
    let result = await fetch(environment.SERVER_URL + "/order/" + params.id, {
      method: 'get',
      headers: {
        "Authorization": `OEC` + ' ' + auth.token,
      },
    });
    result = await result.json();
    if (result.success) {
      setLoading(0);
      setOrderDetail(result.data);
      setProductData(result.data.orderItemDetails);
    } else {
      setLoading(0);
    }    
    } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(0);
        return error; // Rethrow the error to handle it in the calling code
    }
  }

  const customerInvoiceDownload = () => {
    let url = OrderDetail.invoiceUrl;
    if (url != null) {
      window.open(url, '_parent');
    } else {
      toast.error("Invoice not generated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",

      });
    }
  }

  const navigate = useNavigate();
  let CategoryId = null;
  if (localStorage.getItem('CategoryWiseProducts')) {
    CategoryId = JSON.parse(localStorage.getItem('CategoryWiseProducts'));
  }
  return (
    <div>
      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ml-1 mr-1 mb-3 mt-4">
              <Link to={'/myOrder'}><button type="button" style={{ borderRadius: '5px', float: 'right', fontSize: '13px', fontWeight: 'bold' }} className="btn btn-outline-secondary">Back</button></Link>
              {/* <h2 className="float-right" routerLink="/orders/my-orders" role="button"><i className="fa fa-arrow-left" aria-hidden="true"></i></h2> */}
              <h1 className="mb-2" style={{ float: 'left' }}>Order Details</h1>
            </div>
            <div className="col-lg-12 ml-1 mr-1 mb-3 mt-4">

              <h3 className="ordered-on float-left" style={{ float: 'left' }}>Ordered On &nbsp;

                {
                  OrderDetail.dateTime ? moment(OrderDetail.dateTime).format("DD MMM YYYY") : null
                }</h3>

              {
                OrderDetail.status && OrderDetail.status.keyName == 'delivered' ?
                  <>
                    <h4 className="d-inline-block" style={{ float: 'right' }}>
                      <strong>Invoice: </strong>

                      {
                        OrderDetail.invoiceUrl != null ?
                          <>
                            <Badge bg="primary" style={{ cursor: 'pointer' }}>
                              <span onClick={customerInvoiceDownload}>Download</span>
                            </Badge>
                          </>
                          : null
                      }
                      <ToastContainer></ToastContainer>

                    </h4>
                  </>
                  : null
              }

            </div>

            <div className="row col-md-12 ml-1 mr-1 order-success-sec">
              <div className="col-sm-12 col-md-6 col-lg-3">
                <h4 className='Order'>Order Summary</h4>
                <ul className="order-detail">
                  <li className='order-detailli'>order ID: &nbsp; <strong> {OrderDetail.orderTxnId}</strong></li>
                  <li className='order-detailli'>Order Date: &nbsp;<strong> {OrderDetail.dateTime !== null ? moment(OrderDetail.dateTime).format('D-MM-yyyy') : ''}</strong></li>
                  <li className='order-detailli'>Order Total: &nbsp;<strong> <Currency locale="en-US" currency="INR">{OrderDetail.total}</Currency></strong></li>

                  {
                    OrderDetail.status && OrderDetail.status.keyName == 'order_cancelled' ?
                      <>
                        <li className='order-detailli'>Order Status: &nbsp;<Badge bg="danger" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '1px', marginTop: '0px' }}>{OrderDetail.status.name}</Badge>
                        </li>
                      </>
                      : null
                  }
                  {
                    OrderDetail.status && OrderDetail.status.keyName == 'order_placed' ?
                      <>
                        <li className='order-detailli'>Order Status: &nbsp;<Badge bg="success" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '1px', marginTop: '0px' }}>{OrderDetail.status.name}</Badge>
                        </li>
                      </>
                      : null
                  }
                  {
                    OrderDetail.status && OrderDetail.status.keyName == 'order_pending' ?
                      <>
                        <li className='order-detailli'>Order Status: &nbsp;<Badge bg="primary" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '1px', marginTop: '0px' }}>{OrderDetail.status.name}</Badge>
                        </li>
                      </>
                      : null
                  }

                  {
                    OrderDetail.status && OrderDetail.status.keyName == 'delivered' ?
                      <>
                        <li className='order-detailli'>Order Status: &nbsp;<Badge bg="primary" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '1px', marginTop: '0px' }}>{OrderDetail.status.name}</Badge>
                        </li>
                      </>
                      : null
                  }
                </ul>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <h4 className='Shipping'>Shipping Address</h4>
                {
                  OrderDetail?.shippingAddress ?
                    <ul className="order-detail">
                      <li className='order-detailli'>{OrderDetail.shippingAddress.address},</li>
                      <li className='order-detailli'>{OrderDetail.shippingAddress.state?.name},</li>
                      <li className='order-detailli'>{OrderDetail.shippingAddress.city},{OrderDetail.shippingAddress.pincode},</li>
                      <li className='order-detailli'>{OrderDetail.shippingAddress.country?.name},</li>
                      <li className='order-detailli'>Contact No. {OrderDetail.shippingAddress.mobileNo}</li>
                    </ul>
                    : null

                }

              </div>

              <div className="col-md-12 col-lg-3 payment-mode">
                <h4 className='Track'>Track Your Order</h4>
                {
                  !OrderDetail.awbNumber ?
                    <p className='order-detaillis'>NA</p>
                    :
                    <><a href="https:/shiprocket.co/tracking/{orderDetail.awbNumber}"
                      target="_blank">{OrderDetail.awbNumber}</a><br></br></>
                }

                {
                  OrderDetail.isRefundInitiated || OrderDetail.isRefundProcessed || OrderDetail.isRefundFailed
                    ?
                    OrderDetail?.isRefundProcessed
                      ?
                      <Badge bg="danger" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '-140px' }}>Refund Processed</Badge>
                      :
                      OrderDetail?.isRefundFailed
                        ?
                        <Badge bg="danger" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '-140px' }}>Refund Failed</Badge>
                        :
                        <Badge bg="danger" style={{ paddingTop: '7px', fontSize: '12px', marginLeft: '-140px' }}>{OrderDetail.refundStatus}</Badge>
                    :
                    null
                }
              </div>

              <div className="col-md-12 col-lg-3">
                {
                  OrderDetail.status && OrderDetail.status.keyName == 'picked_up'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Expected Date Of Delivery</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.etd).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail?.etd
                            ?
                            <h2>NA</h2>
                            :
                            null
                        }

                      </div>
                    </>
                    :
                    null
                }

                {
                  OrderDetail.status && OrderDetail.status.keyName == 'delivered'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Delivered On Date</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.updatedDate).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail.updatedDate
                            ?
                            <h2>NA</h2>
                            : null
                        }

                      </div>
                    </>
                    :
                    null
                }

                {
                  OrderDetail.status && OrderDetail.status.keyName == 'order_cancelled'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Order Cancelled On Date</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.updatedDate).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail.updatedDate
                            ?
                            <h2>NA</h2>
                            : null
                        }

                      </div>
                    </>
                    :
                    null
                }

                {
                  OrderDetail.status && OrderDetail.status.keyName == 'order_placed'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Order Placed On Date</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.updatedDate).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail.updatedDate
                            ?
                            <h2>NA</h2>
                            : null
                        }

                      </div>
                    </>
                    :
                    null
                }

                {
                  OrderDetail.status && OrderDetail.status.keyName == 'order_failed'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Order Failed On Date</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.updatedDate).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail.updatedDate
                            ?
                            <h2>NA</h2>
                            : null
                        }

                      </div>
                    </>
                    :
                    null
                }

                {
                  OrderDetail.status && OrderDetail.status.keyName == 'order_pending'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Order Pending On Date</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.updatedDate).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail.updatedDate
                            ?
                            <h2>NA</h2>
                            : null
                        }

                      </div>
                    </>
                    :
                    null
                }

                {
                  OrderDetail.status && OrderDetail.status.keyName == 'refund_failed'
                    ?
                    <>
                      <div className="delivery-sec">
                        <h3>Refund Failed On Date</h3>
                        <h2>{OrderDetail.updatedDate ? moment(OrderDetail.updatedDate).format('D-MM-yyyy') : ''}</h2>
                        {
                          !OrderDetail.updatedDate
                            ?
                            <h2>NA</h2>
                            : null
                        }

                      </div>
                    </>
                    :
                    null
                }
              </div>
            </div>

            {

              ProductData && ProductData.length > 0 ?
                ProductData.map((item, i) =>

                  <div className="row col-md-12 mt-3 align-items-center ml-1 mr-1 order-success-sec">
                    <div className="col-12 col-xs-4 col-sm-4 col-md-2" style={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
                      <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.productVariantCombinations.category.id, combinationId: item?.productVariantCombinations?.id } }) }}>
                        <img
                          className="img-fluid"
                          src={`${environment.SERVER_URL}/fileDownload/getProductImage/${item.product.id}`}
                          style={{ height: '130px', width: '130px', cursor: 'pointer' }}
                          onError={(e) => {
                            e.target.src = no_image;
                          }}
                        />
                        {/* <img className="img-fluid" src={no_image} style={{ height: '130px', width: '130px' }} /> */}
                      </a>
                    </div>
                    <div className="col-4  col-sm-4 col-md-2 orderDetailsHeading" style={{ textAlign: 'left' }}>
                      <a style={{ color: 'black' }}>
                        <h4>Product </h4>
                        <p className='order-detailli' style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.productVariantCombinations.category.id, combinationId: item?.productVariantCombinations?.id } }) }}>{item.product.name}</p>
                      </a>
                    </div>

                    <div className="col-4  col-sm-4 col-md-2 orderDetailsHeading" style={{ textAlign: 'left' }}>
                      <a style={{ color: 'black' }}>
                        <h4>Product Variant</h4>
                        <p className='order-detailli' style={{ fontSize: '14px' }}>
                          {
                            item.productVariantCombinations.productVariantDetails && item.productVariantCombinations.productVariantDetails.length > 0
                              ?
                              item.productVariantCombinations.productVariantDetails.map((rs) => (
                                <>{rs.attribute.name}-{rs.attributeValue.name}<br></br></>
                              ))
                              : null
                          }
                        </p>
                      </a>
                    </div>

                    <div className="col-4  col-sm-4 col-md-2 orderDetailsHeading" style={{ textAlign: 'left' }}>
                      <h4>Price</h4>
                      <h5 className="order-detailli"><Currency locale="en-US" currency="INR">{item.productVariantCombinations.price ? item.productVariantCombinations.price : 0}</Currency></h5>
                    </div>
                    <div className="col-4  col-sm-4 col-md-2 orderDetailsHeading" style={{ textAlign: 'left' }}>
                      <h4>Quantity</h4>
                      <h5 className="order-detailli">{item.quantity}</h5>
                    </div>
                    <div className="col-4 col-sm-4 col-md-2 orderDetailsHeading" style={{ textAlign: 'left' }}>
                      <h4>Amount</h4>
                      <h5 className='order-detailli'><Currency locale="en-US" currency="INR">{item.price}</Currency></h5>
                    </div>
                  </div>
                )
                : null
            }

            <div className="row col-md-12 product-order">
              <div className="col-md-12">
                <hr></hr>
                <ul>
                  <li className='d-block order-detailli'>
                    <h4 style={{ float: "left", margin: '0px 0px 0px 0px' }}>Subtotal</h4><h4><span style={{ float: 'right' }}><Currency locale="en-US" currency="INR">{OrderDetail.subTotal}</Currency></span></h4>
                  </li><br></br>
                  <li className='d-block order-detailli'>
                    <h4 style={{ float: "left", margin: '0px 0px 0px 0px' }}>Shipping (+)</h4><h4><span style={{ float: 'right' }}><Currency locale="en-US" currency="INR">{OrderDetail.total - OrderDetail.subTotal}</Currency></span></h4>

                  </li><br></br>
                  <li className='d-block order-detailli'>
                    <h4 style={{ float: "left", margin: '0px 0px 0px' }}>Discount (-)</h4><h4><span style={{ float: 'right', color: 'green' }}><Currency locale="en-US" currency="INR">{OrderDetail.overAllDiscount}</Currency></span></h4>

                  </li><br></br>
                </ul>
                <hr></hr>
                <h1 style={{ float: "left" }}>TOTAL</h1><h1><span className="float-right" style={{ float: 'right' }}><Currency locale="en-US" currency="INR">{OrderDetail.total}</Currency></span></h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
