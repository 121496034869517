import React, { useState, useEffect, useContext } from 'react';
import { BiChevronRight } from "react-icons/bi";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { environment } from "../environment";
import { CartContext, SubTotalContext,AuthUserContext } from "../Component/CartContext";
import { Context } from "../Component/LoadingContext";
import { calculateSubTotal } from "../Component/Common";
import ReactImageMagnify from 'react-image-magnify';
import no_image from "../image/no_image.png";
import { getData, PaginationGridFilter, filterData,saveWishList,fetchData } from "../../src/Component/SupplierServices"
import { format, parse } from 'date-fns';
import ReviewList from '../ChildComponent/Review/ReviewList'
import ReviewAddUpdate from '../ChildComponent/Review/ReviewAddUpdate'
import { Badge } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { WishListCountContext } from '../Component/CartContext';
import { Modal } from "react-bootstrap";
import LoginContainer from '../Containers/LoginContainer';
export default function Product(props) {
    //declare common useState for review form field start
    const [formData, setFormData] = useState({
        id: null,
        rating: 0,
        description: '',
        ratingError: false,
        descriptionError: false,
        title: '', // Add title property
        customer: {},
        product: {},
    });
    const [checked, setChecked] = useState(false);
    const { wishListCount, setWishListCount } = useContext(WishListCountContext);
    //declare common useState for review form field end
    const [isProductDelivered, setIsProductDelivered] = useState(Boolean)
    const [customerRatingBasedOnCurrentProduct, setCustomerRatingsBasedOnCurrentProduct] = useState({})
    const reportGridFilter = new PaginationGridFilter()
    const [showForm, setShowForm] = useState(false);
    const [productRatingAverage, setProductRatingAverage] = useState();
    const { id } = useParams();
    environment.cartUrl = id;
    const navigate = useNavigate();
    let params = useParams();
    const state = useLocation();
    const Category = state;
    // let authUser = null;
    // if (localStorage.getItem('authUser')) {
    //     authUser = JSON.parse(localStorage.getItem('authUser'));
    // }
    const [showLoginModal, setShowLoginModal] = React.useState(false);
    const handleLoginModalClose = () => setShowLoginModal(false);
    const handleLoginModalShow = () => setShowLoginModal(true);
    const [productDetails, setProductDetails] = useState({});
    const [productImage, setProductImage] = useState(null);
    const [productImageGallery, setProductImageGallery] = useState([]);
    const [loading, setLoading] = useContext(Context);
    const [tempProductDetails, setTempProductDetails] = useState({
        quantity: '',
        isSaveOrLater: false,
        isActive: "true",
        isDeleted: "false",
        customer: { 'id': null },
        product: {
            id: null,
            name: null
        },
        productVariantCombinations: { id: null, price: 0, weight: 0, productVariantDetails: [] }
    });
    const [VariantCombinations, setVariantCombinations] = useState([]);
    const [price, setPrice] = useState();
    const [products, setProducts] = useState([]);
    const [Attribute, setAttribute] = useState([]);
    const [tempArray, setTempArray] = useState([]);
    const { authUser, setAuthUser } = useContext(AuthUserContext);

    const [tempVariantComb, setTempVariantComb] = useState([]);
    const [Quantity, setQuantity] = useState(1);
    const [cartItem, setCartItem] = useState(null);
    const [variantMatch, setVariantMatch] = useState(false);
    const { cartCount, setCartCount } = useContext(CartContext);
    const { subTotal, setSubTotal } = useContext(SubTotalContext);
    //let Quantity = 1;
    useEffect(() => {
        if (localStorage.getItem("cartItems")) {
            setCartItem(JSON.parse(localStorage.getItem("cartItems")));
        } else {
            setCartItem([]);
        }
    }, [])
    const handleLoginSuccess = () => {
        setShowLoginModal(false); // Close the login modal
       // window.location.reload(); // Reload the page
    };
    let tempAttr = [];
    let obj = {
        attribute: {},
        ProductVariantValue: {}
    }


    useEffect(() => {
        if (authUser) {
            tempProductDetails.customer.id = authUser.referenceId;
        }
    })


    let auth = null;

    if (localStorage.getItem('authUser')) {
      auth = JSON.parse(localStorage.getItem('authUser'));
    }
    // useEffect(() => {
    //     setAuthUser(getAuthUser);
    // }, []);

    // const getAuthUser = () => {
    //     if (localStorage.getItem('authUser')) {
    //        return JSON.parse(localStorage.getItem('authUser'));
    //     }
    //     return null;
    // }


    // useEffect(() => {
    //     if (params.id) {
    //         getProductDetails();
    //         getProductRatingDetailsByCustomerIdAndProductId();
    //         getIsProductDeliveredOrNotByCustomerIdAndProductId();
    //         getProductReviewByProductId();
    //     }
    //     getProductRatingAverage(params.id);

    // }, [params.id])

    // useEffect(() => {
    //         getProductDetails();
    //         getProductRatingDetailsByCustomerIdAndProductId();
    //         getIsProductDeliveredOrNotByCustomerIdAndProductId();
    //         getProductReviewByProductId();
    //         getProductRatingAverage(params.id);
    // }, [authUser]);
    useEffect(() => {
        if (params.id) {
            getProductDetails();
            getProductRatingDetailsByCustomerIdAndProductId();
            getIsProductDeliveredOrNotByCustomerIdAndProductId();
            getProductReviewByProductId();
            getProductRatingAverage(params.id);
        }
    }, [params.id, authUser]);


    const getProductDetails = async () => {
        setLoading(1);
        try {
            props.getProductById(params.id).then((res) => {
                if (res.success && res.data) {
                    setLoading(0);
                    setProductDetails(res.data);
                    setProducts(res.data)
                    setTempVariantComb(res.data.productVariantCombinations);
                } else {
                    setLoading(0);
                }
            })
        } catch (error) {
            setLoading(0);

        }
    }

    const getProductRatingAverage = async (id) => {
        setLoading(1)
        props.getProductRatingAverage(id).then((res) => {
            if(res.success && res.data){
                setProductRatingAverage(res.data);
                setLoading(0)
            }else{
                setLoading(0)
            }
        })
    }
    useEffect(() => {
        tempProductDetails.product.id = productDetails.id;
        tempProductDetails.product.name = productDetails.name;
        if (productDetails) {
            const fetchProductImages1 = async () => {
                const imageList = [];
                const fetchImagePromises = [];
                if (productDetails.image) {
                    const fetchImagePromiseMain = fetch(
                        `${environment.SERVER_URL}/fileDownload/getProductImage/${productDetails.id}`
                    )
                        .then((response) => response.blob())
                        .then((blob) => {
                            if (blob.type !== "application/json") {
                                const imageUrl = URL.createObjectURL(blob);
                                imageList.push(imageUrl);
                                setProductImage(imageUrl);
                            }
                        }).catch((error) => {
                            console.error("Error fetching product image:", error);
                            // Handle the error here, set a default image or perform any other appropriate action
                            // For example, you can set a default image URL
                            setProductImage(no_image);
                        });

                    fetchImagePromises.push(fetchImagePromiseMain);
                }
                if (productDetails.images && productDetails.images.length > 0) {
                    for (const img of productDetails.images) {
                        const fetchImagePromise = fetch(
                            `${environment.SERVER_URL}/fileDownload/getProductGalleryImage/${productDetails.id}/${img.id}`
                        )
                            .then((response) => response.blob())
                            .then((blob) => {
                                if (blob.type !== "application/json") {
                                    const imageUrl = URL.createObjectURL(blob);
                                    imageList.push(imageUrl);
                                }
                            }).catch((error) => {
                                console.error('Error fetching image:', error);
                                // Handle the error as needed, e.g., logging or displaying a message to the user.
                                return error
                            });

                        fetchImagePromises.push(fetchImagePromise);
                    }

                    await Promise.all(fetchImagePromises);

                    setProductImageGallery(imageList);
                }
            };
            fetchProductImages1();
        }
    }, [productDetails]);

    useEffect(() => {
        setProductVariantCombination();
    }, [productDetails.productVariantCombinations])

    const setProductVariantCombination = async () => {
        if (productDetails && productDetails.productVariantCombinations && productDetails.productVariantCombinations.length > 0) {
            productDetails.productVariantCombinations.forEach((rs) => {
                if (Category.state?.combinationId == null && rs.category.id == Category.state?.categoryId && rs.isDefaultVariant === true) {
                    setVariantCombinations(rs);
                    setPrice(rs.price);
                    tempProductDetails.productVariantCombinations.id = rs.id;
                    tempProductDetails.productVariantCombinations.price = rs.price;
                    tempProductDetails.productVariantCombinations.weight = rs.weight;
                    tempProductDetails.categoryId = rs.category.id;
                    let obj = { attribute: '', attributeValue: '' }
                    if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
                        rs.productVariantDetails.forEach((e) => {
                            // Check if an object with the same id already exists
                            const existingObjIndex = tempProductDetails.productVariantCombinations.productVariantDetails.findIndex(obj => obj.id === e.id);

                            // If an object with the same id exists, update its properties
                            if (existingObjIndex !== -1) {
                                const existingObj = tempProductDetails.productVariantCombinations.productVariantDetails[existingObjIndex];
                                if (e && e.attribute) {
                                    existingObj.attribute = e.attribute.name;
                                }
                                if (e && e.attributeValue) {
                                    existingObj.attributeValue = e.attributeValue.name;
                                }
                            } else {
                                obj = {};
                                if (e && e.id) {
                                    obj.id = e.id;
                                }
                                if (e && e.attribute) {
                                    obj.attribute = e.attribute.name;
                                }
                                if (e && e.attributeValue) {
                                    obj.attributeValue = e.attributeValue.name;
                                }
                                tempProductDetails.productVariantCombinations.productVariantDetails.push(obj);
                            }
                        })
                    }
                    if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
                        rs.productVariantDetails.forEach((e) => {
                            const exists = Attribute.some(attr => attr.id === e.id);
                            if (!exists) {
                                Attribute.push(e);
                            }
                        });
                    }
                }
                if (Category.state?.combinationId != null && rs.id === Category.state?.combinationId && rs.category.id === Category.state?.categoryId) {
                    setVariantCombinations(rs);
                    setPrice(rs.price);
                    tempProductDetails.productVariantCombinations.id = rs.id;
                    tempProductDetails.productVariantCombinations.price = rs.price;
                    tempProductDetails.productVariantCombinations.weight = rs.weight;
                    tempProductDetails.categoryId = rs.category.id;
                    let obj = { attribute: '', attributeValue: '' }
                    if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
                        rs.productVariantDetails.forEach((e) => {
                             // Check if an object with the same id already exists
                             const existingObjIndex = tempProductDetails.productVariantCombinations.productVariantDetails.findIndex(obj => obj.id === e.id);

                             // If an object with the same id exists, update its properties
                             if (existingObjIndex !== -1) {
                                 const existingObj = tempProductDetails.productVariantCombinations.productVariantDetails[existingObjIndex];
                                 if (e && e.attribute) {
                                     existingObj.attribute = e.attribute.name;
                                 }
                                 if (e && e.attributeValue) {
                                     existingObj.attributeValue = e.attributeValue.name;
                                 }
                             } else {
                                obj = {};
                                if (e && e.id) {
                                    obj.id = e.id;
                                }
                                if (e && e.attribute) {
                                    obj.attribute = e.attribute.name;
                                }
                                if (e && e.attributeValue) {
                                    obj.attributeValue = e.attributeValue.name;
                                }
                                tempProductDetails.productVariantCombinations.productVariantDetails.push(obj);
                             }
                        })
                    }
                    if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
                        rs.productVariantDetails.forEach((e) => {
                            const exists = Attribute.some(attr => attr.id === e.id);
                            if (!exists) {
                                Attribute.push(e);
                            }
                        });
                    }
                }
                if (Attribute && Attribute.length > 0) {
                    Attribute.sort((a, b) => a.id - b.id); // Sort the array based on id
                    Attribute.forEach((attr) => {
                        attr.productVariantValueDetails = [];
                        if (productDetails && productDetails.productVariantCombinations && productDetails.productVariantCombinations.length > 0) {
                            if (productDetails.productVariantCombinations && productDetails.productVariantCombinations.length > 0) {
                                productDetails.productVariantCombinations.forEach((rs) => {
                                    if (rs.productVariantDetails && rs.productVariantDetails.length > 0) {
                                        rs.productVariantDetails.forEach((ls) => {
                                            if (attr.attribute.id === ls.attribute.id) {
                                                let find = attr.productVariantValueDetails.find(x => x.attributeValue.id === ls.attributeValue.id);
                                                if (!find) {
                                                    ls.isDefaultVariant = true;
                                                    attr.productVariantValueDetails.push(ls);
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                }
            })
        }
    }


    useEffect(() => {

        if (Attribute && Attribute.length > 0) {
            Attribute.forEach((rs) => {
                if (tempAttr && tempAttr.length == 0) {
                    obj = {};
                    obj.attribute = rs.attribute;
                    obj.ProductVariantValue = rs.attributeValue;
                    //setTempArray([...tempArray,obj]);
                    tempAttr.push(obj);
                } else {
                    obj = {};
                    obj.attribute = rs.attribute;
                    obj.ProductVariantValue = rs.attributeValue;
                    tempAttr.push(obj);
                    //setTempArray([...tempArray,obj]);
                }
            })
        }
        setTempArray(tempAttr);
    }, [Attribute && Attribute.length > 0])

    const onChangeAttr = (event, attr) => {
        let currentCombination = tempArray;
        let selectedValue = attr.productVariantValueDetails.find(e => e.attributeValue.id == event.target.value);
        if (tempArray && tempArray.length > 0) {


            //let find = tempAttr.find(x => x.attribute.id == attr.attribute.id);
            let find = tempArray.find(x => x.attribute.id == attr.attribute.id);
            if (find) {
                let attrfind2 = attr.productVariantValueDetails.find(e => e.attributeValue.id == event.target.value);
                if (attrfind2) {
                    tempArray.forEach((rs) => {
                        if (rs.attribute.id == attrfind2.attributeValue.attribute.id) {
                            rs.ProductVariantValue = attrfind2.attributeValue;
                        }
                    })
                    currentCombination = tempArray;
                }
            } else {
                obj = {};
                obj.attribute = attr.attribute;
                let attrfind1 = attr.productVariantValueDetails.find(e => e.attributeValue.id == event.target.value);

                if (attrfind1) {
                    //obj.ProductVariantValue = attrfind1;//OLD
                    obj.ProductVariantValue = attrfind1.attributeValue;
                }
                // tempAttr.push(obj);
                currentCombination.push(obj);
                setTempArray([...tempArray, obj]);
            }
        }


        if (productDetails && productDetails.productVariantCombinations.length > 0) {
            let foundVariantId = null;
            for (let i = 0; i < productDetails.productVariantCombinations.length; i++) {
                foundVariantId = productDetails.productVariantCombinations[i].id;
                for (let j = 0; j < productDetails.productVariantCombinations[i].productVariantDetails.length; j++) {

                    let find = currentCombination && currentCombination.find((d) => d.attribute.id == productDetails.productVariantCombinations[i].productVariantDetails[j].attribute.id && d.ProductVariantValue.id == productDetails.productVariantCombinations[i].productVariantDetails[j].attributeValue.id);
                    if (!find) {
                        foundVariantId = null;
                    }
                }
                if (foundVariantId) {
                    break;
                }
            }
            if (foundVariantId != null) {
                setVariantMatch(false);
                var selectedVariat = productDetails.productVariantCombinations.find((res) => res.id == foundVariantId);
                tempProductDetails.productVariantCombinations.id = selectedVariat.id;
                tempProductDetails.productVariantCombinations.price = selectedVariat.price;
                tempProductDetails.productVariantCombinations.weight = selectedVariat.weight;
                setPrice(selectedVariat.price);
                let obj = { attribute: '', attributeValue: '' }
                tempProductDetails.productVariantCombinations.productVariantDetails = [];
                if (selectedVariat.productVariantDetails && selectedVariat.productVariantDetails.length > 0) {
                    selectedVariat.productVariantDetails.forEach((e) => {
                        obj = {};
                        obj.attribute = e.attribute.name;
                        obj.attributeValue = e.attributeValue.name;
                        tempProductDetails.productVariantCombinations.productVariantDetails.push(obj);
                    })
                }
            } else {
                setVariantMatch(true);
            }

        }

        if (localStorage.getItem("cartItems")) {
            setCartItem(JSON.parse(localStorage.getItem("cartItems")));
        }
    }


    const onMinus = () => {
        setQuantity(Quantity - 1);
    }

    useEffect(() => {
        setQuantity(1);
    }, [Quantity == 0])

    const onPlus = () => {
        setQuantity(Quantity + 1);
    }

    const AddtoCart = () => {
        let currentCombination = tempArray;
        // if (localStorage.getItem('authUser')) {
        //     authUser = JSON.parse(localStorage.getItem('authUser'));
        //     tempProductDetails.customer.id = authUser.referenceId;
        // }
        if (auth) {
            tempProductDetails.customer.id = auth.referenceId;
            setLoading(1);
            props.AddtoCart(tempProductDetails).then((res) => {
                if(res?.success && res?.data){
                    setTimeout(() => {
                        navigate('/cart');
                    }, 100); 
                }
                setLoading(0);
            })
            if (cartItem && cartItem.length === 0) {
                cartItem.push(tempProductDetails);
                setCartCount(cartCount + 1);
                const subtotal = calculateSubTotal(cartItem);
                setSubTotal(subtotal);
            } else {
                let findItem = cartItem.find((d) => d.product.id === productDetails.id);
                if (findItem) {
                    if (productDetails.id === findItem.product.id) {
                        if (productDetails && productDetails.productVariantCombinations.length > 0) {
                            let foundVariantId = null;
                            for (let i = 0; i < productDetails.productVariantCombinations.length; i++) {
                                foundVariantId = productDetails.productVariantCombinations[i].id;
                                for (let j = 0; j < productDetails.productVariantCombinations[i].productVariantDetails.length; j++) {

                                    let find = currentCombination && currentCombination.find((d) => d.attribute.id == productDetails.productVariantCombinations[i].productVariantDetails[j].attribute.id && d.ProductVariantValue.id == productDetails.productVariantCombinations[i].productVariantDetails[j].attributeValue.id);
                                    if (!find) {
                                        foundVariantId = null;
                                    }
                                }

                                if (foundVariantId) {
                                    break;
                                }
                            }
                            if (foundVariantId != null) {
                                var selectedVariat = productDetails.productVariantCombinations.find((res) => res.id == foundVariantId);
                                cartItem.forEach((e) => {

                                    if (e.productVariantCombinations.id === selectedVariat.id) {
                                        e.quantity = e.quantity + Quantity;
                                    }
                                })
                                let findcartitem = cartItem.find((f) => f.productVariantCombinations.id === selectedVariat.id)
                                if (!findcartitem) {
                                    cartItem.push(tempProductDetails);
                                    setCartCount(cartCount + 1);
                                    const subtotal = calculateSubTotal(cartItem);
                                    setSubTotal(subtotal);
                                }
                            }

                        }
                        const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                        localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                        navigate('/cart');
                    } else {
                        cartItem.push(tempProductDetails);
                        setCartCount(cartCount + 1);
                        const subtotal = calculateSubTotal(cartItem);
                        setSubTotal(subtotal);
                        const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                        localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                        navigate('/cart');
                    }
                } else {
                    cartItem.push(tempProductDetails);
                    setCartCount(cartCount + 1);
                    const subtotal = calculateSubTotal(cartItem);
                    setSubTotal(subtotal);
                    const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                    localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                    navigate('/cart');
                }
            }
        } else {
            if (cartItem && cartItem.length === 0) {
                cartItem.push(tempProductDetails);
                setCartCount(cartCount + 1);
                const subtotal = calculateSubTotal(cartItem);
                setSubTotal(subtotal);
                const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                navigate('/cart');
            } else {
                let findItem = cartItem.find((d) => d.product.id === productDetails.id);
                if (findItem) {
                    if (productDetails.id === findItem.product.id) {
                        if (productDetails && productDetails.productVariantCombinations.length > 0) {
                            let foundVariantId = null;
                            for (let i = 0; i < productDetails.productVariantCombinations.length; i++) {
                                foundVariantId = productDetails.productVariantCombinations[i].id;
                                for (let j = 0; j < productDetails.productVariantCombinations[i].productVariantDetails.length; j++) {

                                    let find = currentCombination && currentCombination.find((d) => d.attribute.id == productDetails.productVariantCombinations[i].productVariantDetails[j].attribute.id && d.ProductVariantValue.id == productDetails.productVariantCombinations[i].productVariantDetails[j].attributeValue.id);
                                    if (!find) {
                                        foundVariantId = null;
                                    }
                                }
                                if (foundVariantId) {
                                    break;
                                }
                            }

                            if (foundVariantId != null) {
                                var selectedVariat = productDetails.productVariantCombinations.find((res) => res.id == foundVariantId);
                                cartItem.forEach((e) => {

                                    if (e.productVariantCombinations.id === selectedVariat.id) {
                                        e.quantity = e.quantity + Quantity;
                                    }
                                })
                                let findcartitem = cartItem.find((f) => f.productVariantCombinations.id === selectedVariat.id)
                                if (!findcartitem) {
                                    cartItem.push(tempProductDetails)
                                    setCartCount(cartCount + 1);
                                    const subtotal = calculateSubTotal(cartItem);
                                    setSubTotal(subtotal);
                                }
                            }
                        }
                        const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                        localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                        navigate('/cart');
                    } else {
                        cartItem.push(tempProductDetails);
                        setCartCount(cartCount + 1);
                        const subtotal = calculateSubTotal(cartItem);
                        setSubTotal(subtotal);
                        const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                        localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                        navigate('/cart');
                    }
                } else {
                    cartItem.push(tempProductDetails);
                    setCartCount(cartCount + 1);
                    const subtotal = calculateSubTotal(cartItem);
                    setSubTotal(subtotal);
                    const updatedCartItemsWithDetails = setVariantDetails(cartItem, 'cart');
                    localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails));
                    navigate('/cart');
                }
            }
        }
    }

    const setVariantDetails = (list, key) => {
        return list.map(item => {
            const variantsDetails = item.productVariantCombinations.productVariantDetails
                .filter(detail => detail.attribute && detail.attributeValue)
                .map(detail => `${detail.attribute}=${detail.attributeValue}`).join(',');
            return {
                ...item,
                variantsDetails: variantsDetails,
                isSaveOrLater: key === 'saveForLater'
            };
        });
      };
      

    useEffect(() => {
        tempProductDetails.quantity = Quantity;
    }, [Quantity])



    useEffect(() => {

        window.scrollTo(0, 0);


        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    const activeImageStyle = {
        border: '2px solid #007bff',
    };




    //set active class on review tabs change start
    const [activeTab, setActiveTab] = useState('product-tab-description');
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    //set active class on review tabs change end


    // Function to set review form details on load start
    const getProductRatingDetailsByCustomerIdAndProductId = async () => {
    if (auth && auth.referenceId) {
        setLoading(1);
        const response = await getData(`productRating/productRatingByCustomerIdAndProductId/${auth.referenceId}/${params.id}`);
        if (response.success && response.data) {
            setFormData(response.data);
            setLoading(0);
        } else {
            setLoading(0);
        }
        }
    }
    // Function to set review form details on load end

    // Function to set Product is delivered or not on load start
    const getIsProductDeliveredOrNotByCustomerIdAndProductId = async () => {
    if(auth && auth.referenceId){
        setLoading(1);
        const response = await getData(`product/productIsDelivered/${auth.referenceId}/${params.id}`);
        if (response.success && response.data) {
            setIsProductDelivered(response.data);
            setLoading(0);
        } else {
            setLoading(0);
        }
        }
    }
    //  Function to set Product is delivered or not on load end

    // Function to set Product is delivered or not on load start
    const getProductReviewByProductId = async () => {
        reportGridFilter.filters[0].filterColumn = "productId"
        reportGridFilter.filters[0].idList.push(params.id);
        setLoading(1);
        const response = await filterData(`productRating/filters`, reportGridFilter);
        if (response.success && response?.data && response?.data?.content) {
            const tempCustomerRatings = setParseDateString(response.data);
            setCustomerRatingsBasedOnCurrentProduct(tempCustomerRatings);
            setLoading(0);
        } else {
            setLoading(0);
        }
    }
    //  Function to set Product is delivered or not on load end

    //  Function to convert created Date in readable format start
    const setParseDateString = (data) => {
        data.content = data.content && data.content.map(res => {
            res.createdDate = format(
                parse(res.createdDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", new Date()),
                "MMMM dd, yyyy"
            );
            return res;
        });
        return data;
    };
    //  Function to convert created Date in readable format end


    // Function to toggle the visibility of the form
    const toggleForm = () => {
        setShowForm(!showForm);
    }



    const handleCheckboxChange = async (res) => {
        if(auth && auth.referenceId){
            setLoading(1)
            let obj = {
                quantity: 1,
                isActive: true,
                isDeleted: false,
                isWishList: !productDetails.isWishList,
                customer: { id: auth.referenceId },
                product: { id: res?.id },
                productVariantCombinations: { id: res?.productVariantCombinations[0].id }
            };
            const response = await saveWishList('cart/wishListProduct/save', obj);
           if (response.success && response.data) {
                productDetails.isWishList = !productDetails.isWishList;
                setLoading(0)
                } else {
                productDetails.isWishList = !productDetails.isWishList;
                setLoading(0)
            }
            setLoading(1)
            const wishListResponse = await fetchData('cart/getWishListByCustomer/' + auth.referenceId);
            if (wishListResponse.success && wishListResponse.data) {
                setWishListCount(wishListResponse?.data?.length);
                setLoading(0)
            }else{
                setLoading(0)
            }
        }else{
            handleLoginModalShow();
            //navigate('/login');
        }
       
    };


    return (
        <div>
            <div className="page-wrapper">
                <main className="main mt-6 single-product">
                    <div className="page-content mb-10 pb-6 ">
                        <div className="container">
                            <div className="product product-single row mb-7" >
                                <div className="col-md-6 sticky-sidebar-wrapper" >
                                    <div className='row'>
                                        <div className='col-10'>
                                    <div className="product-gallery pg-vertical sticky-sidebar" data-sticky-options="{'minWidth': 767}" style={{ zIndex: 1, }}>
                                        <div className="product-single-carousel  owl-theme owl-nav-inner row cols-1 gutter-no" style={{ zIndex: 1, }}>

                                            <figure className="product-images">
                                                {productImage ? (
                                                    <ReactImageMagnify
                                                        disableZoom={true}
                                                        smallImage={{
                                                            alt: "Wristwatch by Ted Baker London",
                                                            isFluidWidth: false,
                                                            src: productImage,
                                                            width: 325,
                                                            height: 400,
                                                            onError: (e) => {
                                                                e.target.src = no_image; // Set the default image source if the image fails to load
                                                            }
                                                        }}
                                                        largeImage={{
                                                            src: productImage,
                                                            width: 800,
                                                            height: 1200,
                                                        }}
                                                        enlargedImageContainerStyle={{
                                                            backgroundColor: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={no_image}
                                                        alt="No Image"
                                                        width={325}
                                                        height={400}
                                                        onError={(e) => {
                                                            e.target.src = no_image; // Set the default image source if the image fails to load
                                                        }}
                                                    />
                                                )}
                                            </figure>
                                        </div>
                                        <div className="product-thumbs-wrap">
                                            <div className="product-thumbs" style={{ overflowY: 'auto' }}>
                                                {productImageGallery.map((imageUrl, index) => (
                                                    <div className="product-thumb" key={index} onMouseEnter={() => {
                                                        setProductImage(imageUrl);
                                                    }}
                                                        onMouseLeave={() => {

                                                            setProductImage(productImage);
                                                        }}
                                                    >
                                                        <img src={imageUrl} onError={(e) => {
                                                            e.target.src = no_image; // Set the default image source if the image fails to load
                                                        }} alt="product thumbnail" width={109} height={122} style={productImage === imageUrl ? activeImageStyle : {}} />
                                                    </div>
                                                ))}
                                            </div>


                                            <button className="thumb-up disabled"><i className="fas fa-chevron-left" /></button>
                                            <button className="thumb-down disabled"><i className="fas fa-chevron-right" /></button>
                                        </div>

                                            </div>
                                        </div>
                                        <div className='col-2'>
                                        <div className="product-action-vertical product-action-vertical-custom">
                                                {/* <a className="btn-product-icon btn-wishlist" style={{ padding: '10px 0px', cursor: 'pointer' }} title="Add to wishlist" onClick={(event) => handleCheckboxChange(props?.product)}>
                                                    <div className="container">
                                                        <label className="heart-checkbox mb-0">
                                                            <input
                                                                type="checkbox" style={{ cursor: 'pointer' }}
                                                                checked={productDetails?.isWishList ? checked : false}
                                                                onChange={(event) => handleCheckboxChange(productDetails)}
                                                            />
                                                            <FontAwesomeIcon style={{ cursor: 'pointer' }}
                                                                icon={faHeart}
                                                                color={productDetails?.isWishList ? 'red' : '#ccc'}
                                                            />

                                                        </label>
                                                    </div>
                                                </a> */}
                                                <div onClick={(event) => handleCheckboxChange(productDetails)} className="btn-product-icon btn-wishlist" style={{ padding: '10px 0px', cursor: 'pointer' }} title="Add to wishlist">
                                                    {/* <i className="d-icon-heart" /> */}
                                                    <div className="container">
                                                        <label className="heart-checkbox mb-0">
                                                            <FontAwesomeIcon
                                                                style={{ cursor: 'pointer' }}
                                                                icon={faHeart}
                                                                color={productDetails?.isWishList ? 'red' : '#ccc'}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="product-details" >
                                    <h1 className="product-name" style={{ textAlign: 'left', }}>{productDetails.name}</h1>
                                        <div className="product-meta" style={{ textAlign: 'left' }}>
                                            Code: <span className="product-sku">{productDetails.code}</span>
                                            Series: <span className="product-brand">{productDetails.series}</span>
                                        </div>
                                        <div className="product-price" style={{ textAlign: 'left' }}>&#8377;{price}</div>
                                        {productRatingAverage?.averageRating !== null && (
                                        <div className="ratings-container m-0">
                                            <Badge bg="success ml-0 m-0">
                                                <span className='pt-1'>{productRatingAverage?.averageRating}</span><FaStar />
                                            </Badge>
                                            <p className="rating-reviews">&nbsp;({productRatingAverage?.totalRatings} Ratings & {productRatingAverage?.numberOfReviews} reviews )</p>
                                        </div>
                                        )}
                                        <p style={{ textAlign: 'left', overflowWrap: 'break-word' }} className="product-short-desc">{productDetails.smallDescription}</p><br></br>
                                        <div className='row'>
                                        {
                                            Attribute && Attribute.length > 0
                                                ?
                                                Attribute.map((res, i) =>
                                                    <div key={i} className=" col-6 product-form product-variations product-color shipping-address" style={{ padding: '0px' }}>
                                                        <label style={{ position: 'relative' }} className='productColors'>{res.attribute.name}:</label>
                                                        <div className="select-boxs">
                                                            <select name="color" onChange={(e) => onChangeAttr(e, res)}>
                                                                {res.productVariantValueDetails && res.productVariantValueDetails.length > 0
                                                                    ? res.productVariantValueDetails.map((ls) =>
                                                                        <option key={ls.attributeValue.id} value={ls.attributeValue.id} selected={res.attributeValue.id == ls.attributeValue.id}>{ls.attributeValue.name}</option>
                                                                    )
                                                                    : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                )
                                                : null
                                        }
                                        </div>
                                        <div className="product-variation-price">
                                            <span>$239.00</span>
                                        </div>
                                        {
                                            variantMatch
                                                ?
                                                <p style={{ textAlign: 'left', overflowWrap: 'break-word', color: 'red' }} className="product-short-desc">Combination not found, Please try different combination.</p>
                                                : null
                                        }

                                        <hr className="product-divider" />
                                        <div className="product-form product-qty">
                                            <div className="product-form-group">
                                                <div className="input-group">
                                                    <button className="quantity-minus d-icon-minus" onClick={onMinus} />
                                                    {/* <input className="quantity form-control" type="number" min={1} max={1000000} value={Quantity} /> */}
                                                    <input
                                                        className="quantity form-control"
                                                        type="number"
                                                        min={1}
                                                        max={1000000}
                                                        value={Quantity}
                                                        onChange={(e) => setQuantity(e.target.value)}
                                                    />
                                                    <button className="quantity-plus d-icon-plus" onClick={onPlus} />
                                                </div>
                                                <button className="btn-product btn-cart text-normal ls-normal font-weight-semi-bold" onClick={AddtoCart} disabled={variantMatch ? true : false}><i className="d-icon-bag" />Add to Cart</button>

                                            </div>
                                        </div>
                                        <hr className="product-divider mb-3" />
                                    </div>
                                </div>
                            </div>


                            <div className="tab tab-nav-simple product-tabs">
                                <ul className="nav nav-tabs justify-content-center" role="tablist">
                                    <li className="nav-item">
                                        <a className={activeTab === 'product-tab-description' ? 'nav-link active' : 'nav-link'} onClick={() => handleTabClick('product-tab-description')}>Description</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={activeTab === 'product-tab-reviews' ? 'nav-link active' : 'nav-link'} onClick={() => handleTabClick('product-tab-reviews')}>Rating & Reviews</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className={activeTab === 'product-tab-description' ? 'tab-pane active in' : 'tab-pane'} id="product-tab-description">
                                        <div className='row' style={{ justifyContent: 'center' }}>
                                            <div className={`col-8 comments pt-2 pb-10 border-no justify-center`}>
                                                <div className='col-12'>
                                                    <div className="toolbox-item m-0">
                                                        <p className="m-0">Description</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <p className='ml-2 text-justify'> <BiChevronRight size={20} style={{ color: '#ccc' }} />{productDetails?.longDescription}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={activeTab === 'product-tab-reviews' ? 'tab-pane active in' : 'tab-pane'} id="product-tab-reviews">
                                        <div className="row " style={{ justifyContent: 'center' }}>
                                            <div className={`col-8 comments pt-2 pb-10 border-no justify-center`}>
                                                {showForm && (
                                                    <div className="col-12 comments pt-2 pb-10 border-no" >
                                                        <ReviewAddUpdate data={props.data} formData={formData} onToggleForm={toggleForm} getProductRatingDetailsByCustomerIdAndProductId={getProductRatingDetailsByCustomerIdAndProductId} getProductReviewByProductId={getProductReviewByProductId} />
                                                    </div>
                                                )}
                                                {!showForm && (<div>
                                                    <ReviewList customerRatingBasedOnCurrentProduct={customerRatingBasedOnCurrentProduct} data={props.data} onToggleForm={toggleForm} isRateProductBtn={true} />
                                                    {
                                                        customerRatingBasedOnCurrentProduct?.totalElements > customerRatingBasedOnCurrentProduct?.numberOfElements ?
                                                            <nav className="toolbox toolbox-pagination " style={{ justifyContent: 'flex-start' }}>
                                                                <div className="feeling ">
                                                                    <a onClick={() => { navigate('/reviews/' + productDetails.id) }} className="ml-2 submit-review-toggle" style={{ color: 'var(--primary)', fontSize: 'medium', fontWeight: '500', cursor: 'pointer' }}>All Reviews</a>
                                                                </div>
                                                            </nav>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                <Modal show={showLoginModal} size="md" animation={false} onHide={handleLoginModalClose}>
                <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}><span>Login</span>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleLoginModalClose}></button>
                </Modal.Header>
                <LoginContainer  onLoginSuccess={handleLoginSuccess}/>
            </Modal>
            </div >
        </div >
    )
}


