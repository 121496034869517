import React, { useState , useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,faEyeSlash} from '@fortawesome/free-regular-svg-icons'
import { environment } from "../environment";
import { Context } from "../Component/LoadingContext";
export default function NewPassword(props) {
   
    const [email, setEmail] = useState(props.verifiedEmail);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPwd, setConfirmNewPwd] = useState('');
    const [oldPasswordToggle, setOldPasswordToggle] = useState(true);
    const [newPasswordToggle, setnewPasswordToggle] = useState(true);
    const [isSamePwd, setisSamePwd] = useState(true);
    const [error, setError] = useState(false);
    const [loading,setLoading ] = useContext(Context);

    const oldPasswordIconToggle = async() =>{
        setOldPasswordToggle(!oldPasswordToggle);
        // this.oldPasswordToggle = !this.oldPasswordToggle
      
    }

    const newPasswordIconToggle = async() =>{
        setnewPasswordToggle(!newPasswordToggle);
        // this.oldPasswordToggle = !this.oldPasswordToggle
     
    }

    const handleSubmit = async(e) =>{
        e.preventDefault();
        if (!newPassword || !confirmNewPwd) {
            setError(true);
            return false;
        } else {

            if(newPassword != confirmNewPwd){
                toast.error("New password and Confirm password must be same", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
    
                });
                return;
            }
            const formdata = new FormData();
            formdata.append('email',email);
            formdata.append('newPassword', newPassword);
            setLoading(1);
            try {
            let result = await fetch(environment.SERVER_URL +'/customer/forgotPassword', {
                method: 'post',
                body: formdata,
               
            });
            result = await result.json();
    
            if (result.success) {
                setLoading(0);
                toast.success("Password has been reset successfully.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                props.onNewPwd();
                
            } else {
                setLoading(0);
                toast.error(result.responseMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
    
                });
                } 
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(0);
                return error; // Rethrow the error to handle it in the calling code
            }
        }
    }
    return (
        <div>
            <Modal.Body style={{ fontWeight: 'bold', fontSize: '12px' }}>

                <form action="#">
                    <div className="form-group">
                        <label className="form-label">New Password</label>
                        {
                            oldPasswordToggle ?
                            <><FontAwesomeIcon icon={faEye} style={{fontSize:20,paddingTop:'40px',paddingLeft:'330px',position:'absolute',cursor:'pointer'}} onClick={oldPasswordIconToggle}/></>
                            :
                            <><FontAwesomeIcon icon={faEyeSlash} style={{fontSize:20,paddingTop:'40px',paddingLeft:'330px',position:'absolute',cursor:'pointer'}} onClick={oldPasswordIconToggle}/></>
                        }
                       
                        
                        <input type={oldPasswordToggle ? 'password' : 'text'} value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                            className="email-form-control" id="login-email" name="login-email" placeholder="Enter New Password" required />
                         
                        {error && !newPassword && <><label className="error-label">New Password is required</label><br></br><br></br></>}
                       
                    </div>

                    <div className="form-group mt-3">
                        <label className="form-label">Confirm Password</label>
                        {
                            newPasswordToggle ?
                            <FontAwesomeIcon icon={faEye} style={{fontSize:20,paddingTop:'40px',paddingLeft:'305px',position:'absolute'}} onClick={newPasswordIconToggle}/>
                            :
                            <FontAwesomeIcon icon={faEyeSlash} style={{fontSize:20,paddingTop:'40px',paddingLeft:'305px',position:'absolute'}} onClick={newPasswordIconToggle}/>
                        }
                        <input type={newPasswordToggle ? 'password' : 'text'} value={confirmNewPwd} onChange={(e) => setConfirmNewPwd(e.target.value)}
                            className="email-form-control" id="login-email" name="login-email" placeholder="Enter Confirm Password" required />
                        {error && !confirmNewPwd && <><label className="error-label">Confirm Password is required.</label><br></br><br></br></>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-dark btn-block btn-rounded bg-primary text-white w-50 border border-white rounded" type="submit"
                    onClick={handleSubmit} style={{
                        height: '40px',
                        fontSize: '13px',
                        marginRight: '390px'
                    }}>RESET</button>
            </Modal.Footer>
        </div>
    )
}
