import React, { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import no_image from "../image/no_image.png";
import { useNavigate } from 'react-router-dom';
import { environment } from '../environment';
import { WishListCountContext, AuthUserContext } from '../Component/CartContext';
import { save } from "../Component/SupplierServices";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Context } from "../Component/LoadingContext";

const BootstrapTooltip = styled((props) => (
  <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip} .MuiTooltip-tooltip`]: {
    fontSize: '16px', // Adjust the font size as needed
  },
}));

export default function WishList(props) {
    const [wishListProducts, setWishListProducts] = useState([]);
    const { wishListCount, setWishListCount } = useContext(WishListCountContext);
    const [productImage, SetProductImage] = useState('');
    let auth = null;
    const navigate = useNavigate();
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const [loading, setLoading] = useContext(Context);


    if (localStorage.getItem('authUser')) {
        auth = JSON.parse(localStorage.getItem('authUser'));
    }
    // useEffect(() => {
    //     setAuthUser(getAuthUser);
    // }, []);
    
    // const getAuthUser = () => {
    //     if (localStorage.getItem('authUser')) {
    //        return JSON.parse(localStorage.getItem('authUser'));
    //     }
    //     return null;
    // }

    useEffect(() => {
            getWishListOfCustomer();
    }, [authUser])

    const getWishListOfCustomer = async () => {
        if (auth && auth?.referenceId) {
            setLoading(true);
            props.GetWishListByCustomerId(auth?.referenceId).then((res) => {
                if (res.success && res.data) {
                    setWishListProducts(setVariantDetails(res.data));
                    setWishListCount(res?.data?.length);
                    setLoading(false);
                    return
                } else {
                    setWishListProducts([]);
                    setWishListCount(0);
                    setLoading(false);
                    return
                }
            })
        } else {
            setWishListProducts([]);
            setWishListCount(0);
            return
        }
    }

    const setVariantDetails = (list) => {
        const updatedList = list.map(item => {
            const variantsDetails = item.productVariantCombinations.productVariantDetails
                .filter(detail => detail.attributeValue.name) // Exclude details with null attribute values
                .map(detail => `${detail.attribute.name || '-'}=${detail.attributeValue.name}`).join(',');
                // Include attribute names if available, otherwise use '-'
            return {
                ...item,
                variantsDetails: variantsDetails,
            };
        });
        return updatedList;
    }

    useEffect(() => {
        if (wishListProducts && wishListProducts.length > 0) {
            const fetchProductImages = async () => {
                const imagePromises = wishListProducts.map(async (item) => {
                    try {
                    const response = await fetch(
                        `${environment.SERVER_URL}/fileDownload/getProductImage/${item.product.id}`
                    );
                    const blob = await response.blob();
                    if (blob.type !== "application/json") {
                        const imageUrl = URL.createObjectURL(blob);
                        return imageUrl;
                        }
                    } catch (error) {
                        console.error("Error fetching user data:", error);
                        return no_image; // Rethrow the error to handle it in the calling code
                    }
                    return null;
                });

                const productImages = await Promise.all(imagePromises);
                SetProductImage(productImages);
            };

            fetchProductImages();
        }
    }, [wishListProducts]);


    const removeItem = async (item, index) => {
        if (item) {
            let obj = {
                id: item.id
            };
            setLoading(true);
            try {
            let result = await fetch(environment.SERVER_URL + '/cart/delete', {
                method: 'post',
                body: JSON.stringify(obj),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            result = await result.json();
            setLoading(false);
            getWishListOfCustomer();
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(false);
                getWishListOfCustomer();
                return error; // Rethrow the error to handle it in the calling code
            }
        }
    }


    const redirectToCart = async () => {
        navigate("/cart");
    }


    const addToCart = async (data) => {
        let obj = {
            quantity: data.quantity,
            isSaveOrLater: false,
            isActive: "true",
            isDeleted: "false",
            customer: { id: data.customer.id },
            product: { id: data.product.id },
            productVariantCombinations: { id: data.productVariantCombinations.id }
        };
        const response = await save('cart/save', obj);
        if (response.success && response.data) {
            navigate('/cart');
        }
    }

    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className="col-12 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <table className="shop-table cart-table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th className='col-3'>Product</th>
                                    <th className='col-3'>Product Name</th>
                                    <th className='col-3'>Price</th>
                                    {/* <th className='col-2'>Add Cart</th> */}
                                    <th className='col-1'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wishListProducts && wishListProducts.length > 0 ? (
                                   wishListProducts.map((item, i) => (
                                        <tr key={item.id} style={{ textAlign: 'center' }}>
                                                <td className="product-thumbnail" style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <figure>
                                                        <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item?.productVariantCombinations?.category?.id, combinationId: item?.productVariantCombinations?.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                                            <img src={productImage[i] || no_image} width={100} height={100} alt="product"
                                                                onError={(e) => {
                                                                    e.target.src = no_image; // Set the default image source if the image fails to load
                                                                }} />
                                                        </a>
                                                    </figure>
                                                </td>
                                                <td className="product-name">
                                                <BootstrapTooltip title={item.variantsDetails}>
                                                    <div className="product-name-section">
                                                        <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item?.productVariantCombinations?.category?.id, combinationId: item?.productVariantCombinations?.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                                            {item.product.name}
                                                        </a>
                                                        <p>{item?.variantsDetails ? (item.variantsDetails.length > 15 ? item.variantsDetails.slice(0, 15) + '...' : item.variantsDetails) : null}</p>
                                                    </div>
                                                    </BootstrapTooltip>
                                                </td>
                                                <td className="product-subtotal">
                                                    <span className="amount">₹{item.productVariantCombinations.price ? item.productVariantCombinations.price.toFixed(2) : '0'}</span>
                                                </td>
                                                {/* <td>
                                                    <div className="product-form product-qty">
                                                        <div className="product-form-group">

                                                            <button className="btn-product btn-cart text-normal ls-normal font-weight-semi-bold" onClick={() => addToCart(item)} ><i className="d-icon-bag" />Add to Cart</button>

                                                        </div>
                                                    </div>
                                                </td> */}
                                                <td className="product-close">
                                                    <button className="quantity-plus " style={{ border: '0px', backgroundColor: 'white' }} onClick={() => removeItem(item, i)} >
                                                        <FontAwesomeIcon className='cross-button' icon={faCircleXmark} style={{ fontSize: 20 }} />
                                                    </button>
                                                </td>
                                            </tr>
                                    ))
                                ) : (
                                    <tr>
                                     <td className="product-name" colSpan={4}>
                                    <div className="product-name-section text-center">
                                        Data Not Available
                                    </div>
                                </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="cart-actions mb-6 pt-4">
                            <a className="btn btn-dark btn-md btn-rounded btn-icon-left mr-4 mb-4" style={{ color: '#fff', fontWeight: '700' }} onClick={redirectToCart}>Go To Cart</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


