import React, { useState, useEffect } from 'react';
import AsyncSelect from "react-select/async";
import { useSelector } from 'react-redux';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { environment } from '../../environment';
export default function AddUpdateDeliveryAddress(props) {
  let auth = null;
  let user = null;
  if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
  }

  const [firsName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [address, setaddress] = useState('');
  const [landmark, setlandmark] = useState('');
  const [city, setcity] = useState('');
  const [pincode, setpincode] = useState('');
  const [mobileNo, setmobileNo] = useState('');
  const [state, setstate] = useState(null);
  const [country, setcountry] = useState(null);
  const [customer, setcustomer] = useState({ id: auth.referenceId });
  const [isPrimary, setisPrimary] = useState(false);
  const [id, setid] = useState('');
  const [error, setError] = React.useState(false);
  const [mobileerror, setMobileError] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [pincodeerror, setPincoeError] = React.useState(false);

  useEffect(() => {

    if (props.id != null) {
      getById(props.id);
      return;
    } else {

      let user = null;

      if (localStorage.getItem('authUser')) {
        user = JSON.parse(localStorage.getItem('authUser'));
        if (user && user.isCustomer) {
          if (usedata && usedata.shippingAddresses && usedata.shippingAddresses.length == 0) {
            if (usedata.name.search(" ") != -1) {
              var names = usedata.name.split(" ");
              setfirstName(names[0]);
              setlastName(names[1]);

            } else {
              setfirstName(usedata.name);
            }
            setmobileNo(usedata.mobileNo);
          }

        }

      }


    }
  }, [])
  user = useSelector(state => state.UserDetail)
  let usedata = useSelector(state => state.UserDetail);

  function isValidPincode(pincode) {
    return /^\d{6}$/.test(pincode);
  }

  const getById = async (id) => {
    try {
    let result = await fetch(environment.SERVER_URL + "/shippingAddress/" + id, {
      method: 'get',
      headers: {
        "Authorization": `OEC` + ' ' + auth.token
      },
    });
    result = await result.json();
    setfirstName(result.data.firsName);
    setlastName(result.data.lastName);
    setmobileNo(result.data.mobileNo);
    setcountry(result.data.country);
    handleCountryInputChange(result.data.country)
    if (result.data.state) {
      var optionsTagsDb1 = [];
      let optionobj1 = { label: null, value: null }
      optionobj1.label = result.data.state.name;
      optionobj1.value = result.data.state;
      // optionsTagsDb1.push(optionobj1);
  
      setstate(optionobj1);
    }
    setcity(result.data.city);
    setpincode(result.data.pincode);
    setlandmark(result.data.landmark);
    setaddress(result.data.address);
    setisPrimary(result.data.isPrimary);
    setid(result.data.id);   
    } catch (error) {
        console.error("Error fetching user data:", error);
        return error; // Rethrow the error to handle it in the calling code
    }
  }

  let countryList = [];
  const getCountryList = async () => {

    if ((!countryList || countryList.length === 0)) {
      try {
      let result = await fetch(environment.SERVER_URL + '/country', {
        method: 'get',
        headers: {
          "Authorization": `OEC` + ' ' + auth.token
        },
      });
  
      result = await result.json();
      countryList = result.data;
      } catch (error) {
          console.error("Error fetching user data:", error);
          return error; // Rethrow the error to handle it in the calling code
      }
    }
    return countryList;
  }


  const handleCountryInputChange = (Inputcountry) => {
    setcountry(Inputcountry);
    setstate(null);
    getStateList(Inputcountry);
  }

  let stateList = [];
  const getStateList = async (e) => {
    if ((!stateList || stateList.length === 0)) {
      try {
      let result = await fetch(environment.SERVER_URL + '/state/getStateByCountry/' + e.id, {
        method: 'get',
        headers: {
          "Authorization": `OEC` + ' ' + auth.token
        },
      });
      result = await result.json();
      if (result) {
        var optionsTagsDb = [];
        result.data.forEach((res) => {
          let optionobj = { label: null, value: null }
          optionobj.label = res.name;
          optionobj.value = res;
          optionsTagsDb.push(optionobj);
        })
        setOptions(optionsTagsDb);
  
      }
      } catch (error) {
          console.error("Error fetching user data:", error);
          return error; // Rethrow the error to handle it in the calling code
      }
    }
  }

  const onChangeMobile = (e) => {
    if (!e.target.value) {
      setMobileError(false);
      setError(true);
      return false;
    } else if (e.target.value && e.target.value.length !== 10) {
      setMobileError(true);
    } else {

      setMobileError(false);
    }
  }
  const onChangePincode = (e) => {
    if (!e.target.value) {
      setPincoeError(false);
      setError(true);
      return false;
    } else if (e.target.value && !isValidPincode(e.target.value)) {
      setPincoeError(true);
    } else {

      setPincoeError(false);
    }
  }

  const handleStateInputChange = (InputState) => {
    setstate(InputState);
  }

  const onChangeState = (e) => {
    setstate(e);
  }

  const onCancel = async () => {
    props.cancel();
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!firsName || !lastName || !address || !city || !pincode || !state || !mobileNo || !country) {
      setErrorName(true) || setErrorLastName(true) || setErrorAddress(true) || setErrorPincode(true) || setErrorPhoneNumber(true) || setErrorLandmark(true) || setErrorCity(true) || setError(true)
      return false;

    } else if (!isValidPincode(pincode)) {
      setPincoeError(true);
      return false
    } else {

      if (mobileNo && mobileNo.length !== 10) {
        setMobileError(true);
        // isValidMobile = true;
        return false
      } else if (!isValidPincode(pincode)) {
        setPincoeError(true);
        return false
      } else {

        setError(false);
        setMobileError(false);
        // setstate(state.value);
        if (props.isUpdateComponent) {
          props.UpdateAddressHandler({ firsName, lastName, address, city, pincode, state: state.value, mobileNo, country, isPrimary, customer, landmark, id }, user).then((val) => {
            if (val.success) {
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              props.save(val);

            } else {
              // alert("registered failed due to : "+val.responseMessage)
            }
          })
        } else {
          props.AddAddressHandler({ firsName, lastName, address, city, pincode, state: state.value, mobileNo, country, isPrimary, customer, landmark }, user).then((val) => {
            if (val.success) {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              props.save(val);

            } else {
              // alert("registered failed due to : "+val.responseMessage)
            }
          });
        }
      }
    }
  }


  // change Validations
  const [erroName, setErrorName] = useState(false);
  const handleChangeName = (e) => {
    const inputValue = e.target.value;

    if (inputValue === '' || !/\d/.test(inputValue)) {
      setfirstName(inputValue.trimLeft());
      setErrorName(true);
    } else {
      setErrorName(false);
    }
  }; const [erroLastName, setErrorLastName] = useState(false);

  const handleChangeLastName = (e) => {
    const inputValue = e.target.value;

    if (inputValue === '' || !/\d/.test(inputValue)) {
      setlastName(inputValue.trimLeft());
      setErrorLastName(true);
    } else {
      setErrorLastName(false);
    }
  };
  const [erroPhoneNumber, setErrorPhoneNumber] = useState(false);

  const handleChangePhoneNumber = (e) => {
    const inputValue = e.target.value;
    if (/^\d{10}$/.test(inputValue)) {
      setmobileNo(inputValue);
      setErrorPhoneNumber(false);
      setMobileError(false);
    } else if (inputValue === '') {
      setmobileNo('');
      setErrorPhoneNumber(true);
      setMobileError(false);
    } else {
      setmobileNo(inputValue);
      setErrorPhoneNumber(false);
      setMobileError(true);
    }
  };
  const [errorCity, setErrorCity] = useState(false);
  const handleChangeCity = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || !/\d/.test(inputValue)) {
      setcity(inputValue.trimLeft());
      setErrorCity(true);
    } else {
      setErrorCity(false);
    }

  }; const [errorPincode, setErrorPincode] = useState(false);

  const onChangePincodes = event => {
    const input = event.target.value;
    const result = input.replace(/\D/g, '');

    if (result.length !== 6) {
      setErrorPincode(true);
      setpincode(result);
      setPincoeError(true);
    } else {
      setpincode(result);
      setErrorPincode(false);
      setPincoeError(false);
    }
  };
  const [errorLandmark, setErrorLandmark] = useState(false);
  const handleChangeLandmark = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || !/\d/.test(inputValue)) {
      setErrorLandmark(true);
      setlandmark(inputValue.trimLeft());
    } else {

      setErrorLandmark(false);
    }
  }
  const [errorAddress, setErrorAddress] = useState(false);

  const handleChangeAddress = (e) => {
    const inputValue = e.target.value;

    if (inputValue.trim() === '') {
      setErrorAddress(true);
      setaddress(inputValue.trimLeft());
    } else {
      setaddress(inputValue);
      setErrorAddress(false);
    }
  };
  return (
    <div>
      <div className="container" style={{ fontSize: '14px' }}>
        <div className="row" style={{ margin: '10px 15px 10px 10px' }}>
          <div className="col-lg-12">
            <div className>
              <form>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label htmlFor="firstName">First Name<span className="text-danger">*</span></label>
                      <input className="form-control formfont" type="text" name="firstname" placeholder="Enter First Name" value={firsName} onChange={handleChangeName} autoComplete="off" style={{ fontSize: '14px' }} />
                      {erroName && !firsName && <label className="error-label">First Name is required.</label>}
                    </div>
                  </div>

                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name<span className="text-danger">*</span></label>
                      <input className="form-control" type="text" name="lastname" placeholder="Enter Last Name" value={lastName} onChange={handleChangeLastName} autoComplete="off" style={{ fontSize: '14px' }} />
                      {erroLastName && !lastName && <label className="error-label">Last Name is required.</label>}

                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="phone">Mobile No<span className="text-danger">*</span></label>
                      <input className="form-control" type="number" name="phone" placeholder="Enter Mobile No" value={mobileNo} onChange={handleChangePhoneNumber} autoComplete="off" style={{ fontSize: '14px' }} />
                      {erroPhoneNumber && !mobileNo && <label className="error-label">Mobile No. is required</label>}
                      {mobileerror && <label className="error-label">Mobile No. should be 10 digit</label>}

                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="country">Country <span className="text-danger">*</span></label>
                      <AsyncSelect

                        defaultOptions
                        value={country}
                        getOptionLabel={e => e.name}
                        getOptionValue={(option) => option}
                        onChange={handleCountryInputChange}
                        loadOptions={getCountryList}
                        placeholder="Select Country"
                        isSearchable={true}

                      />
                      {/* <ng-select bindlabel="name" placeholder="--Select--" [items]="countryList" formcontrolname="country" [(ngmodel)]="entity.country" (open)="getCountryList()" (change)="onCountryChange()">
                      </ng-select> */}
                      {error && !country && <label className="error-label">Country is required</label>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="state">State <span className="text-danger">*</span></label>
                      <Select
                        defaultOptions
                        options={options}
                        value={state}
                        onChange={onChangeState}
                        placeholder="Select State"

                      />
                      {error && !state && <label className="error-label">State is required</label>}

                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="city">City <span className="text-danger">*</span></label>
                      <input className="form-control" type="text" name id placeholder="Enter City" value={city} onChange={handleChangeCity} style={{ fontSize: '14px' }} />
                      {errorCity && !city && <label className="error-label">City is required</label>}
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="pincode">Pincode <span className="text-danger">*</span></label>
                      <input className="form-control" type="text" name="phone" placeholder="Enter Pincode" value={pincode} onChange={onChangePincodes} autoComplete="off" style={{ fontSize: '14px' }} />
                      {errorPincode && !pincode && <label className="error-label">Pincode is required</label>}
                      {pincodeerror && pincode && <><label className="error-label">Pincode is invalid</label></>}

                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label htmlFor="landmark">Landmark <span className="text-danger">*</span></label>
                      <input className="form-control" type="text" name="phone" placeholder="Enter Landmark" value={landmark} onChange={handleChangeLandmark} autoComplete="off" style={{ fontSize: '14px' }} />
                      {errorLandmark && !landmark && <label className="error-label">landmark is required</label>}

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label htmlFor="address">Address <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" name="address" placeholder="Enter Address" value={address} onChange={(e) => setaddress(e.target.value)} autoComplete="off" style={{ fontSize: '14px' }} />
                      {errorAddress && !address && <label className="error-label">Address is required</label>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    {
                      props.isUpdateComponent
                        ? <button className="btn btn-primary m-2 btnSaveCancel" style={{ fontSize: '14px', height: '40px', width: '70px' }} onClick={onSubmit}>Update</button>
                        : <button className="btn btn-primary m-2 btnSaveCancel" style={{ fontSize: '14px', height: '40px', width: '70px' }} onClick={onSubmit}>Save</button>
                    }

                    <button className="btn btn-danger btnSaveCancel" style={{ fontSize: '14px', height: '40px', width: '70px' }} onClick={onCancel}>Cancel</button>
                    <ToastContainer></ToastContainer>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
