
import { connect } from 'react-redux';
import Footer from '../Component/Footer';
const mapStatetoProps = state => ({
    data: state
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStatetoProps, mapDispatchToProps)(Footer)