import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";
import { Context } from "./LoadingContext";
import Loader from "./Loader";
export default function ForgotPassword(props) {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [error, setError] = React.useState(false);
    const [emailerror, setemailError] = React.useState(false);
    const [isForCustomer, setisForCustomer] = React.useState(true);
    const [loading, setLoading] = useContext(Context);

    function isValidEmail() {
        return /\S+@\S+\.\S+/.test(email);
    }

    const onChangeEmail = (e) => {
        if (!e.target.value) {
            setError(true);
            return false;
        } else if (!e.target.validity.valid) {
            setemailError(true);
        } else {
            //setError(false);
            setemailError(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setError(true);
            return false;
        } else {

            if (!isValidEmail(email)) {
                setemailError(true);
                return false
            } else {
                setError(null);
                setemailError(false);
                const formdata = new FormData();
                formdata.append('email', email);
                formdata.append('isForCustomer', isForCustomer);
                setLoading(1);
                try {
                let result = await fetch(environment.SERVER_URL + '/customer/sendmail', {
                    method: 'post',
                    body: formdata,
    
                });
                result = await result.json();
    
                if (result.success) {
                    setLoading(0);
                    props.sendMail(email);
                } else {
                    setLoading(0);
                    toast.error(result.responseMessage, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
    
                    });
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    setLoading(0);
                    return error; // Rethrow the error to handle it in the calling code
                }
                console.warn("email", email);
            }

        }
    }
    return (
        <div>
            {/* {loading == 1 ? <Loader></Loader>:''} */}
            <Modal.Body style={{ fontWeight: 'bold', fontSize: '12px' }}>

                <form action="#">
                    <div className="form-group">
                        <input type="email" value={email} onChange={(e) => { setEmail(e.target.value); onChangeEmail(e) }}
                            className="email-form-control" id="login-email" name="login-email" placeholder="Email Address *" required />
                        {error && !email && <><label className="error-label">Email  Address is required</label><br></br><br></br></>}
                        {emailerror && email && <><label className="error-label SetFont">Email Address is invalid</label><br></br><br></br></>}

                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-dark btn-block btn-rounded bg-primary text-white w-50 border border-white rounded" type="submit"
                    onClick={handleSubmit} style={{
                        height: '40px',
                        fontSize: '13px',
                        marginRight: '390px'
                    }}>SEND OTP</button>
            </Modal.Footer>

        </div>
    )
}
