import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

import 'react-toastify/dist/ReactToastify.css';
import { Context } from "../Component/LoadingContext";
import Loader from "./Loader";
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
export default function Register(props) {
    const [loading, setLoading] = useContext(Context);
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [mobileNo, setMobile] = useState("");
    const [password, setPass] = useState("");
    const [error, setError] = React.useState(false);
    const [mobileerror, setMobileError] = React.useState(false);
    const [emailerror, setemailError] = React.useState(false);

    function isValidEmail() {
        return /\S+@\S+\.\S+/.test(email);
    }

    const onChangeMobile = async (e) => {

        if (!e.target.value) {
            setError(true);
            setMobileError(false);
            return false;
        } else if (e.target.value && e.target.value.length !== 10) {
            setMobileError(true);
            //setError(false);
            return false;
        } else {
            //setError(false);
            setMobileError(false);
        }
    }

    const onChangeEmail = async (e) => {

        if (!e.target.value) {
            setError(true);
            return false;
        } else if (!e.target.validity.valid) {
            setemailError(true);
        } else {
            //setError(false);
            setemailError(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !mobileNo || !email || !password) {
            setErrorPhoneNumber(true) || setErrorEmail(true) || (setErrorName(true)) || setPasswordError(true)
            // isValidMobile = true;
            return false;
        } else {

            if (mobileNo && mobileNo.length !== 10) {
                setMobileError(true);
                // isValidMobile = true;
                return false
            }
            else if (!isValidEmail(email)) {
                setemailError(true);
            } else {
                setError(null);
                setMobileError(false);
                setemailError(false);
                setLoading(1);
                props.registerHandler({ name, mobileNo, email, password }).then((val) => {
                    if (val.success) {
                        setLoading(0);
                        setTimeout(() => {
                            navigate("/login")
                        }, 500);



                    } else {
                        setLoading(0);

                    }
                }

                ).catch((e) => {
                    setLoading(0);
                    setError(true);
                });
            }
        }
    }

    const redirectlogin = () => {
        navigate("/login");
    }
    const [erroName, setErrorName] = useState(false);

    const handleChangeName = (e) => {
        const inputValue = e.target.value;

        if (inputValue === '' || !/\d/.test(inputValue)) {
            setName(inputValue.trimLeft());
            setErrorName(true);
        } else {
            setErrorName(false);
        }
    };
    const [erroPhoneNumber, setErrorPhoneNumber] = useState(false);

    const handleChangePhoneNumber = (e) => {
        const inputValue = e.target.value;
        if (/^\d{10}$/.test(inputValue)) {
            setMobile(inputValue);
            setErrorPhoneNumber(false);
            setMobileError(false);
        } else if (inputValue === '') {
            setMobile('');
            setErrorPhoneNumber(true);
            setMobileError(false);
        } else {
            setMobile(inputValue);
            setErrorPhoneNumber(false);
            setMobileError(true);
        }
    };

    const [errorEmail, setErrorEmail] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const handleChangeEmail = (e) => {
        const inputValue = e.target.value;
        if (/^\S+@\S+\.\S+$/.test(inputValue)) {
            setEmail(inputValue);
            setErrorEmail(false);
            setEmailError(false);
        } else if (inputValue === '') {
            setEmail('');
            setErrorEmail(true);
            setEmailError(false);
        } else {
            setEmail(inputValue);
            setErrorEmail(false);
            setEmailError(true);
        }
    };
    const [passwordError, setPasswordError] = useState(false);
    const onChangePassword = (e) => {
        const value = e.target.value;
        setPass(value);
        if (!value) {
            setPasswordError("Password is required");
        } else {
            setPasswordError("");
        }
    };
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div>
            {/* <Loader></Loader>
            {loading == 1 ?  :''} */}
            <div className="login-popup">
                <div className="form-box">
                    <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                        <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active border-no lh-1 ls-normal">Register</a>
                            </li>
                        </ul>
                        <div className="tab-content">

                            <div className="tab-pane active" id="register">
                                <form action="#">
                                    <div className="form-group">
                                        <label className="simple-label" style={{ fontSize: '15px' }}>Name&nbsp;<small style={{ color: 'red' }}>*</small></label>
                                        <input type="text" value={name} onChange={handleChangeName} className="form-control" id="register-name" name="register-name" placeholder="Name" required />
                                        {erroName && !name && <><label className="reg-form">Name is required</label><br></br></>}

                                        <label className="simple-label" style={{ fontSize: '15px' }}>Mobile No.&nbsp;<small style={{ color: 'red' }}>*</small></label>
                                        <input type="number" value={mobileNo} onChange={handleChangePhoneNumber} className="form-control" id="register-mobile" name="register-mobile" placeholder="Mobile No." required />
                                        {erroPhoneNumber && !mobileNo && <><label className="reg-form">Mobile No. is required</label><br></br></>}
                                        {mobileerror && <><label className="reg-form">Mobile No. should be 10 digit</label><br></br></>}

                                        <label className="simple-label" style={{ fontSize: '15px' }}>Email&nbsp;<small style={{ color: 'red' }}>*</small></label>
                                        <input type="email" value={email} onChange={handleChangeEmail} className="form-control" id="register-email" name="register-email" placeholder="Email Address" required />
                                        {errorEmail && !email && <><label className="reg-form">Email Address is required</label><br></br></>}
                                        {emailError && email && <><label className="reg-form">Email is invalid</label><br></br></>}

                                        <label className="simple-label" style={{ fontSize: '15px' }}>Password&nbsp;<small style={{ color: 'red' }}>*</small></label>
                                        <div className="password-input-container">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={onChangePassword}
                                                className="form-control"
                                                id="login-password"
                                                name="login-password"
                                                placeholder="Password"
                                                required
                                            />
                                            <span
                                                className="password-toggle-icons"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>

                                        {passwordError && !password && <><label className="error-label">Password is required</label><br /></>}

                                    </div>
                                    <div>

                                    </div>
                                    <div className="form-group">

                                    </div>
                                    <div className="form-group">

                                    </div>
                                    {/* <div className="form-footer">
                                        <div className="form-checkbox">
                                            <input type="checkbox" className="custom-checkbox" id="register-agree" name="register-agree" required />
                                            <label className="form-control-label" htmlFor="register-agree">I
                                                agree to the
                                                privacy policy</label>
                                        </div>
                                    </div> */}
                                    <button className="btn btn-dark btn-block btn-rounded" type="submit" onClick={handleSubmit}>Register</button>
                                    <ToastContainer></ToastContainer>
                                    <div className="form-footer" style={{ marginTop: '10px', color: 'blue' }}>
                                        <div className="form-checkbox">
                                            {/* <input type="checkbox" className="custom-checkbox" id="register-agree" name="register-agree" required /> */}

                                            <Link className="form-control-label" htmlFor="register-agree" to='/login' >Already have an Account?</Link>
                                        </div>
                                    </div>
                                </form>
                                <div className="form-choice text-center">
                                    <label className="ls-m">or Register With</label>
                                    <div className="social-links">
                                        <a href="#" title="social-link" className="social-link social-google fab fa-google border-no"><FontAwesomeIcon icon={faGoogle} /></a>
                                        <a href="#" title="social-link" className="social-link social-facebook fab fa-facebook-f border-no"><FontAwesomeIcon icon={faFacebook} /></a>
                                        <a href="#" title="social-link" className="social-link social-twitter fab fa-twitter border-no"><FontAwesomeIcon icon={faTwitter} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
