import Header from "../Component/Header";
import { connect } from 'react-redux';
import { hederMenu, logout, userDetail } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";
const mapStatetoProps = state => ({
    data: state
})

const mapDispatchToProps = dispatch => ({
    // MenuHandler:data => dispatch(hederMenu(data))

    MenuHandler: async data => {
        try {
            let result = await fetch(environment.SERVER_URL + '/category');
            result = await result.json();

            if (result.success) {
                dispatch(hederMenu(result.data))
            } else {
                toast.error(result.responseMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",

                });
            }
            return result;
        } catch (error) {
            toast.error("something is wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

    },

    userHandler: async () => {
        try {
        let auth = null;
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
        }
        let result = await fetch(environment.SERVER_URL + '/customer/' + auth.referenceId, {
            method: 'get',
            headers: {
                "Authorization": `OEC ${auth.token}`
            },
        });
        result = await result.json();

        if (result.success) {
            dispatch(userDetail(result.data));
        } else {
            toast.error(result.responseMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            }
            
            return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    },    

    logouthandler: async () => {
        dispatch(logout())
    }
})

export default connect(mapStatetoProps, mapDispatchToProps)(Header)