import { useEffect, useState, useContext } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';
import Select from "react-select";
import { environment } from "../environment";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context } from "../Component/LoadingContext";
import { CartContext, SubTotalContext} from "../Component/CartContext";
import { fetchData } from '../Component/SupplierServices';
import AddUpdateDeliveryAddressContainer from '../Containers/AddUpdateDeliveryAddressContainer';
export default function CheckOut() {

  const navigate = useNavigate();

  const [shippingAddress, setShippingAddress] = new useState();
  const [firstname, setFirstName] = new useState();
  const [lastname, setLastName] = new useState();
  const [phone, setPhone] = new useState();
  const [address, setAddress] = new useState();
  const [country, setCountry] = new useState();
  const [state, setState] = new useState();
  const [pincode, setPincode] = new useState();
  const [city, setCity] = new useState();
  const [landmark, setlandmark] = new useState();
  const [error, setError] = React.useState(false);
  const [mobileerror, setMobileError] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [options1, setOptions1] = React.useState([]);
  const [countryoptions, setCountryOptions] = React.useState([]);
  const [pincodeerror, setPincoeError] = React.useState(false);
  const [checkValue, setCheckValue] = React.useState(false);
  const [checkoutSubTotal, setCheckoutSubTotal] = useState(0);
  const [OrderItem, setOrderItem] = React.useState([])
  const [showAddUpdateAddressModal, setshowAddUpdateAddressModal] = React.useState(false);
  const [shippingRate, setShippingRate] = React.useState(0);
  const [isShippingRate, setIsShippingRate] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [finalTotal, setFinalTotal] = React.useState(0);
  const [courierCompanyId, setCourierCompanyId] = React.useState(null);
  const [loading, setLoading] = useContext(Context);
  const { cartCount, setCartCount } = useContext(CartContext);
  const { subTotal, setSubTotal } = useContext(SubTotalContext);
  // const [razorpayOrderId, setRazorpayOrderId] = React.useState(null);
  let authUser = null;
  let cartItemData = null;
  let razorpayOrderId = null;
  let OrderObj = {
    firsName: '',
    lastName: '',
    address: '',
    landmark: '',
    courierCompanyId: null,

    city: '',
    pincode: '',
    mobileNo: '',
    subTotal: 0,
    total: 0,

    status: { id: '' },
    state: '',
    country: '',

    shippingAddress: {},
    customer: { id: '' },
    orderItemDetails: [],
    code: '',
    price: '',
    paymentId: '',
    orderTxnId: '',
    dateTime: '',
    updatedDate: '',

  }

  let shippingRateObj = {
    pickup_postcode: 0,
    delivery_postcode: 0,
    cod: 0,
    weight: ''
  };

  let weightSum = 0;


  useEffect(() => {
    getShippingRate();
  }, [shippingAddress])

  const getShippingRate = async () => {
    shippingRateObj = {};
    if (shippingAddress) {
      let shippingRateObj = {};
      shippingRateObj.pickup_postcode = 380001;
      shippingRateObj.delivery_postcode = Number(shippingAddress.pincode);
      if (cartItemData && cartItemData.length > 0) {
        cartItemData.forEach((ls) => {
          let totalProductWeight = ls.quantity * ls.productVariantCombinations.weight;
          weightSum = weightSum + totalProductWeight;

        })
      }
      shippingRateObj.weight = weightSum.toString();
      //shippingRateObj.weight = 5;
      shippingRateObj.cod = 0;
      setLoading(1);
      try {
      let result = await fetch(environment.SERVER_URL + '/order/getCourierServiceability', {
        method: 'post',
        body: JSON.stringify(shippingRateObj),
        headers: {
          "Authorization": `OEC` + ' ' + authUser.token,
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      setSuccess(result.success);
      if (result.success == true) {
        setLoading(0);
        if (result.data) {
          setShippingRate(result.data.rate);
          setCourierCompanyId(result.data.courier_company_id);
          let sum = result.data.rate + checkoutSubTotal;
          // setSubTotal(sum);
          setFinalTotal(sum);
          setIsShippingRate(false);
          setErrorMsg("");
        }
      } else {
        setLoading(0);
        setIsShippingRate(true);
        setCourierCompanyId(null);
        setErrorMsg(result.responseMessage);
        if (cartItemData && cartItemData.length > 0) {
          cartItemData.forEach((ls) => {
            total = total + (ls.productVariantCombinations.price * ls.quantity);
            //setSubTotal(total);
            setFinalTotal(total);
          })
        }
        toast.error(result.responseMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          className: 'toaster-size'
        })
        }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(0);
          return error; // Rethrow the error to handle it in the calling code
      }

    }
  }
  if (localStorage.getItem('authUser')) {
    authUser = JSON.parse(localStorage.getItem('authUser'));
  }

  if (localStorage.getItem('cartItems')) {
    cartItemData = JSON.parse(localStorage.getItem('cartItems'));
  }


  let total = 0;

  useEffect(() => {
    if (cartItemData && cartItemData.length > 0) {
      cartItemData.forEach((ls) => {
        total = total + (ls.productVariantCombinations.price * ls.quantity);
        setCheckoutSubTotal(total);
        setFinalTotal(total);
      })
    }

  }, [cartItemData && cartItemData.length > 0])

  useEffect(() => {
    if (cartItemData && cartItemData.length > 0) {
      cartItemData.forEach((ls) => {
        let obj = {
          quantity: 0,
          price: 0,
          product: { id: '' },
          productVariantCombinations: { id: '' }
        };

        obj.quantity = ls.quantity;
        obj.price = ls.productVariantCombinations.price * ls.quantity;
        obj.product.id = ls.product.id;
        obj.productVariantCombinations.id = ls.productVariantCombinations.id;


        OrderItem.push(obj);
      })
    }

  }, [cartItemData && cartItemData.length > 0])

  const handleAddUpdateAddressModalClose = () => setshowAddUpdateAddressModal(false);
  const handleAddUpdateAddressModalShow = () => setshowAddUpdateAddressModal(true);
  const saveDeliveryAddress = (data) => {
    handleAddUpdateAddressModalClose();
    getShipAddressList();
  }

  const cancelDeliveryAddress = (data) => {
    handleAddUpdateAddressModalClose();
  }

  const AddShippingAddress = () => {

    handleAddUpdateAddressModalShow();
  }

  const loadScript = (src) => {

    return new Promise((resolve) => {

      const script = document.createElement('script')
      script.src = src

      script.onload = () => {
        resolve(true);
      }

      script.onerror = () => {

        resolve(false)
      }

      document.body.appendChild(script)
    })
  }

  const displayrazorpay = async () => {
    if (!firstname || !lastname || !address || !city || !pincode || !phone || !shippingAddress || !country || !state || !landmark) {
      setErrorName(true) || setErrorLastName(true) || setErrorAddress(true) || setErrorPincode(true) || setErrorPhoneNumber(true) || setErrorLandmark(true) || setErrorCity(true) || setError(true)
      return false;
    } else {

      if (phone && phone.length !== 10) {
        setMobileError(true);
        // isValidMobile = true;
        return false;
      } else if (!isValidPincode(pincode)) {
        setPincoeError(true);
        return false;
      } else {

        setError(false);
        setMobileError(false);

        if (success == true) {
          OrderObj.firsName = firstname;
          OrderObj.lastName = lastname;
          OrderObj.address = address;
          OrderObj.landmark = landmark;
          OrderObj.city = city;
          OrderObj.pincode = pincode;
          OrderObj.mobileNo = phone;
          OrderObj.subTotal = checkoutSubTotal;
          OrderObj.total = finalTotal;
          OrderObj.orderItemDetails = OrderItem;
          OrderObj.state = state.value;
          OrderObj.country = country.value;
          OrderObj.shippingAddress = shippingAddress;
          OrderObj.customer.id = authUser.referenceId;
          OrderObj.address = address;
          OrderObj.status.id = 2;
          OrderObj.courierCompanyId = courierCompanyId;
          setLoading(1);
          let result = await fetch(environment.SERVER_URL + "/order/save", {
            method: 'post',
            body: JSON.stringify(OrderObj),
            headers: {
              "Authorization": `OEC` + ' ' + authUser.token,
              "Content-Type": "application/json"
            },
          });
          result = await result.json();
          if (result.data) {
            setLoading(0);
            razorpayOrderId = result.data.razorpayOrderId;
            const res = await loadScript('http://checkout.razorpay.com/v1/checkout.js')

            if (!res) {
              alert("you are offline...Failed to load Razorpay SDK");
              return
            }
            const options = {
              key: "rzp_test_c4QoSgD9HLCRQr",
              handler: async function (response) {

                if (response.razorpay_payment_id) {

                  let updatedResult = await fetch(environment.SERVER_URL + "/order/" + result.data.id, {
                    method: 'get',
                    headers: {
                      "Authorization": `OEC` + ' ' + authUser.token,
                      "Content-Type": "application/json"
                    },
                  });
                  updatedResult = await updatedResult.json();
                  let obj = { id: 1 };

                  updatedResult.data.paymentId = response.razorpay_payment_id;
                  updatedResult.data.status = obj;
                  let result1 = await fetch(environment.SERVER_URL + "/order/update", {
                    method: 'post',
                    body: JSON.stringify(updatedResult.data),
                    headers: {
                      "Authorization": `OEC` + ' ' + authUser.token,
                      "Content-Type": "application/json"
                    },
                  });
                  let result2 = await fetchData(`cart/deleteCart`);
                  if (result2.success) {
                    setCartCount(0);
                    setSubTotal(0)
                  }
                  localStorage.setItem("cartItems", []);

                  // }

                  toast.success("Your order is placed successfully", {
                    position: "top-right",
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  })
                } else {
                  toast.error(response.error.description, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  })

                }
                navigate("/myOrder");
              },

              prefill: {
                name: result.data.customer.name,
                email: result.data.customer.email,
                contact: "91" + result.data.customer.mobileNo,

              },


            };

            //options.amount = subTotal * 100;

            options.order_id = razorpayOrderId;
            const paymentObject = new window.Razorpay(options)
            paymentObject.open()
          } else {
            setLoading(0);
          }

        } else {

          toast.error(errorMsg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: 'toaster-size'
          })
        }




      }
    }


  }


  function isValidPincode(pincode) {
    return /^\d{6}$/.test(pincode);
  }


  useEffect(() => {
    getShipAddressList();
  }, [])

  let shipAddressList = [];
  const getShipAddressList = async () => {

    if (shipAddressList || shipAddressList.length === 0) {
      setLoading(1);
      try {
      let result = await fetch(environment.SERVER_URL + `/shippingAddress/getShippingAddressByCustomer/${authUser.referenceId}`, {
        method: 'get',
        headers: {
          "Authorization": `OEC` + ' ' + authUser.token,
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(0);
        shipAddressList = result.data;
        if (result) {
          var optionsTagsDb = [];
          result.data.forEach((res) => {
            let optionobj = { label: null, value: null }
            optionobj.label = res.address + "," + res.city + "," + res.country.name + "-" + res.pincode;
            optionobj.value = res;
            optionsTagsDb.push(optionobj);
          })
          setOptions1(optionsTagsDb);
        }
      } else {
        setLoading(0);
      }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(0);
          return error; // Rethrow the error to handle it in the calling code
    }
    }
  }

  const handleShipAddrInputChange = (e) => {
    setShippingAddress(e.value);
    if (checkValue) {
      setCheckValue(false);
      setFirstName('');
      setLastName('');
      setPhone('');
      setCountry('');
      setState('');
      setCity('');
      setPincode('');
      setlandmark('');
      setAddress('');
    }


  };

  useEffect(() => {
    getCountryList();
  }, [])

  let countryList = [];
  const getCountryList = async () => {

    if ((!countryList || countryList.length === 0)) {
      setLoading(1);
      try {
        let result = await fetch(environment.SERVER_URL + '/country', {
          method: 'get',
          headers: {
            "Authorization": `OEC` + ' ' + authUser.token
          },
        });

        result = await result.json();
        if (result.success) {
          setLoading(0);
          if (result) {
            var optionsTagsDb = [];
            result.data.forEach((res) => {
              let optionobj = { label: null, value: null }
              optionobj.label = res.name;
              optionobj.value = res;
              optionsTagsDb.push(optionobj);
            })
            setCountryOptions(optionsTagsDb);

          }
        } else {
          setLoading(0);
        }
      } catch (error) {
        setLoading(0);

      }
    }
  }


  const handleCountryInputChange = (Inputcountry) => {
    setCountry(Inputcountry);
    setState(null);
    getStateList(Inputcountry.value);
  }

  let stateList = [];
  const getStateList = async (e) => {
    if ((!stateList || stateList.length === 0)) {
      try {
      let result = await fetch(environment.SERVER_URL + '/state/getStateByCountry/' + e.id, {
        method: 'get',
        headers: {
          "Authorization": `OEC` + ' ' + authUser.token
        },
      });
      result = await result.json();
      if (result) {
        var optionsTagsDb = [];
        result.data.forEach((res) => {
          let optionobj = { label: null, value: null }
          optionobj.label = res.name;
          optionobj.value = res;
          optionsTagsDb.push(optionobj);
        })
        setOptions(optionsTagsDb);
        }
      } catch (error) {
          console.error("Error fetching user data:", error);
          return error; // Rethrow the error to handle it in the calling code
      }
    }
  }

  const onChangeState = (e) => {
    setState(e);
  }


  function handleCheckboxChange(e) {
    setCheckValue(!checkValue);

    if (e.target.checked && shippingAddress) {
      setFirstName(shippingAddress.firsName);
      setLastName(shippingAddress.lastName || '');
      setPhone(shippingAddress.mobileNo || '');
      let optionobj = { label: null, value: null };
      optionobj.label = shippingAddress.country ? shippingAddress.country.name : '';
      optionobj.value = shippingAddress.country || null;
      setCountry(optionobj);
      let optionobj1 = { label: null, value: null };
      optionobj1.label = shippingAddress.state ? shippingAddress.state.name : '';
      optionobj1.value = shippingAddress.state || null;
      setState(optionobj1);
      setCity(shippingAddress.city || '');
      setPincode(shippingAddress.pincode || '');
      setlandmark(shippingAddress.landmark || '');
      setAddress(shippingAddress.address || '');
    } else {
      setFirstName('');
      setLastName('');
      setPhone('');
      setCountry('');
      setState('');
      setCity('');
      setPincode('');
      setlandmark('');
      setAddress('');
    }
  }

  // const storedToken = localStorage.getItem("token");
  // useEffect(() => {
  //   if (storedToken) {
  //     navigate("/checkout");

  //   } else {
  //     navigate("/");
  //   }
  // }, [storedToken])

  // change validatons
  const [erroName, setErrorName] = useState(false);

  const handleChangeName = (e) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');

    if (filteredValue === inputValue) {

      setFirstName(inputValue.trimLeft());
      setErrorName(true);
    } else {

      setFirstName(filteredValue.trimLeft());
      setErrorName(false);
    }
  };
  const [erroLastName, setErrorLastName] = useState(false);

  const handleChangeLastName = (e) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');

    if (filteredValue === inputValue) {

      setLastName(inputValue.trimLeft());
      setErrorLastName(true);
    } else {

      setLastName(filteredValue.trimLeft());
      setErrorLastName(false);
    }



  };

  // Phone Number
  const [erroPhoneNumber, setErrorPhoneNumber] = useState(false);

  const handleChangePhoneNumber = (e) => {
    const inputValue = e.target.value;
    if (/^\d{10}$/.test(inputValue)) {
      setPhone(inputValue);
      setErrorPhoneNumber(false);
      setMobileError(false);
    } else if (inputValue === '') {
      setPhone('');
      setErrorPhoneNumber(true);
      setMobileError(false);
    } else {
      setPhone(inputValue);
      setErrorPhoneNumber(false);
      setMobileError(true);
    }
  };


  // City
  const [errorCity, setErrorCity] = useState(false);
  const handleChangeCity = (e) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');

    if (filteredValue === inputValue) {

      setCity(inputValue.trimLeft());
      setErrorCity(true);
    } else {

      setCity(filteredValue.trimLeft());
      setErrorCity(false);
    }



  };
  const [errorPincode, setErrorPincode] = useState(false);
  const onChangePincodes = event => {
    const input = event.target.value;
    const result = input.replace(/\D/g, '');

    if (result.length !== 6) {
      setErrorPincode(true);
      setPincode(result);
      setPincoeError(true);
    } else {
      setPincode(result);
      setErrorPincode(false);
      setPincoeError(false);
    }
  };

  const [errorLandmark, setErrorLandmark] = useState(false);
  const handleChangeLandmark = (e) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');

    if (filteredValue === inputValue) {

      setlandmark(inputValue.trimLeft());
      setErrorLandmark(true);
    } else {

      setlandmark(filteredValue.trimLeft());
      setErrorLandmark(false);
    }



  }

  const [errorAddress, setErrorAddress] = useState(false);

  const handleChangeAddress = (e) => {
    const inputValue = e.target.value;

    if (inputValue.trim() === '') {
      setErrorAddress(true);
      setAddress(inputValue.trimLeft());
    } else {
      setAddress(inputValue);
      setErrorAddress(false);
    }
  };


  return (
    <div>
      <main className="main checkout">
        <div className="page-content pt-7 pb-10 mb-10">
          <div className="step-by pr-4 pl-4" style={{
            display: '-webkit-inline-box',
            overflow: 'auto'
          }}>
            <h3 className="title title-simple title-step" style={{ marginRight: '5px', cursor: 'pointer' }}><a >1. Shopping Cart</a></h3>
            <BiChevronRight size={30} style={{ color: '#ccc' }} /><h3 className="title title-simple title-step active" style={{ marginRight: '5px', cursor: 'pointer' }}><a style={{ cursor: 'pointer' }}>2. Checkout</a></h3>
          </div>
          <div className="container mt-7">
            <div className="form" style={{ fontFamily: 'Poppins,sans-serif', textAlign: 'left' }}>
              <div className="row">
                <div className="col-lg-7 mb-6 mb-lg-0 pr-lg-4">
                  <div className="row mb-2">
                    <div className='col-8'>
                    <label>Shipping Address <span style={{ color: 'red' }}>*</span></label>
                    </div>
                    <div className='col-4'>
                    <button className="btn btn-primary float-end" onClick={AddShippingAddress}><span >Add</span></button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-lg-12">
                      <Select
                        className='select-form'
                        style={{ fontSize: '1.3rem' }}
                        defaultOptions
                        getOptionLabel={e => e.label}
                        getOptionValue={(option) => option.value}
                        options={options1}
                        onChange={handleShipAddrInputChange}
                        placeholder="Select Shipping Address"
                        isSearchable={true}
                      />
                      {error && !shippingAddress && <label className="checkout-err-select">Shipping Address is required.</label>}
                    </div>
                  </div>
                  <br></br>
                  <div className="row" >
                    <div className="col-xs-12 form-group">
                      <div className="form-checkbox" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <input type="checkbox" id="signin-remember" className="custom-checkbox" name="signin-remember" checked={checkValue} onClick={(e) => { handleCheckboxChange() }} />
                        <label className='customcheckbox' >
                          <div className='row'>
                            <div className='col-1 custom-col-1'>
                              <input
                                type="checkbox"
                                id="signin-remember"
                                className="custom-checkbox"
                                checked={checkValue}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className='col-11'>
                              <span
                              >Keep billing address same as shipping address</span>
                            </div>
                          </div>
                        </label>
                        {checkValue && !shippingAddress && (
                          <label className="checkout-err-select">
                            Please select a shipping address first.
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <h3 className="title title-simple text-left text-uppercase">Billing Details</h3>
                  <div className="row">
                    <div className="col-xs-6 form-group">
                      <label>First Name <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="form-control" name="first-name" required value={firstname} onChange={handleChangeName} placeholder='Enter First Name' />
                      {erroName && !firstname && <label className="checkout-err-input">First Name is required.</label>}
                    </div>
                    <div className="col-xs-6">
                      <label>Last Name <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" style={{ borderRadius: '5px' }} className="form-control" name="last-name" required value={lastname} onChange={handleChangeLastName} placeholder='Enter Last Name' />
                      {erroLastName && !lastname && <label className="checkout-err-input">Last Name is required.</label>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6">
                      <label>Phone <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" style={{ borderRadius: '5px' }} className="form-control" name="zip" required value={phone} onChange={handleChangePhoneNumber} placeholder='Enter Phone Number' />
                      {erroPhoneNumber && !phone && <label className="checkout-err-input">Phone is required.</label>}
                      {mobileerror && <label className="checkout-err-input">Phone No. should be 10 digit</label>}
                    </div>
                    <div className="col-xs-6  col-sm-6 col-md-6">
                      <label className='Countrys'>Country *</label>
                      <Select
                        className='select-form'
                        defaultOptions
                        getOptionLabel={e => e.label}
                        getOptionValue={(option) => option.value}
                        options={countryoptions}
                        onChange={handleCountryInputChange}
                        placeholder="Select Country"
                        value={country}
                      />
                      {error && !country && <label className="checkout-err-select">Country is required.</label>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6">
                      <label className='State'>State <span style={{ color: 'red' }}>*</span></label>
                      <Select
                        className='select-form'
                        defaultOptions
                        options={options}
                        getOptionLabel={e => e.label}
                        getOptionValue={(option) => option.value}
                        onChange={onChangeState}
                        value={state}
                        placeholder="Select State"
                      />
                      {error && !state && <label className="checkout-err-select">State is required.</label>}
                    </div>
                    <div className="col-xs-6">
                      <label className='City'>City <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" style={{ borderRadius: '5px' }} className="form-control" name="city" required value={city} onChange={handleChangeCity} placeholder='Enter City' />
                      {errorCity && !city && <label className="checkout-err-input">City is required.</label>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6">
                      <label>Pincode <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" style={{ borderRadius: '5px' }} className="form-control" name="zip" required value={pincode} onChange={onChangePincodes} placeholder='Enter Pincode' />
                      {errorPincode && !pincode && <label className="checkout-err-input">Pincode is required.</label>}
                      {pincodeerror && pincode && <><label className="checkout-err-input">Pincode is invalid</label></>}
                    </div>
                    <div className="col-xs-6">
                      <label>Landmark <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" style={{ borderRadius: '5px' }} className="form-control" name="phone" required value={landmark} onChange={handleChangeLandmark} placeholder='Enter Landmark' />
                      {errorLandmark && !landmark && <label className="checkout-err-input">Landmark is required.</label>}
                    </div>
                  </div>
                  <label>Address <span style={{ color: 'red' }}>*</span></label>
                  <input type="text" style={{ borderRadius: '5px' }} className="form-control" name="email-address" required value={address} onChange={handleChangeAddress} placeholder='Enter Address' />
                  {errorAddress && !address && <><label className="checkout-err-input">Address is required.</label><br></br><br></br></>}
                  <h2 className="title title-simple text-uppercase text-left">Additional Information</h2>
                  <label>Order Notes (Optional)</label>
                  <textarea className="form-control pb-2 pt-2 mb-0" cols={30} rows={5} placeholder="Notes about your order, e.g. special notes for delivery" defaultValue={""} />
                </div>
                <aside className="col-lg-5 sticky-sidebar-wrapper">
                  <div className="sticky-sidebar mt-1" data-sticky-options="{'bottom': 50}">
                    <div className="summary pt-5">
                      <h3 className="title title-simple text-left text-uppercase">Your Order</h3>
                      <table className="order-table">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {cartItemData ?
                            cartItemData.map((val) =>
                              <tr key={'cartItemData'+val?.id}>
                                <td className="product-name">{val.product.name}<span className="product-quantity">×&nbsp;{val.quantity}</span></td> 
                                <td className="product-total text-body" style={{ fontSize: '1.4rem' }}>&#8377;{(val.productVariantCombinations.price * val.quantity).toFixed(2)}</td>
                              </tr>
                            )
                            : null}
                          <tr className="summary-subtotal">
                            <td>
                              <h4 className="summary-subtitle">Shipping Rate</h4>
                            </td>
                            <td className="summary-subtotal-price pb-0 pt-0">{isShippingRate ? "NA" : <>₹{shippingRate ? shippingRate.toFixed(2) : '0'}</>}
                            </td>
                          </tr>
                          <tr className="summary-subtotal">
                            <td>
                              <h4 className="summary-subtitle">Subtotal</h4>
                            </td>
                            <td className="summary-subtotal-price pb-0 pt-0">₹{finalTotal ? finalTotal.toFixed(2) : '0'}
                            </td>
                          </tr>
                          <tr className="summary-total">
                            <td className="pb-0">
                              <h4 className="summary-subtitle">Total</h4> 
                            </td>
                            <td className=" pt-0 pb-0">
                              <p className="summary-total-price ls-s text-primary">₹{finalTotal ? finalTotal.toFixed(2) : '0'}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button type="button" className="btn btn-dark btn-rounded btn-order PlaceOrder" onClick={displayrazorpay}>Place Order</button>
                    </div>
                  </div>
                </aside>
              </div>
              <ToastContainer></ToastContainer>
            </div>
          </div>

          <Modal show={showAddUpdateAddressModal} size="xl" animation={false} onHide={handleAddUpdateAddressModalClose}>
            <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}><span>ADD DELIVERY ADDRESS</span>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleAddUpdateAddressModalClose}></button>
            </Modal.Header>
            <AddUpdateDeliveryAddressContainer save={saveDeliveryAddress} cancel={cancelDeliveryAddress} />
          </Modal>
        </div>
      </main>
    </div>
  )
}
