import React, {useState, useEffect, useContext} from 'react'
import { useParams} from "react-router-dom";
import Rating from 'react-rating';
import star_empty from "../../image/star_empty.png";
import star_yellow from "../../image/star_yellow.png";
import { fetchData, saveData } from '../../Component/SupplierServices';
import { Context } from "../../Component/LoadingContext";
export default function ReviewAddUpdate(props) {
    //declare common useState for review form field start
    const [formData, setFormData] = useState({
        id: null,
        rating: 0,
        description: '',
        ratingError: false,
        descriptionError: false,
        title: '', // Add title property
        customer: {},
        product: {},
    });
    //declare common useState for review form field end
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useContext(Context);
    let params = useParams();
    useEffect(() => {
        // Update formData state with the provided data when props.data changes
        setFormData(prevState => ({
            ...prevState,
            customer: props.data?.UserDetail || {},
            product: props.data?.ProductDetail || {},
        }));
    
        // If formData prop changes, update the local state accordingly
        if (props.formData) {
            setFormData(props.formData);
        }
    }, [props.data, props.formData]);
    

    // Function to handle on review form submission start
    const onSubmitReview = async () => {
        // Validate the form fields
        let formValid = true;
        const updatedFormData = { ...formData };
        Object.keys(formData).forEach(key => {
            if (key !== 'title' && key !== 'customer' && key !== 'product' && key !== 'id' && key !== 'isDeleted' && key !== 'isActive') {
                if (!formData[key] && !key.endsWith('Error')) {
                    updatedFormData[`${key}Error`] = true;
                    formValid = false;
                }
            }

        });
        setFormData(updatedFormData);
        if (!formValid) return; // Stop further execution if any field is empty

        if (formValid) {
            formData.customer = props?.data?.UserDetail;
            setLoading(1);
            let result = await fetchData(`product/${params.id}`);
            if (result.success) {
                formData.product = result.data;
                setLoading(0);
            }
            setLoading(1);
            const response = await saveData('productRating', formData);
            if (response.success && response.data) {
                props.getProductRatingDetailsByCustomerIdAndProductId();
                props.getProductReviewByProductId();
                toggleForm();
                setLoading(0);
            } else {
                setLoading(0);
            }
        }
    };
    // Function to handle on review form submission end


     // Function to toggle the visibility of the form
     const toggleForm = () => {
        setShowForm(!showForm);
        props.onToggleForm(!showForm);
    }
// Function to toggle the visibility of the end


  // Function to handle changes in review form fields start
  const handleFieldChange = (fieldName, value, isMandatory) => {

    // Dynamically set the field value and its corresponding error state
    setFormData({
        ...formData,
        [fieldName]: value,
        ...(isMandatory ? { [`${fieldName}Error`]: !value } : '')
    });

};
// Function to handle changes in review form fields end
    
    return (
        <div>
            <div className="col-12 comments pt-2 pb-10 border-no" >
                <form>
                    <div className='row' style={{ textAlign: 'justify' }}>
                        <div className='col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="firstName" style={{ fontSize: 'medium' }}>Rating<span className="text-danger">*</span></label>
                                <Rating
                                    initialRating={formData.rating}
                                    className="ml-2"
                                    emptySymbol={<img src={star_empty} className="icon" />}
                                    fullSymbol={<img src={star_yellow} className="icon" />}
                                    value={formData.rating}
                                    onChange={(newRating) => handleFieldChange('rating', newRating, true)}
                                />
                            </div>
                            {formData.ratingError && !formData.rating && <label className="error-label">Rating is required</label>}
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className="form-group mb-3">
                                <label htmlFor="firstName" style={{ fontSize: 'medium' }}>Name</label>
                                <input
                                    className="form-control formfont"
                                    type="text"
                                    name="firstName"
                                    disabled
                                    autoComplete="off"
                                    style={{ fontSize: '14px' }}
                                    value={props?.data?.UserDetail?.name}
                                />
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className="form-group mb-3">
                                <label htmlFor="firstName" style={{ fontSize: 'medium' }}>Email</label>
                                <input
                                    className="form-control formfont"
                                    type="text"
                                    name="firstName"
                                    disabled
                                    autoComplete="off"
                                    style={{ fontSize: '14px' }}
                                    value={props?.data?.UserDetail?.email}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="firstName" style={{ fontSize: 'medium' }}>Title</label>
                                <input
                                    className="form-control formfont"
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter Title"
                                    autoComplete="off"
                                    style={{ fontSize: '14px' }}
                                    value={formData.title}
                                    maxLength={60} // Adjust the value as per your requirement
                                    onChange={(e) => handleFieldChange('title', e.target.value, false)}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group">
                                <label htmlFor="address" style={{ fontSize: 'medium' }}>Description<span className="text-danger">*</span></label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    placeholder="Enter Description"
                                    autoComplete="off"
                                    style={{ fontSize: '14px' }}
                                    value={formData.description}
                                    onChange={(e) => handleFieldChange('description', e.target.value, true)}
                                />
                            </div>
                            {formData.descriptionError && !formData.description && <label className="error-label">Description is required</label>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <a className="btn btn-dark btn-rounded submit-review-toggle mr-2" style={{ fontSize: 'medium', color: 'white' }} onClick={onSubmitReview}>Submit Review</a>
                            <a className="btn btn-dark btn-rounded submit-review-toggle" style={{ fontSize: 'medium', color: 'black', background: 'white' }} onClick={toggleForm}>Cancel</a>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}
