import Login from "../Component/Login";
import { connect } from 'react-redux';
import { login } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";
import { getDataById } from "../../src/Component/SupplierServices";

const mapStatetoProps = state => ({
    data: state
})
let auth = null;

const mapDispatchToProps = dispatch => ({
    // MenuHandler:data => dispatch(hederMenu(data))
    loginHandler: async data => {
        try {
            let result = await fetch(environment.SERVER_URL + '/login', {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            result = await result.json();

            if (result.success) {
                localStorage.setItem("authUser", JSON.stringify(result.data));
                localStorage.setItem("token", JSON.stringify(result.data.token));
                toast.success("User Login Successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                dispatch(login(result.data))


            } else {
                toast.error(result.responseMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",

                });
            }
            return result;

        } catch (error) {
            toast.error("something is wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return error
        }

    },

    AddtoCart: async data => {
        try {
            
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
        }
        let arr = [];
        let obj = {
            quantity: '',
            isSaveOrLater: false,
            isActive: "true",
            isDeleted: "false",
            customer: { id: null },
            product: { id: null },
            productVariantCombinations: { id: null }
        };
        if (data && data.length > 0) {
            data.forEach((ls) => {
                obj = {
                    quantity: '',
                    isSaveOrLater: false,
                    isActive: "true",
                    isDeleted: "false",
                    customer: { id: null },
                    product: { id: null },
                    productVariantCombinations: { id: null }
                };
                obj.quantity = ls.quantity;
                obj.isSaveOrLater = false;
                obj.isActive = "true";
                obj.isDeleted = "false";
                obj.customer.id = auth.referenceId;
                obj.product.id = ls.product.id;
                obj.productVariantCombinations.id = ls.productVariantCombinations.id;

                arr.push(obj);
            })

        }
        
        let result = await fetch(environment.SERVER_URL + '/cart/saveAll', {
            method: 'post',
            body: JSON.stringify(arr),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    },

    GetCartProductByUser: async () => {
        try {
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
        }
    
        let result = await fetch(environment.SERVER_URL + '/cart/getCartProductByUser/' + auth.referenceId, {
            method: 'get',
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    },
    GetWishListByCustomerId: async (id) => {
        return getDataById('cart/getWishListByCustomer',id);
    },
})

export default connect(mapStatetoProps, mapDispatchToProps)(Login)