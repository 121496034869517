import React, { useEffect, useState, useContext } from 'react'
import { BiChevronDown } from "react-icons/bi";
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { CartContext, SubTotalContext, WishListCountContext, SearchContext, AuthUserContext, BreadCrumbsContext } from '../Component/CartContext';
import { Context } from "../Component/LoadingContext";
import { calculateSubTotal } from "../Component/Common";
import Loader from './Loader';
import logos from '../image/final_logo.png'
import { fetchData } from "../../src/Component/SupplierServices"
import { Modal } from "react-bootstrap";
import LoginContainer from '../Containers/LoginContainer';
export default function Header(props) {
  let menudata = {};
  let menuss = [];
  let auth = null;
  const location = useLocation(); // Hook to access the current location
  const [username, setUsername] = useState("");
  const [cartItem, setCartItem] = useState([]);
  const { cartCount, setCartCount } = useContext(CartContext);
  const { wishListCount, setWishListCount } = useContext(WishListCountContext);
  const { search, setSearch } = useContext(SearchContext);
  const [inputValue, setInputValue] = useState('');
  const { subTotal, setSubTotal } = useContext(SubTotalContext);
  const [loading, setLoading] = useContext(Context);
  const state = useSelector(state => state);
  let Users = useSelector(state => state);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const handleLoginModalClose = () => setShowLoginModal(false);
  const handleLoginModalShow = () => setShowLoginModal(true);
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { breadCrumbValue, setBreadCrumbValue } = useContext(BreadCrumbsContext);
  if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
  }
  useEffect(() => {
    // Reset inputValue to an empty string when the location is not the search page
    if (location.pathname !== '/search') {
      setInputValue('');
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('cartItems') && localStorage.getItem('cartItems').length > 0) {
      setCartItem(JSON.parse(localStorage.getItem('cartItems')));
      let cartItems = JSON.parse(localStorage.getItem('cartItems'));
      setCartCount(cartItems.length);
      const subtotal = calculateSubTotal(cartItems);
      setSubTotal(subtotal);
    }
  }, [localStorage.getItem('cartItems') && localStorage.getItem('cartItems').length > 0]);

  useEffect(() => {
    try {
      setLoading(1)
      props.MenuHandler();
    } catch (error) {

    } finally {
      setLoading(0)
    }
    getWishList();
  }, []);

  const handleLoginSuccess = () => {
    setShowLoginModal(false); // Close the login modal
   // window.location.reload(); // Reload the page
};


  const getWishList = async () => {
    if (auth?.referenceId) {
      const response = await fetchData('cart/getWishListByCustomer/' + auth?.referenceId);
      if (response.success && response.data) {
        setWishListCount(response?.data?.length);
      }
    }else{
      setWishListCount(0);
    }
  }

  useEffect(() => {

    if (auth) {
      getCustomerDetails();
    }

  }, [Users.Login]);

  const getCustomerDetails = async () => {

    props.userHandler().then((val) => {

      if (val.success) {
        setUsername(val.data.name)

      } else {
        // alert("registered failed due to : "+val.responseMessage)
      }
    });
  }
  menudata = useSelector(state => {
    if (state.HeaderMenu) {
      state.HeaderMenu.forEach(element => {
        element.HeaderMenu.forEach((ls) => {
          menuss.push(ls);
        })
      });
    } else {
      menuss = []
    }
  })
  
  const navigate = useNavigate();
  const redirectCart = () => {
    navigate("/cart");
  }

  const redirectWishList = () => {
    if(auth?.referenceId){
      navigate("/wishlist");  
    }else{
      setShowLoginModal(true); // Close the login modal
    }
    
  }

  const redirectlogin = () => {
    navigate("/login");
  }

  const redirectregister = () => {
    navigate("/register");
  }



  const handleInputChange = (value) => {
    setInputValue(value);
    // Call handleSubmit if input value becomes empty
    if (value === '') {
      handleSubmit(value);
    }
  };

  const handleSubmit = (value) => {
    setSearch({ searchInput: value, data: props });
    navigate('/search'); // Redirect to the search page
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(inputValue); // Pass inputValue directly to handleSubmit
    }
  };
  const redirecthome = async () => {
    try {
      // Execute logout process
      await props.logouthandler();
      // Clear local storage and set cart count
      localStorage.clear();
      setCartCount(0);
      setSubTotal(0)
      setAuthUser(null);
      setWishListCount(0);
      // Redirect to home page
      //navigate("/");
      if (location.pathname.startsWith("/product") || location.pathname === "/profile"  || location.pathname === "/myOrder" || location.pathname === "/shipping-address-list" || location.pathname === "/cart" )  {
        setTimeout(() => {
          navigate("/");
        }, 100);
      }
    } catch (error) {
      // Handle error if needed
    }
  }
  const ToShopProduct = (id, item) => {
    //environment.shopProduct = id;
    localStorage.setItem("CategoryWiseProducts", id);
    setBreadCrumbValue(item.name);
    closeSidebar();
  }

  // useEffect(() => {
  //   console.warn("loadddd", loading);
  // }, [loading])

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Function to toggle the sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);

  };

  // Account Menu
  const [sidebarAccountOpen, setAccountSidebarOpen] = useState(false);
  const accountSidebar = () => {
    setAccountSidebarOpen(!sidebarAccountOpen);
  };
  const closeAccount = () => {
    setAccountSidebarOpen(false);

  };
  const handleAccountLinkClick = () => {
    closeAccount(false);
  };

  return (

    <div>
      {/* {loading} */}
      {loading == 1 ? <><Loader></Loader></> : ''}
      <div className="page-wrapper header header-border">
        <h1 className="d-none">Riode - Responsive eCommerce HTML Template</h1>
        <header className="header">
          <div className="header-top">
            <div className="container">
              <div className="header-left">
                <p className="welcome-msg">Welcome to MG Store </p>
              </div>
              <div className="header-right">
                {
                  auth ?
                    <>
                      <div className="dropdown1">
                        <a style={{ textDecoration: 'none' }} onClick={accountSidebar} ><i className="d-icon-user" style={{ height: "20px", cursor: 'pointer' }} onClick={accountSidebar} />&nbsp;&nbsp;<span style={{ cursor: 'pointer' }}>{username}</span> </a>
                        {
                          sidebarAccountOpen && (
                            <div className="dropdown-content">

                              <Link to={"/profile"} onClick={handleAccountLinkClick}>Profile</Link>
                              <Link to={"/shipping-address-list"} onClick={handleAccountLinkClick}>Addresses</Link>
                              <Link to={"/myOrder"} onClick={handleAccountLinkClick}>My Orders</Link>

                              <Link onClick={redirecthome} style={{ textDecoration: 'none' }}>Sign Out</Link>

                            </div>
                          )
                        }

                      </div>
                    </>
                    :
                    < >
                      <a className="header-option" onClick={redirectlogin} >    <i className="d-icon-user" style={{ marginRight: '10px' }} /> Login</a>
                      <span  >/</span>
                      <a className="header-option" onClick={redirectregister}  >Register</a>

                    </>
                }
                <div className="dropdown login-dropdown off-canvas">
                  <div className="canvas-overlay" />
                  {/* End Login Toggle */}
                  <div className="dropdown-box scrollable">
                    <div className="login-popup">
                      <div className="form-box">
                        <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                          <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5" role="tablist">
                            <li className="nav-item">
                              <a className="nav-link active border-no lh-1 ls-normal" href="#signin">Login</a>
                            </li>
                            <li className="delimiter">or</li>
                            <li className="nav-item">
                              <a className="nav-link border-no lh-1 ls-normal" href="#register">Register</a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div className="tab-pane active" id="signin">
                              <form action="#">
                                <div className="form-group mb-3">
                                  <input type="text" className="form-control" id="singin-email" name="singin-email" placeholder="Username or Email Address *" required />
                                </div>
                                <div className="form-group">
                                  <input type="password" className="form-control" id="singin-password" name="singin-password" placeholder="Password *" required />
                                </div>
                                <div className="form-footer">
                                  <div className="form-checkbox">
                                    <input type="checkbox" className="custom-checkbox" id="signin-remember" name="signin-remember" />
                                    <label className="form-control-label" htmlFor="signin-remember">Remember
                                      me</label>
                                  </div>
                                  <a href="#" className="lost-link">Lost your password?</a>
                                </div>
                                <button className="btn btn-dark btn-block btn-rounded" type="submit">Login</button>
                              </form>
                              <div className="form-choice text-center">
                                <label className="ls-m">or Login With</label>
                                <div className="social-links">
                                  <a href="#" title="social-link" className="social-link social-google fab fa-google border-no" />
                                  <a href="#" title="social-link" className="social-link social-facebook fab fa-facebook-f border-no" />
                                  <a href="#" title="social-link" className="social-link social-twitter fab fa-twitter border-no" />
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="register">
                              <form action="#">
                                <div className="form-group mb-3">
                                  <input type="email" className="form-control" id="register-email" name="register-email" placeholder="Your Email Address *" required />
                                </div>
                                <div className="form-group">
                                  <input type="password" className="form-control" id="register-password" name="register-password" placeholder="Password *" required />
                                </div>
                                <div className="form-footer">
                                  <div className="form-checkbox">
                                    <input type="checkbox" className="custom-checkbox" id="register-agree" name="register-agree" required />
                                    <label className="form-control-label" htmlFor="register-agree">I
                                      agree to the
                                      privacy policy</label>
                                  </div>
                                </div>
                                <button className="btn btn-dark btn-block btn-rounded" type="submit">Register</button>
                              </form>
                              <div className="form-choice text-center">
                                <label className="ls-m">or Register With</label>
                                <div className="social-links">
                                  <a href="#" title="social-link" className="social-link social-google fab fa-google border-no" />
                                  <a href="#" title="social-link" className="social-link social-facebook fab fa-facebook-f border-no" />
                                  <a href="#" title="social-link" className="social-link social-twitter fab fa-twitter border-no" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button title="Close (Esc)" type="button" className="mfp-close"><span>×</span></button>
                    </div>
                  </div>
                  {/* End Dropdown Box */}
                </div>
                {/* End of Login */}
              </div>
            </div>
          </div>
          {/* End HeaderTop */}
          <div className="header-middle sticky-header fix-top sticky-content">
            <div className="container">
              <div className="header-left">
                <a className="mobile-menu-toggle" onClick={toggleSidebar}>
                  <i className="d-icon-bars2" />
                </a>


                <div className={` ${sidebarOpen ? 'open' : ''}`}>

                  {sidebarOpen && (
                    <>

                      <div className='sidenav'>
                        <span onClick={toggleSidebar} className='closeMenu' style={{ marginLeft: "85%", padding: "20px", height: "30px", width: "30px", color: "white" }}><i className="d-icon-times" /></span>
                        <div className='input-search'>
                          <form action="#" className="input-wrappers">
                            <input type="text" className="form-control" name="search" autoComplete="off" placeholder="Search your keyword..." required />
                            <button className="btn-search3" type="submit" title="submit-button">
                              <i className="d-icon-search" />
                            </button>
                          </form>
                        </div>
                        <div className='sidbarss'>
                          <a href="#" className="w3-bar-item w3-button">Home</a>
                          <a href="#" className="w3-bar-item w3-button">About</a>
                          {
                            menuss.length > 0
                              ? menuss.map((item, i) =>
                                <Link href="#" to={'/shop/products/' + item.id} onClick={() => ToShopProduct(item.id, item)} className='w3-bar-item w3-button'>{item.name}</Link>
                              )
                              : ''
                          }
                          <a href="#" className="w3-bar-item w3-button">CategoryId</a>

                          <a href="#" className="w3-bar-item w3-button">Login</a>
                          <a href="#" className="w3-bar-item w3-button">Register</a>
                        </div>
                      </div>
                    </>


                  )}
                </div>
                <Link to="/" className="logo">
                  <div className="row">
                    <div className='headerLogo' style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="col-md-6 col-sm-6 col-lg-6">
                        <img src={logos} alt="logo" width={40} height={44} />
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 logoTitle">
                        <div className="titleText">
                          <span>MG</span> <span>Store</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>


                {/* End Logo */}
                <div className="header-search hs-simple">
                  <div className="input-wrapper">
                    <input type="text" className="form-control" name="search" autoComplete="off" placeholder="Search..." required value={inputValue}
                      onChange={(e) => handleInputChange(e.target.value)}
                      onKeyPress={handleKeyPress} />
                    <button className="btn btn-search" type="button" title="submit-button" disabled={inputValue === ''}   onClick={() => handleSubmit(inputValue)}>
                      <i className="d-icon-search" />
                    </button>
                  </div>
                </div>
                {/* End Header Search */}
              </div>
              <div className="header-right">
                <div className="icon-box icon-box-side">
                  <div className="icon-box-content d-lg-show">
                    <h4 className="icon-box-title" style={{color:'var(--blue)'}}>Call Us Now:</h4>
                    <a href="tel:9426344388" className='anchorwhite'> <p>9426344388</p></a>
                  </div>
                </div>
                <span className="divider" />
                <div className="dropdown wishlist wishlist-dropdown off-canvas">
                  <div className="dropdown cart-dropdown type2 off-canvas mr-0 mr-lg-2">
                    <a className="cart-toggle label-block link anchorBlack" onClick={redirectWishList}>
                      <i className="d-icon-heart" onClick={redirectWishList} style={{ cursor: 'pointer' }}><span className="cart-count">{wishListCount}</span></i>
                    </a>
                  </div>
                  <div className="canvas-overlay" />
                  {/* End Wishlist Toggle */}
                  <div className="dropdown-box scrollable">
                    <div className="canvas-header">
                      <h4 className="canvas-title">wishlist</h4>
                      <a href="#" className="btn btn-dark btn-link btn-icon-right btn-close">close<i className="d-icon-arrow-right" /><span className="sr-only">wishlist</span></a>
                    </div>
                    <div className="products scrollable">
                      <div className="product product-wishlist">
                        <figure className="product-media">
                          <a >
                            <img src="images/wishlist/product-1.jpg" width={100} height={100} alt="product" />
                          </a>
                          <button className="btn btn-link btn-close">
                            <i className="fas fa-times" /><span className="sr-only">Close</span>
                          </button>
                        </figure>
                        <div className="product-detail">
                          <a className="product-name">Girl's Dark Bag</a>
                          <div className="price-box">
                            <span className="product-price">$84.00</span>
                          </div>
                        </div>
                      </div>
                      {/* End of wishlist Product */}
                      <div className="product product-wishlist">
                        <figure className="product-media">
                          <a >
                            <img src="images/wishlist/product-2.jpg" width={100} height={100} alt="product" />
                          </a>
                          <button className="btn btn-link btn-close">
                            <i className="fas fa-times" /><span className="sr-only">Close</span>
                          </button>
                        </figure>
                        <div className="product-detail">
                          <a className="product-name">Women's Fashional Comforter
                          </a>
                          <div className="price-box">
                            <span className="product-price">$84.00</span>
                          </div>
                        </div>
                      </div>
                      {/* End of wishlist Product */}
                      <div className="product product-wishlist">
                        <figure className="product-media">
                          <a >
                            <img src="images/wishlist/product-3.jpg" width={100} height={100} alt="product" />
                          </a>
                          <button className="btn btn-link btn-close">
                            <i className="fas fa-times" /><span className="sr-only">Close</span>
                          </button>
                        </figure>
                        <div className="product-detail">
                          <a className="product-name">Wide Knickerbockers</a>
                          <div className="price-box">
                            <span className="product-price">$84.00</span>
                          </div>
                        </div>
                      </div>
                      {/* End of wishlist Product */}
                    </div>
                    <a href="wishlist.html" className="btn btn-dark wishlist-btn mt-4 anchorBlack"><span>Go To
                      Wishlist</span></a>
                    {/* End of Products  */}
                  </div>
                  {/* End Dropdown Box */}
                </div>
                <span className="divider" />
                <div className="dropdown cart-dropdown type2 off-canvas mr-0 mr-lg-2">
                  <a className="cart-toggle label-block link anchorBlack" onClick={redirectCart}>
                    <div className="cart-label d-lg-show">
                      <span className="cart-name">Shopping Cart:</span> 
                      <span className="cart-price">₹{subTotal ? subTotal.toFixed(2) : '0'}</span>
                    </div>
                    <i className="d-icon-bag" onClick={redirectCart} style={{ cursor: 'pointer' }}><span className="cart-count">{cartCount}</span></i>
                  </a>
                  <div className="canvas-overlay" />
                  {/* End Cart Toggle */}
                  <div className="dropdown-box">
                    <div className="canvas-header">
                      <h4 className="canvas-title">Shopping Cart</h4>
                      <a href="#" className="btn btn-dark btn-link btn-icon-right btn-close">close<i className="d-icon-arrow-right" /><span className="sr-only">Cart</span></a>
                    </div>
                    <div className="products scrollable">
                      <div className="product product-cart">
                        <figure className="product-media">
                          <a >
                            <img src="images/cart/product-1.jpg" alt="product" width={80} height={88} />
                          </a>
                          <button className="btn btn-link btn-close">
                            <i className="fas fa-times" /><span className="sr-only">Close</span>
                          </button>
                        </figure>
                        <div className="product-detail">
                          <a className="product-name">Riode White Trends</a>
                          <div className="price-box">
                            <span className="product-quantity">1</span>
                            <span className="product-price">$21.00</span>
                          </div>
                        </div>
                      </div>
                      {/* End of Cart Product */}
                      <div className="product product-cart">
                        <figure className="product-media">
                          <a >
                            <img src="images/cart/product-2.jpg" alt="product" width={80} height={88} />
                          </a>
                          <button className="btn btn-link btn-close">
                            <i className="fas fa-times" /><span className="sr-only">Close</span>
                          </button>
                        </figure>
                        <div className="product-detail">
                          <a className="product-name">Dark Blue Women’s
                            Leomora Hat</a>
                          <div className="price-box">
                            <span className="product-quantity">1</span>
                            <span className="product-price">$118.00</span>
                          </div>
                        </div>
                      </div>
                      {/* End of Cart Product */}
                    </div>
                    {/* End of Products  */}
                    <div className="cart-total">
                      <label>Subtotal:</label>
                      <span className="price">$139.00</span>
                    </div>
                    {/* End of Cart Total */}
                    <div className="cart-action">
                      <a href="" className="btn btn-dark btn-link">View Cart</a>
                      <a href="" className="btn btn-dark"><span>Go To Checkout</span></a>
                    </div>
                    {/* End of Cart Action */}
                  </div>
                  {/* End Dropdown Box */}
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom d-lg-show">
            <div className="container">
              <div className="header-left">
                <nav className="main-nav">
                  <ul className="menu">
                    <li className="active">
                      <Link to={"/"} style={{ display: "flex", paddingTop: '0px', color: 'black' }}><span>Home</span></Link>
                    </li>
                    {
                    menuss.length > 0 &&
                      menuss.map((item, i) => (
                        <li key={item.id}>
                          <Link to={'/shop/products/' + item.id} onClick={() => ToShopProduct(item.id, item)} style={{ display: "flex", paddingTop: item.children ? '' : '0px', color: 'black' }}>
                            <span>{item.name}</span>
                            {item.children ? <BiChevronDown size={25} style={{ paddingBottom: '9px' }} /> : ''}
                          </Link>
                        </li>
                      ))
                    }
                    <li>
                      <a href="#" style={{ display: "flex", paddingTop: '0px', color: 'black' }}><span>About Us</span></a>

                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/* End Header */}

      </div>

      <a id="scroll-top" href="#top" title="Top" role="button" className="scroll-top"><i className="d-icon-arrow-up" /></a>
      {/* MobileMenu */}
      <div className="mobile-menu-wrapper">
        <div className="mobile-menu-overlay">
        </div>
        {/* End of Overlay */}
        <a className="mobile-menu-close" href="#"><i className="d-icon-times" /></a>
        {/* End of CloseButton */}
        <div className="mobile-menu-container scrollable">
          <form action="#" className="input-wrapper">
            <input type="text" className="form-control" name="search" autoComplete="off" placeholder="Search your keyword..."  />
            <button className="btn btn-search" type="submit" title="submit-button">
              <i className="d-icon-search" />
            </button>
          </form>
          {/* End of Search Form */}

          <ul className="mobile-menu mmenu-anim">
            <li>
              <Link to={'/'}>Home</Link>
            </li>
            <li>
              <a >Categories</a>
              <ul>
                <li>
                  <a href="#">
                    Variations 1
                  </a>
                  <ul>
                    <li><a href="shop-classic-filter.html">Classic Filter</a></li>
                    <li><a href="shop-left-toggle-sidebar.html">Left Toggle Filter</a></li>
                    <li><a href="shop-right-toggle-sidebar.html">Right Toggle Sidebar</a></li>
                    <li><a href="shop-horizontal-filter.html">Horizontal Filter </a>
                    </li>
                    <li><a href="shop-navigation-filter.html">Navigation Filter</a></li>
                    <li><a href="shop-off-canvas.html">Off-Canvas Filter </a></li>
                    <li><a href="shop-top-banner.html">Top Banner</a></li>
                    <li><a href="shop-inner-top-banner.html">Inner Top Banner</a></li>
                    <li><a href="shop-with-bottom-block.html">With Bottom Block</a></li>
                    <li><a href="shop-category-in-page-header.html">Category In Page Header</a>
                    </li></ul>
                </li>
                <li>
                  <a href="#">
                    Variations 2
                  </a>
                  <ul>
                    <li><a href="shop-grid-3cols.html">3 Columns Mode</a></li>
                    <li><a href="shop-grid-4cols.html">4 Columns Mode</a></li>
                    <li><a href="shop-grid-5cols.html">5 Columns Mode</a></li>
                    <li><a href="shop-grid-6cols.html">6 Columns Mode</a></li>
                    <li><a href="shop-grid-7cols.html">7 Columns Mode</a></li>
                    <li><a href="shop-grid-8cols.html">8 Columns Mode</a></li>
                    <li><a href="shop-list-mode.html">List Mode</a></li>
                    <li><a href="shop-pagination.html">Pagination</a></li>
                    <li><a href="shop-infinite-ajaxscroll.html">Infinite Ajaxscroll </a></li>
                    <li><a href="shop-loadmore-button.html">Loadmore Button</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">
                    Variations 3
                  </a>
                  <ul>
                    <li><a href="shop-category-grid-shop.html">Category Grid Shop</a></li>
                    <li><a href="shop-category%2bproducts.html">Category + Products</a></li>
                    <li><a href="shop-default-1.html">Shop Default 1 </a>
                    </li>
                    <li><a href="shop-default-2.html">Shop Default 2</a></li>
                    <li><a href="shop-default-3.html">Shop Default 3</a></li>
                    <li><a href="shop-default-4.html">Shop Default 4</a></li>
                    <li><a href="shop-default-5.html">Shop Default 5</a></li>
                    <li><a href="shop-default-6.html">Shop Default 6</a></li>
                    <li><a href="shop-default-7.html">Shop Default 7</a></li>
                    <li><a href="shop-default-8.html">Shop Default 8</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <a >Products</a>
              <ul>
                <li>
                  <a href="#">Product Pages</a>
                  <ul>
                    <li><a href="product-simple.html">Simple Product</a></li>
                    <li><a href="product-featured.html">Featured &amp; On Sale</a></li>
                    <li><a >Variable Product</a></li>
                    <li><a href="product-variable-swatch.html">Variation Swatch
                      Product</a></li>
                    <li><a href="product-grouped.html">Grouped Product </a></li>
                    <li><a href="product-external.html">External Product</a></li>
                    <li><a href="product-in-stock.html">In Stock Product</a></li>
                    <li><a href="product-out-stock.html">Out of Stock Product</a></li>
                    <li><a href="product-upsell.html">Upsell Products</a></li>
                    <li><a href="product-cross-sell.html">Cross Sell Products</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Product Layouts</a>
                  <ul>
                    <li><a href="product-vertical.html">Vertical Thumb</a></li>
                    <li><a href="product-horizontal.html">Horizontal Thumb</a></li>
                    <li><a href="product-gallery.html">Gallery Type</a></li>
                    <li><a href="product-grid.html">Grid Images</a></li>
                    <li><a href="product-masonry.html">Masonry Images</a></li>
                    <li><a href="product-sticky.html">Sticky Info</a></li>
                    <li><a href="product-sticky-both.html">Left &amp; Right Sticky</a></li>
                    <li><a href="product-left-sidebar.html">With Left Sidebar</a></li>
                    <li><a href="product-right-sidebar.html">With Right Sidebar</a></li>
                    <li><a href="product-full.html">Full Width Layout </a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Product Features</a>
                  <ul>
                    <li><a href="product-sale.html">Sale Countdown</a></li>
                    <li><a href="product-hurryup.html">Hurry Up Notification </a></li>
                    <li><a href="product-attribute-guide.html">Attribute Guide </a></li>
                    <li><a href="product-sticky-cart.html">Add Cart Sticky</a></li>
                    <li><a href="product-thumbnail-label.html">Labels on Thumbnail</a>
                    </li>
                    <li><a href="product-more-description.html">More Description
                      Tabs</a></li>
                    <li><a href="product-accordion-data.html">Data In Accordion</a></li>
                    <li><a href="product-tabinside.html">Data Inside</a></li>
                    <li><a href="product-video.html">Video Thumbnail </a>
                    </li>
                    <li><a href="product-360-degree.html">360 Degree Thumbnail </a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Pages</a>
              <ul>
                <li><a href="about-us.html">About</a></li>
                <li><a href="contact-us.html">Contact Us</a></li>
                <li><a href="account.html">Login</a></li>
                <li><a href="faq.html">FAQs</a></li>
                <li><a href="error-404.html">Error 404</a>
                  <ul>
                    <li><a href="error-404.html">Error 404-1</a></li>
                    <li><a href="error-404-1.html">Error 404-2</a></li>
                    <li><a href="error-404-2.html">Error 404-3</a></li>
                    <li><a href="error-404-3.html">Error 404-4</a></li>
                  </ul>
                </li>
                <li><a href="coming-soon.html">Coming Soon</a></li>
              </ul>
            </li>
            <li>
              <a href="blog-classic.html">Blog</a>
              <ul>
                <li><a href="blog-classic.html">Classic</a></li>
                <li><a href="blog-listing.html">Listing</a></li>
                <li>
                  <a href="#">Grid</a>
                  <ul>
                    <li><a href="blog-grid-2col.html">Grid 2 columns</a></li>
                    <li><a href="blog-grid-3col.html">Grid 3 columns</a></li>
                    <li><a href="blog-grid-4col.html">Grid 4 columns</a></li>
                    <li><a href="blog-grid-sidebar.html">Grid sidebar</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Masonry</a>
                  <ul>
                    <li><a href="blog-masonry-2col.html">Masonry 2 columns</a></li>
                    <li><a href="blog-masonry-3col.html">Masonry 3 columns</a></li>
                    <li><a href="blog-masonry-4col.html">Masonry 4 columns</a></li>
                    <li><a href="blog-masonry-sidebar.html">Masonry sidebar</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Mask</a>
                  <ul>
                    <li><a href="blog-mask-grid.html">Blog mask grid</a></li>
                    <li><a href="blog-mask-masonry.html">Blog mask masonry</a></li>
                  </ul>
                </li>
                <li>
                  <a href="post-single.html">Single Post</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="element.html">Elements</a>
              <ul>
                <li>
                  <a href="#">Elements 1</a>
                  <ul>
                    <li><a href="element-accordions.html">Accordions</a></li>
                    <li><a href="element-alerts.html">Alert &amp; Notification</a></li>
                    <li><a href="element-banner-effect.html">Banner Effect
                    </a></li>
                    <li><a href="element-banner.html">Banner
                    </a></li>
                    <li><a href="element-blog-posts.html">Blog Posts</a></li>
                    <li><a href="element-breadcrumb.html">Breadcrumb
                    </a></li>
                    <li><a href="element-buttons.html">Buttons</a></li>
                    <li><a href="element-cta.html">Call to Action</a></li>
                    <li><a href="element-countdown.html">Countdown
                    </a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Elements 2</a>
                  <ul>
                    <li><a href="element-counter.html">Counter </a></li>
                    <li><a href="element-creative-grid.html">Creative Grid
                    </a></li>
                    <li><a href="element-animation.html">Entrance Effect
                    </a></li>
                    <li><a href="element-floating.html">Floating
                    </a></li>
                    <li><a href="element-hotspot.html">Hotspot
                    </a></li>
                    <li><a href="element-icon-boxes.html">Icon Boxes</a></li>
                    <li><a href="element-icons.html">Icons</a></li>
                    <li><a href="element-image-box.html">Image box
                    </a></li>
                    <li><a href="element-instagrams.html">Instagrams</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Elements 3</a>
                  <ul>
                    <li><a href="element-categories.html">Product Category</a></li>
                    <li><a href="element-products.html">Products</a></li>
                    <li><a href="element-product-banner.html">Products + Banner
                    </a></li>
                    <li><a href="element-product-grid.html">Products + Grid
                    </a></li>
                    <li><a href="element-product-single.html">Product Single
                    </a>
                    </li>
                    <li><a href="element-product-tab.html">Products + Tab
                    </a></li>
                    <li><a >Single Product
                    </a></li>
                    <li><a href="element-slider.html">Slider
                    </a></li>
                    <li><a href="element-social-link.html">Social Icons </a></li>
                  </ul>
                </li>
                <li>
                  <a href="#">Elements 4</a>
                  <ul>
                    <li><a href="element-subcategory.html">Subcategory
                    </a></li>
                    <li><a href="element-svg-floating.html">Svg Floating
                    </a></li>
                    <li><a href="element-tabs.html">Tabs</a></li>
                    <li><a href="element-testimonials.html">Testimonials
                    </a></li>
                    <li><a href="element-titles.html">Title</a></li>
                    <li><a href="element-typography.html">Typography</a></li>
                    <li><a href="element-vendor.html">Vendor
                    </a></li>
                    <li><a href="element-video.html">Video
                    </a></li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div>
      <Modal show={showLoginModal} size="md" animation={false} onHide={handleLoginModalClose}>
                <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}><span>Login</span>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleLoginModalClose}></button>
                </Modal.Header>
                <LoginContainer  onLoginSuccess={handleLoginSuccess}/>
            </Modal>
    </div>
  )
}


