import Profile from "../Component/Profile";
import { connect } from 'react-redux';
import { hederMenu, logout, userDetail } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";

const mapStatetoProps = state => ({
    data: state
})

let auth = null;
if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
}

const mapDispatchToProps = dispatch => ({
    ShippingAddressHandler: async data => {
        try {
        let result = await fetch(environment.SERVER_URL +"/shippingAddress/getShippingAddressByCustomer/"+data,{
            method: 'get',
            headers: {
                "Authorization": `OEC` + ' ' + auth.token
            },
        });
        result = await result.json();
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    },

    UpdateProfileHandler: async data => {
        try {
        let result = await fetch(environment.SERVER_URL +"/customer/update",{
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        if (result.success) {
            toast.success("Profile Updated Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                fontSize:'70px'
            });
            dispatch(userDetail(result.data))
    
        } else {
            toast.error(result.responseMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    }

    
})

export default connect(mapStatetoProps, mapDispatchToProps)(Profile)