import React, { useState, useEffect } from 'react'

export default function PaginationComponent(props) {
    const [currentPage, setCurrentPage] = useState(1);
  
    useEffect(() => {
        setCurrentPage(props?.list?.number + 1); // Update the current page in the component state
      }, [props]);
    const handlePageChange = (pageNumber) => {
       
        props.setCurrentPage(pageNumber);
    };

    return (
        <div>
            {
                props.list?.content ?
                    <nav>
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Prev
                                </button>
                            </li>
                            {Array.from({ length: props.list?.totalPages }).map((_, index) => {
                                const pageNumber = index + 1;
                                if (
                                    pageNumber === 1 ||
                                    pageNumber === currentPage ||
                                    pageNumber === currentPage - 1 ||
                                    pageNumber === currentPage + 1 ||
                                    pageNumber === props.list?.totalPages
                                ) {
                                    return (
                                        <li
                                            key={index}
                                            className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(pageNumber)}
                                            >
                                                {pageNumber}
                                            </button>
                                        </li>
                                    );
                                } else if (
                                    pageNumber === currentPage - 2 ||
                                    pageNumber === currentPage + 2
                                ) {
                                    return (
                                        <li
                                            key={index}
                                            className="page-item disabled"
                                        >
                                            <span className="page-link">...</span>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                            <li
                                className={`page-item ${currentPage === props.list?.totalPages ? 'disabled' : ''}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                    :

                    ''
            }

        </div>
    )
}


