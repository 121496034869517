import React, { useEffect, useState, useContext } from 'react'
import { BiChevronRight } from "react-icons/bi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { environment } from '../environment';
import { CartContext, SubTotalContext,AuthUserContext } from "../Component/CartContext";
import { Context } from '../Component/LoadingContext';
import empty_cart from "../image/empty-cart.png";
import no_image from "../image/no_image.png";
import { save, getData } from "../Component/SupplierServices"
import { calculateSubTotal } from "../Component/Common";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
const BootstrapTooltip = styled((props) => (
  <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip} .MuiTooltip-tooltip`]: {
    fontSize: '16px', // Adjust the font size as needed
  },
}));

export default function Cart() {
  const [cartItem, setCartItem] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [saveForLaterProductImage, setSaveForLaterProductImage] = useState([]);
  const [saveForLaterItem, setSaveForLaterItem] = useState([]);
  const { subTotal, setSubTotal } = useContext(SubTotalContext);
  const { cartCount, setCartCount } = useContext(CartContext);
  const [loading, setLoading] = useContext(Context);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  let auth = null;

  if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
  }

  let CategoryId = null;
  if (localStorage.getItem('CategoryWiseProducts')) {
    CategoryId = JSON.parse(localStorage.getItem('CategoryWiseProducts'));
  }
  let total = 0;
  const navigate = useNavigate();
  const redirectCheckout = () => {
    navigate("/checkout");
  }

//   useEffect(() => {
//     setAuthUser(getAuthUser);
// }, []);

// const getAuthUser = () => {
//     if (localStorage.getItem('authUser')) {
//        return JSON.parse(localStorage.getItem('authUser'));
//     }
//     return null;
// }

  useEffect(() => {
    if (auth) {
      getCartProductByUser();
    }
  }, [authUser]);
  
  useEffect(() => {
    if(auth){
      getCartProductByUser();
    }
  }, [])

  let tempCartData = [];
  const getCartProductByUser = async () => {
    if(auth && auth.referenceId){
      setLoading(true);
    const response = await getData(`cart/getCartProductByUser/${auth.referenceId}`);
    if (response.success && response.data) {
        tempCartData = response.data.map(ls => {
          const CartObj = {
            id: ls.id,
            quantity: ls.quantity,
            isSaveOrLater: ls.isSaveOrLater,
            isActive: true,
            isDeleted: false,
            customer: { id: auth.referenceId },
            product: { id: ls.product.id, name: ls.product.name },
            productVariantCombinations: {
              id: ls.productVariantCombinations.id,
              price: ls.productVariantCombinations.price,
              weight: ls.productVariantCombinations.weight,
              productVariantDetails: ls.productVariantCombinations.productVariantDetails.map(res => ({
                attribute: res.attribute.name,
                attributeValue: res.attributeValue.name
              }))
            }
          };
          // Assuming you want to include categoryId in CartObj
          CartObj.categoryId = ls.productVariantCombinations.category.id;
          return CartObj;
        });
      setLoading(0);
      updateCartData(tempCartData);
      updateSaveForLaterData(tempCartData);
      setLoading(false);
    } else {
        updateCartData([]);
        updateSaveForLaterData([]);
        setLoading(false);
    }
    }else{
      setLoading(false);
    }
  };

  useEffect(() => {
    cartItem.forEach((ls) => {
      total = total + (ls.productVariantCombinations.price * ls.quantity);
      setSubTotal(total);
    })
  }, [cartItem && cartItem.length > 0])

  const onCheckOut = () => {
    if (localStorage.getItem('authUser')) {
      redirectCheckout();
    } else {
      environment.checkoutRedirect = true;
      navigate("/login");

    }
  }

  useEffect(() => {
    if (localStorage.getItem('cartItems')) {
      const updatedCartItemsWithDetails = setVariantDetails(JSON.parse(localStorage.getItem('cartItems')), 'cart');
      setCartItem(updatedCartItemsWithDetails);
    }
    const updatedSaveForLaterItemsWithDetails = setVariantDetails(JSON.parse(localStorage.getItem('saveForLaterItems')), 'saveForLater');
    setSaveForLaterItem(updatedSaveForLaterItemsWithDetails)
  }, [localStorage.getItem('cartItems')])

  const onMinus = async (product, index) => {

    product.quantity = product.quantity - 1;
    if (product.quantity == 0) {
      product.quantity = 1;
    }

    if (auth) {
      let CartObj = {
        id: null,
        quantity: '',
        isSaveOrLater: product.isSaveOrLater,
        isActive: true,
        isDeleted: false,
        customer: { id: null },
        product: { id: null },
        productVariantCombinations: { id: null }
      };

      CartObj.id = product.id;
      CartObj.quantity = product.quantity;
      CartObj.customer.id = auth.referenceId;
      CartObj.product.id = product.product.id;
      CartObj.productVariantCombinations.id = product.productVariantCombinations.id;
      setLoading(1);
      try {
      let result = await fetch(environment.SERVER_URL + '/cart/update', {
        method: 'post',
        body: JSON.stringify(CartObj),
        headers: {
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(0);
      } else {
        setLoading(0);
      }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(0);
          return error; // Rethrow the error to handle it in the calling code
      }
    }
    cartItem[index].quantity = product.quantity

    updateCartData(cartItem);

    if (cartItem && cartItem.length > 0) {
      cartItem.forEach((ls) => {
        total = total + (ls.productVariantCombinations.price * ls.quantity);
        setSubTotal(total);
      })
    } else {
      setSubTotal(0);
    }
  }


  const onMinusForSaveForLater = async (product, index) => {

    product.quantity = product.quantity - 1;
    if (product.quantity == 0) {
      product.quantity = 1;
    }

    if (auth) {
      let CartObj = {
        id: null,
        quantity: '',
        isSaveOrLater: true,
        isActive: true,
        isDeleted: false,
        customer: { id: null },
        product: { id: null },
        productVariantCombinations: { id: null }
      };

      CartObj.id = product.id;
      CartObj.quantity = product.quantity;
      CartObj.customer.id = auth.referenceId;
      CartObj.product.id = product.product.id;
      CartObj.productVariantCombinations.id = product.productVariantCombinations.id;
      setLoading(1);
      try {
      let result = await fetch(environment.SERVER_URL + '/cart/update', {
        method: 'post',
        body: JSON.stringify(CartObj),
        headers: {
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(0);
      } else {
        setLoading(0);
      }  
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(0);
          return error; // Rethrow the error to handle it in the calling code
      }
    }
    saveForLaterItem[index].quantity = product.quantity
    //updateCartData();
  }

  const onPlusForSaveForLater = async (product, index) => {

    product.quantity = product.quantity + 1;
    if (auth) {
      let CartObj = {
        id: null,
        quantity: '',
        isSaveOrLater: true,
        isActive: true,
        isDeleted: false,
        customer: { id: null },
        product: { id: null },
        productVariantCombinations: { id: null }
      };

      CartObj.id = product.id;
      CartObj.quantity = product.quantity;
      CartObj.customer.id = auth.referenceId;
      CartObj.product.id = product.product.id;
      CartObj.productVariantCombinations.id = product.productVariantCombinations.id;
      setLoading(1);
      try {
      let result = await fetch(environment.SERVER_URL + '/cart/update', {
        method: 'post',
        body: JSON.stringify(CartObj),
        headers: {
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(0);
      } else {
        setLoading(0);
      }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(0);
          return error; // Rethrow the error to handle it in the calling code
      }
    }

    saveForLaterItem[index].quantity = product.quantity
    //updateCartData();

  }

  const onPlus = async (product, index) => {

    product.quantity = product.quantity + 1;
    if (auth) {
      let CartObj = {
        id: null,
        quantity: '',
        isSaveOrLater: product.isSaveOrLater,
        isActive: true,
        isDeleted: false,
        customer: { id: null },
        product: { id: null },
        productVariantCombinations: { id: null }
      };

      CartObj.id = product.id;
      CartObj.quantity = product.quantity;
      CartObj.customer.id = auth.referenceId;
      CartObj.product.id = product.product.id;
      CartObj.productVariantCombinations.id = product.productVariantCombinations.id;
      setLoading(1);
      try {
      let result = await fetch(environment.SERVER_URL + '/cart/update', {
        method: 'post',
        body: JSON.stringify(CartObj),
        headers: {
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(0);
      } else {
        setLoading(0);
      }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(0);
          return error; // Rethrow the error to handle it in the calling code
      } 
    }

    cartItem[index].quantity = product.quantity
    updateCartData(cartItem);

    if (cartItem && cartItem.length > 0) {
      cartItem.forEach((ls) => {
        total = total + (ls.productVariantCombinations.price * ls.quantity);
        setSubTotal(total);
      })
    } else {
      setSubTotal(0);
    }
  }




  const removeItem = async (product, index) => {
    if (auth) {
      let CartObj = {
        id: null
      };
      CartObj.id = product.id;
      setLoading(true);
      try {
      let result = await fetch(environment.SERVER_URL + '/cart/delete', {
        method: 'post',
        body: JSON.stringify(CartObj),
        headers: {
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(false);
          return error; // Rethrow the error to handle it in the calling code
      }
    }

    cartItem.splice(index, 1);
    updateCartData(cartItem);
    //  environment.cartCount =  environment.cartCount - 1;
    setCartCount(cartCount - 1)
    const subtotal = calculateSubTotal(cartItem);
    setSubTotal(subtotal);
  }

  const removeItemFromSaveForLater = async (product, index) => {
    if (auth) {
      let CartObj = {
        id: null
      };

      CartObj.id = product.id;
      setLoading(true);
      try {
      let result = await fetch(environment.SERVER_URL + '/cart/delete', {
        method: 'post',
        body: JSON.stringify(CartObj),
        headers: {
          "Content-Type": "application/json"
        },
      });
      result = await result.json();
      if (result.success) {
        setLoading(false);
      } else {
        setLoading(false);
        }
      } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(false);
          return error; // Rethrow the error to handle it in the calling code
      }
    }
    saveForLaterItem.splice(index, 1);
    updateSaveForLaterData(saveForLaterItem);
  }

  const saveForLater = async (product, index) => {
    setLoading(true);
    let obj = {
      quantity: product.quantity,
      isSaveOrLater: true,
      isActive: true,
      isDeleted: false,
      customer: { id: product.customer.id },
      product: { id: product.product.id },
      productVariantCombinations: { id: product.productVariantCombinations.id }
    };
    const response = await save('cart/save', obj);
    if (response.success && response.data) {
      // Remove the item from cartItem
      const updatedCartItems = cartItem.filter((item, i) => i !== index);
      product.isSaveOrLater = true;
      // Add the item to saveForLaterItem
      const updatedSaveForLater = [...saveForLaterItem, product];
      // Update state variables with the new values
      // setCartItem(setVariantDetails(updatedCartItems,'cart'));
      // setSaveForLaterItem(setVariantDetails(updatedSaveForLater,'saveForLater'));
      const updatedSaveForLaterItems = setVariantDetails(updatedSaveForLater, 'saveForLater');
      const updatedCartItemsWithDetails = setVariantDetails(updatedCartItems, 'cart');
      setCartItem(updatedSaveForLaterItems);
      setSaveForLaterItem(updatedCartItemsWithDetails);

      // Update local storage
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItemsWithDetails));
      localStorage.setItem('saveForLaterItems', JSON.stringify(updatedSaveForLaterItems));
      setLoading(false);
    }else{
      setLoading(false);
    }
  }


  const moveForCart = async (product, index) => {
    setLoading(true);
    let obj = {
      quantity: product.quantity,
      isSaveOrLater: false,
      isActive: true,
      isDeleted: false,
      customer: { id: product.customer.id },
      product: { id: product.product.id },
      productVariantCombinations: { id: product.productVariantCombinations.id }
    };
    const response = await save('cart/moveToCart', obj);
    if (response.success && response.data) {
      // Remove the item from saveForLaterItem
      const updatedSaveForLater = saveForLaterItem.filter((item, i) => i !== index);

      // Add the item to cartItem
      const updatedCartItems = [...cartItem, product];
      // Update state variables with the new values
      // setSaveForLaterItem(setVariantDetails(updatedSaveForLater,'saveForLater'));
      // setCartItem(setVariantDetails(updatedCartItems,'cart'));
       // Update state variables with the new values
       const updatedSaveForLaterItems = setVariantDetails(updatedSaveForLater, 'saveForLater');
       const updatedCartItemsWithDetails = setVariantDetails(updatedCartItems, 'cart');
       setCartItem(updatedCartItemsWithDetails);
       setSaveForLaterItem(updatedSaveForLaterItems);

      // Update local storage
      
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItemsWithDetails));
      localStorage.setItem('saveForLaterItems', JSON.stringify(updatedSaveForLaterItems));
      setLoading(false);
    }else{
      setLoading(false);
    }
  }


  const updateCartData = async (tempItem) => {
    const cartItemsToStore = tempItem.filter(item => !item.isSaveOrLater);
    const updatedCartItemsWithDetails = setVariantDetails(cartItemsToStore, 'cart');
    setCartItem(updatedCartItemsWithDetails);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItemsWithDetails))
  
  }
  const updateSaveForLaterData = async (tempItem) => {
    const saveForLaterItemsToStore = tempItem.filter(item => item.isSaveOrLater);
    const updatedSaveForLaterItemsWithDetails = setVariantDetails(saveForLaterItemsToStore, 'saveForLater');
    setSaveForLaterItem(updatedSaveForLaterItemsWithDetails);
    localStorage.setItem('saveForLaterItems', JSON.stringify(updatedSaveForLaterItemsWithDetails));
  }

  useEffect(() => {
    if (cartItem && cartItem.length > 0) {
      //environment.cartCount = cartItem.length;
      setCartCount(cartItem.length)
    } else {
      // environment.cartCount = 0;
      setCartCount(0);
    }
    const subtotal = calculateSubTotal(cartItem);
    setSubTotal(subtotal);
  }, [cartItem])




  const ContinueToShop = () => {
    navigate("/shop/products/" + CategoryId);
  }
  useEffect(() => {
    const fetchProductImages = async (items, setImage) => {
      if (items && items.length > 0) {
        const imagePromises = items.map(async (item) => {
          try {
          const response = await fetch(`${environment.SERVER_URL}/fileDownload/getProductImage/${item.product.id}`);
          const blob = await response.blob();
          if (blob.type !== "application/json") {
            const imageUrl = URL.createObjectURL(blob);
            return { productId: item.product.id, imageUrl };
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
            return { productId: item.product.id, imageUrl: null };
          }
          return null;
        });
        const productImages = await Promise.all(imagePromises);
        setImage(productImages);
      }
    };
  
    fetchProductImages(cartItem, setProductImage);
    fetchProductImages(saveForLaterItem, setSaveForLaterProductImage);
  }, [cartItem, saveForLaterItem]);
  

  useEffect(() => {

    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

const setVariantDetails = (list, key) => {
  return list && list.map(item => {
      const variantsDetails = item.productVariantCombinations.productVariantDetails
          .filter(detail => detail.attribute && detail.attributeValue)
          .map(detail => `${detail.attribute}=${detail.attributeValue}`).join(',');
      return {
          ...item,
          variantsDetails: variantsDetails,
          isSaveOrLater: key === 'saveForLater'
      };
  });
};

  // Assuming you have an array of quantities in your state
  const [quantities, setQuantities] = useState([]); // Initialize with your initial quantities

  const handleQuantityChange = (e, index) => {
    const newValue = e.target.value;
    // Create a copy of the quantities array
    const newQuantities = [...quantities];
    // Update the quantity at the specified index
    newQuantities[index] = newValue;
    // Set the updated quantities array to the state
    setQuantities(newQuantities);
  };

  return (
    <main className="main cart">
      <div className="page-content pt-7 pb-10">
        <div className="step-by pr-4 pl-4" hidden={cartItem && cartItem.length > 0 ? false : true}>
          <div style={{
            display: '-webkit-inline-box',
            overflow: 'auto'
          }}>
            <h3 className="title title-simple title-step active" style={{ marginRight: '5px', cursor: 'pointer' }}><a>1. Shopping Cart</a></h3>
            <BiChevronRight size={30} style={{ color: '#ccc', cursor: 'pointer' }} /><h3 className="title title-simple title-step" style={{ marginRight: '5px', cursor: 'pointer' }}><a>2. Checkout</a></h3>
          </div>
        </div>
        <div className="container mt-7 mb-2" hidden={cartItem && cartItem.length > 0 ? false : true}>
          <div className="row">
            <div className="col-lg-8 col-md-12 pr-lg-4  d-none d-sm-none d-md-block d-lg-block d-xl-block">
              <table className="shop-table cart-table">
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th>Product</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th><span>quantity</span></th>
                    <th>Subtotal</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItem && cartItem.length > 0
                    ? cartItem.map((item, i) =>
                      <tr key={`cartItemForWeb-${item?.id}-${i}`} style={{ textAlign: 'center' }} >
                          <td className="product-thumbnail">
                            <figure>
                              <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                <img src={productImage[i] ? productImage[i].imageUrl : no_image} width={100} height={100} alt="product"
                                  onError={(e) => {
                                    e.target.src = no_image; // Set the default image source if the image fails to load
                                  }}
                                />
                              </a>
                            </figure>
                          </td>
                          <td className="product-name">
                          <BootstrapTooltip title={item.variantsDetails}>
                            <div className="product-name-section">
                              <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              >
                                {item.product.name}
                              </a>
                              <p>{item?.variantsDetails ? (item.variantsDetails.length > 15 ? item.variantsDetails.slice(0, 15) + '...' : item.variantsDetails) : null}</p>
                            </div>
                            </BootstrapTooltip>
                          </td>
                          <td className="product-subtotal">
                            <span className="amount">₹{item.productVariantCombinations.price ? item.productVariantCombinations.price.toFixed(2) : '0'}</span>
                          </td>
                          <td className="product-quantity" style={{ width: '10rem' }}>
                            <div className="input-group">
                              <button className="quantity-minus d-icon-minus" onClick={() => onMinus(item, i)} />
                            {/* <input className="quantity form-control" type="number" min={1} max={1000000} defaultValue={item.quantity} style={{ fontWeight: 'bold' }} /> */}
                            <input
                              className="quantity form-control"
                              type="number"
                              min={1}
                              max={1000000}
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(e, i)}
                              style={{ fontWeight: 'bold' }}
                            />
                              <button className="quantity-plus d-icon-plus" onClick={() => onPlus(item, i)} />
                            </div>
                          </td>
                          <td className="product-price">
                            <span className="amount">&#8377;{(item.productVariantCombinations.price * item.quantity).toFixed(2)}</span>
                          </td>
                          <td className="product-close">
                            <div className='row' style={{ float: 'left', fontSize: '10px', width: 'max-content' }}>
                              <div className='col-8' hidden={auth === null}>
                                <button type="submit" className="btn btn-md btn-dark btn-rounded btn-outline p-3" style={{ float: 'left', fontSize: '10px', width: 'max-content' }} onClick={() => saveForLater(item, i)} >Save For Later</button>
                              </div>
                              <div className='col-4 m-auto'>
                                <button className="quantity-plus " style={{ border: '0px', backgroundColor: 'white' }} onClick={() => removeItem(item, i)} >
                                  <FontAwesomeIcon className='cross-button' icon={faCircleXmark} style={{ fontSize: 20 }} />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                    )
                    : null}
                </tbody>
              </table>
              <div className="cart-actions mb-6 pt-4">
                <a className="btn btn-dark btn-md btn-rounded btn-icon-left mr-4 mb-4" style={{ color: '#fff', fontWeight: '700' }} onClick={ContinueToShop}><i className="d-icon-arrow-left" />Continue Shopping</a>
              </div>
                  </div>
            <div className="col-lg-8 col-md-12 pr-lg-4 d-block d-sm-block d-md-none d-lg-none d-xl-none">
              {cartItem && cartItem.length > 0
                ? cartItem.map((item, i) =>
                    <div key={`cartItemForMobile-${item?.id}-${i}`} style={{ marginLeft: '6px', marginRight: '6px' }}>
                      <div className='row' style={{ borderColor: '#80808040', borderStyle: 'solid', borderWidth: '1px', paddingBottom: '8px' }}>
                        <div className="product-close" style={{ textAlign: 'end', position: 'absolute', paddingRight: '12px', paddingTop: '2px' }}>
                          <button className="quantity-plus " style={{ border: '0px', backgroundColor: 'transparent', position: 'relative', zIndex: '1' }} onClick={() => removeItem(item, i)} >
                            <FontAwesomeIcon className='cross-button' icon={faCircleXmark} style={{ fontSize: 20 }} />
                          </button>
                        </div>
                        <div className="product-thumbnail col-12">
                          <figure>
                            <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                              <img src={productImage[i] ? productImage[i].imageUrl : no_image} width={100} height={100} alt="product" />
                            </a>
                          </figure>
                        </div>
                        <div className="product-name col-8 text-start" key={`product-${item.id}-${i}`}>
                        <BootstrapTooltip title={item.variantsDetails}>
                          <div className="product-name-section">
                            <a key={`product.name-${item.id}-${i}`} onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                              {item.product.name}
                            </a>
                            <p key={`variantsDetails-${item.id}-${i}`}>{item?.variantsDetails ? (item.variantsDetails.length > 15 ? item.variantsDetails.slice(0, 15) + '...' : item.variantsDetails) : null}</p>
                          </div>
                          </BootstrapTooltip>
                        <div className="product-subtotal " key={`subtotal-${item.id}-${i}`}>
                          {item.productVariantCombinations.productVariantDetails && item.productVariantCombinations.productVariantDetails.length > 0
                            ? item.productVariantCombinations.productVariantDetails.map((rs, index) => (
                              <div key={`variant-${item.id}-${index}`}>
                                {rs.attribute}-{rs.attributeValue}<br />
                              </div>
                                ))
                                : null
                            }
                            <span className="amount" key={`amount-${item.id}-${i}`}>{item.price}</span>
                          </div>
                        </div>
                        <div className="product-subtotal col-4 text-end">
                          <h5 className="amount">₹{item.productVariantCombinations.price ? item.productVariantCombinations.price.toFixed(2) : '0'}</h5>
                        </div>
                        <div className="product-quantity col-6">
                          <div className="input-group">
                            <button className="quantity-minus d-icon-minus" onClick={() => onMinus(item, i)} />
                          {/* <input className="quantity form-control" type="number" min={1} max={1000000} value={item.quantity} style={{ fontWeight: 'bold' }} /> */}
                          <input
                            className="quantity form-control"
                            type="number"
                            min={1}
                            max={1000000}
                            value={item.quantity}
                            onChange={(e) => handleQuantityChange(e, i)}
                            style={{ fontWeight: 'bold' }}
                          />
                            <button className="quantity-plus d-icon-plus" onClick={() => onPlus(item, i)} />
                          </div>
                        </div>
                        <div className="product-price col-6 text-end">
                          <span className="amount">&#8377;{(item.productVariantCombinations.price * item.quantity).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                )
                : null}
              </div>

            <aside className="col-lg-4 sticky-sidebar-wrapper">
              <div className="sticky-sidebar" data-sticky-options="{'bottom': 20}">
                <div className="summary mb-4">
                  <h3 className="summary-title text-left">Cart Totals</h3>
                  <table className="shipping">
                    <tbody><tr className="summary-subtotal">
                      <td style={{ textAlign: 'left' }}>
                        <h4 className="summary-subtitle">Subtotal</h4>
                      </td>
                      <td>
                        <p className="summary-subtotal-price">₹{subTotal ? subTotal.toFixed(2) : '0'}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table className="total">
                    <tbody><tr className="summary-subtotal">
                      <td>
                        <h4 className="summary-subtitle">Total</h4>
                      </td>
                      <td>
                        <p className="summary-total-price ls-s">₹{subTotal ? subTotal.toFixed(2) : '0'}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <a onClick={onCheckOut} className="btn btn-dark btn-rounded btn-checkout proceedToChekout mt-3" style={{ color: 'white' }}>Proceed to checkout</a>
                </div>
              </div>
            </aside>
          </div>
        </div>
        <div className="container mt-7 mb-2" hidden={cartItem && cartItem.length > 0 ? true : false}>
          <div className="col-8 m-auto">
            <img src={empty_cart} style={{ width: '-webkit-fill-available' }} alt="empty cart" />
          </div>
          <div className="col-12"><h3 className="d-flex align-items-center justify-content-center">Your cart is empty</h3>
          </div>
        </div>
        <h2 hidden={saveForLaterItem && saveForLaterItem.length > 0 ? false : true} style={{ background: 'lightgrey', padding: '10px' }}>
          Save For Later
        </h2>
        <div className="container mt-7 mb-2" hidden={saveForLaterItem && saveForLaterItem.length > 0 ? false : true}>
          <div className="row">
            <div className="col-lg-12 col-md-12 pr-lg-4  d-none d-sm-none d-md-block d-lg-block d-xl-block">
              <table className="shop-table cart-table">
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th><span>Product</span></th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th><span>quantity</span></th>
                    <th>Subtotal</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {saveForLaterItem && saveForLaterItem.length > 0
                    ? saveForLaterItem.map((item, i) =>
                        <tr key={`saveForLaterForWeb-${item?.id}-${i}`} style={{ textAlign: 'center' }} >
                          <td className="product-thumbnail">
                            <figure>
                              <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                <img src={saveForLaterProductImage[i] ? saveForLaterProductImage[i].imageUrl : no_image} width={100} height={100} alt="product"
                                  onError={(e) => {
                                    e.target.src = no_image; // Set the default image source if the image fails to load
                                  }}
                                />
                              </a>
                            </figure>
                          </td>
                          <td className="product-name">
                          <BootstrapTooltip title={item.variantsDetails}>
                            <div className="product-name-section">
                              <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              >
                                {item.product.name}
                              </a>
                              <p>{item?.variantsDetails ? (item.variantsDetails.length > 15 ? item.variantsDetails.slice(0, 15) + '...' : item.variantsDetails) : null}</p>
                            </div>
                            </BootstrapTooltip>
                          </td>
                          <td className="product-subtotal">
                            <span className="amount">₹{item.productVariantCombinations.price ? item.productVariantCombinations.price.toFixed(2) : '0'}</span>
                          </td>
                          <td className="product-quantity" style={{ width: '10rem' }}>
                            <div className="input-group">
                              <button className="quantity-minus d-icon-minus" onClick={() => onMinusForSaveForLater(item, i)} />
                            {/* <input className="quantity form-control" type="number" min={1} max={1000000} value={item.quantity} style={{ fontWeight: 'bold' }} /> */}
                            <input
                              className="quantity form-control"
                              type="number"
                              min={1}
                              max={1000000}
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(e, i)}
                              style={{ fontWeight: 'bold' }}
                            />
                              <button className="quantity-plus d-icon-plus" onClick={() => onPlusForSaveForLater(item, i)} />
                            </div>
                          </td>
                          <td className="product-price">
                            <span className="amount">&#8377;{(item.productVariantCombinations.price * item.quantity).toFixed(2)}</span>
                          </td>
                          <td className="product-close">
                            <div className='row' style={{ float: 'left', fontSize: '10px', width: 'max-content' }}>
                              <div className='col-8'>
                                <button type="submit" className="btn btn-md btn-dark btn-rounded btn-outline p-3" style={{ float: 'left', fontSize: '10px', width: 'max-content' }} onClick={() => moveForCart(item, i)} >Move To Cart</button>
                              </div>
                              <div className='col-4 m-auto'>
                                <button className="quantity-plus " style={{ border: '0px', backgroundColor: 'white' }} onClick={() => removeItemFromSaveForLater(item, i)} >
                                  <FontAwesomeIcon className='cross-button' icon={faCircleXmark} style={{ fontSize: 20 }} />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                    )
                    : null}
                </tbody>
              </table>
            </div>
            <div className="col-lg-8 col-md-12 pr-lg-4 d-block d-sm-block d-md-none d-lg-none d-xl-none">
              {saveForLaterItem && saveForLaterItem.length > 0
                ? saveForLaterItem.map((item, i) =>
                    <div key={`saveForLaterForMobile-${item?.id}-${i}`} style={{ marginLeft: '6px', marginRight: '6px' }}>
                      <div className='row' style={{ borderColor: '#80808040', borderStyle: 'solid', borderWidth: '1px', paddingBottom: '8px' }}>
                        <div className="product-close" style={{ textAlign: 'end', position: 'absolute', paddingRight: '12px', paddingTop: '2px' }}>
                          <button className="quantity-plus " style={{ border: '0px', backgroundColor: 'transparent', position: 'relative', zIndex: '1' }} onClick={() => removeItemFromSaveForLater(item, i)} >
                            <FontAwesomeIcon className='cross-button' icon={faCircleXmark} style={{ fontSize: 20 }} />
                          </button>
                        </div>
                        <div className="product-thumbnail col-12">
                          <figure>
                            <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                              <img src={saveForLaterProductImage[i] ? saveForLaterProductImage[i].imageUrl : no_image} width={100} height={100} alt="product" />
                            </a>
                          </figure>
                        </div>
                        <div className="product-name col-8 text-start" key={`product-${item.id}-${i}`}>
                        <BootstrapTooltip title={item.variantsDetails}>
                          <div className="product-name-section">

                            <a onClick={() => { navigate('/product/' + item.product.id, { state: { categoryId: item.categoryId, combinationId: item.productVariantCombinations.id } }) }} style={{ cursor: 'pointer', fontWeight: 'bold' }}
                            >
                              {item.product.name}

                            </a>
                            <p key={`variantsDetails-${item.id}-${i}`}>{item?.variantsDetails ? (item.variantsDetails.length > 15 ? item.variantsDetails.slice(0, 15) + '...' : item.variantsDetails) : null}</p>
                          </div>
                          </BootstrapTooltip>
                          <div className="product-subtotal " key={`subtotal-${item.id}-${i}`}>
                          {item.productVariantCombinations.productVariantDetails && item.productVariantCombinations.productVariantDetails.length > 0
                            ? item.productVariantCombinations.productVariantDetails.map((rs, index) => (
                              <div key={`variant-${item.id}-${index}`}>
                                {rs.attribute}-{rs.attributeValue}<br />
                              </div>
                            ))
                            : null
                          }
                            <span className="amount" key={`amount-${item.id}-${i}`}>{item.price}</span>
                          </div>
                        </div>
                        <div className="product-subtotal col-4 text-end">
                          <h5 className="amount">₹{item.productVariantCombinations.price ? item.productVariantCombinations.price.toFixed(2) : '0'}</h5>
                        </div>
                        <div className="product-quantity col-6">
                          <div className="input-group">
                            <button className="quantity-minus d-icon-minus" onClick={() => onMinusForSaveForLater(item, i)} />
                          {/* <input className="quantity form-control" type="number" min={1} max={1000000} value={item.quantity} style={{ fontWeight: 'bold' }} /> */}
                          <input
                            className="quantity form-control"
                            type="number"
                            min={1}
                            max={1000000}
                            value={item.quantity}
                            onChange={(e) => handleQuantityChange(e, i)}
                            style={{ fontWeight: 'bold' }}
                          />
                            <button className="quantity-plus d-icon-plus" onClick={() => onPlusForSaveForLater(item, i)} />
                          </div>
                        </div>
                        <div className="product-price col-6 text-end">
                          <span className="amount">&#8377;{(item.productVariantCombinations.price * item.quantity).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                )
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
