import Register from "../Component/Register";
import { connect } from 'react-redux';
import { register } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";

const mapStatetoProps = state => ({
    data: state
})

const mapDispatchToProps = dispatch => ({
    registerHandler: async data => {

        try {
            let result = await fetch(environment.SERVER_URL + "/customer/saveCustomer", {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            result = await result.json();
            if (result.success) {
                toast.success("Account has been created", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                dispatch(register(result.data))

            } else {
                toast.error(result.responseMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            return result;
        } catch (error) {
            toast.error("something is wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return error;
        }
    }
})

export default connect(mapStatetoProps, mapDispatchToProps)(Register)