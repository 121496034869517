import AddUpdateDeliveryAddress from "../Component/DeliveryAddress/AddUpdateDeliveryAddress";
import { connect } from 'react-redux';
import { login, userDetail } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { environment } from "../environment";

let auth = null;
let user1 = null;



const mapStatetoProps = state => ({
    
    data: state,
    
})

const mapDispatchToProps = dispatch => ({
 
    
    // MenuHandler:data => dispatch(hederMenu(data))
    AddAddressHandler: async (data, user) => {
        try {
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
             
        }
      
        let result = await fetch(environment.SERVER_URL + "/shippingAddress/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        if (result.success) {
            let shippingAddress = result.data;
            /* User Object From State*/
            
            user1 = user;
            // user1.shippingAddresses = [];
            user1.shippingAddresses = user.shippingAddresses;
            user1.shippingAddresses.push(shippingAddress);
           
            dispatch(userDetail(user1))

        } else {
            toast.error(result.responseMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    },

    UpdateAddressHandler: async (data, user) => {
        try {
        if (localStorage.getItem('authUser')) {
            auth = JSON.parse(localStorage.getItem('authUser'));
          

        }
       
        let result = await fetch(environment.SERVER_URL + "/shippingAddress/update", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        if (result.success) {
            let shippingAddress = result.data;
            /* User Object From State*/
            
            user1 = user;
            // user1.shippingAddresses = [];
            user1.shippingAddresses = user.shippingAddresses;
            if (user1.shippingAddresses && user1.shippingAddresses.length > 0) {
                user1.shippingAddresses.forEach((res) => {
                  
                    if (res.id == shippingAddress.id) {
                        res.firsName = shippingAddress.firsName;
                        res.lastName = shippingAddress.lastName;
                        res.address = shippingAddress.address;
                        res.city = shippingAddress.city;
                        res.pincode = shippingAddress.pincode;
                        res.state = shippingAddress.state;
                        res.mobileNo = shippingAddress.mobileNo;
                        res.country = shippingAddress.country;
                        res.landmark = shippingAddress.landmark;
                    
                       
                    }
                })
            }
            dispatch(userDetail(user1))

        } else {
            toast.error(result.responseMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    }
})


export default connect(mapStatetoProps, mapDispatchToProps)(AddUpdateDeliveryAddress)