import React, { useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import no_image from "../../image/no_image_739X1000.jpg";
import { saveWishList, fetchData } from "../../Component/SupplierServices"
import { WishListCountContext,AuthUserContext } from '../../Component/CartContext';
import { environment } from '../../environment';
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Context } from "../../Component/LoadingContext";
import LoginContainer from '../../Containers/LoginContainer';
export default function ListingProduct(props) {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const [checked, setChecked] = useState(false);
    const [showLoginModal, setShowLoginModal] = React.useState(false);
    const navigate = useNavigate();
    const { wishListCount, setWishListCount } = useContext(WishListCountContext);
    const [loading, setLoading] = useContext(Context);
    const [productImage, setProductImage] = useState('');
    const handleLoginModalClose = () => setShowLoginModal(false);
    const handleLoginModalShow = () => setShowLoginModal(true);
    const handleLoginSuccess = () => {
        setShowLoginModal(false); // Close the login modal
       // window.location.reload(); // Reload the page
    };
    let auth = null;
if (localStorage.getItem('authUser')) {
  auth = JSON.parse(localStorage.getItem('authUser'));
}
    const handleCheckboxChange = async (res) => {
        if(auth && auth.referenceId){
            setLoading(true);
            let obj = {
                quantity: 1,
                isActive: true,
                isDeleted: false,
                isWishList: !props.product.isWishList,
                customer: { id: auth.referenceId },
                product: { id: res?.id },
                productVariantCombinations: { id: res?.defaultVariantId }
            };
            const response = await saveWishList('cart/wishListProduct/save', obj);
            if (response.success && response.data) {
                props.product.isWishList = !props.product.isWishList;
                props.onUpdateProduct(props.product);
                setLoading(false);
            } else {
                props.product.isWishList = !props.product.isWishList;
                props.onUpdateProduct(props.product);
                setLoading(false);
            }
            setLoading(true);
            const wishListResponse = await fetchData('cart/getWishListByCustomer/' + auth.referenceId);
            if (wishListResponse.success && wishListResponse.data) {
                setLoading(false);
                setWishListCount(wishListResponse?.data?.length);
            }else{
                setLoading(false);
            }
        }else{
            handleLoginModalShow();
        }
       
    };
    useEffect(() => {
        const fetchProductImages = async () => {
            try {
            const response = await fetch(
                `${environment.SERVER_URL}/fileDownload/getProductImage/${props?.product?.id}`
            );
            const blob = await response.blob();
            if (blob.type !== "application/json") {
                const imageUrl = URL.createObjectURL(blob);
                setProductImage(imageUrl);
            }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setProductImage(no_image);
                return error; // Rethrow the error to handle it in the calling code
            }    
        };
        fetchProductImages();
    }, []);


    return (
        <div>
            <div key={props?.product?.id} className="product text-center">
                <figure className="product-media">
                    <a onClick={() => { navigate('/product/' + props?.product?.id, { state: { categoryId: props?.product?.categoryId } }) }} style={{ cursor: 'pointer' }}>

                        <img
                            src={productImage ? productImage : no_image}
                            alt={props?.product?.name}
                            width={280}
                            height={315}
                            style={{ backgroundColor: productImage ? '#f2f3f5' : 'var(--bs-border-color)' }}
                            onError={(e) => {
                                e.target.src = no_image; // Set the default image source if the image fails to load
                            }}
                        />
                    </a>
                    <div className="product-action-vertical">
                        {/* <a className="btn-product-icon btn-wishlist" style={{ cursor: 'pointer' }} title="Add to wishlist"  onClick={(event) => handleCheckboxChange(props?.product)}>
                            <div className="container">
                                <label className="heart-checkbox mb-0">
                                    <input style={{ cursor: 'pointer' }}
                                        type="checkbox"
                                        checked={props?.product?.isWishList ? checked : false} // Ensure to set checked to false when isWishList is false
                                        onChange={(event) => handleCheckboxChange(props?.product)} // Pass the event object to handleCheckboxChange
                                    />
                                    <FontAwesomeIcon style={{ cursor: 'pointer' }}
                                        icon={faHeart}
                                        color={props?.product?.isWishList ? 'red' : '#ccc'} // Use res.isWishList to determine the color
                                    />

                                </label>
                            </div>
                        </a> */}
                        <div onClick={(event) => handleCheckboxChange(props?.product)} className="btn-product-icon btn-wishlist" style={{ padding: '10px 0px', cursor: 'pointer' }} title="Add to wishlist">
                            {/* <i className="d-icon-heart" /> */}
                            <div className="container">
                                <label className="heart-checkbox mb-0">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={faHeart}
                                        color={props?.product?.isWishList ? 'red' : '#ccc'}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="product-action">
                        <a onClick={() => { navigate('/product/' + props?.product?.id, { state: { categoryId: props?.product?.categoryId } }) }} className="btn-product btn-quickview" title="Quick View">Quick View</a>
                    </div>
                </figure>
                <div className="product-details">
                    <div className="product-cat">
                        <a onClick={() => { navigate('/product/' + props?.product?.id, { state: { categoryId: props?.product?.categoryId } }) }} className='anchorwhite'>{props?.product?.category}</a>
                    </div>
                    <h3 className="product-name">
                        <a onClick={() => { navigate('/product/' + props?.product?.id, { state: { categoryId: props?.product?.categoryId } }) }} className='anchorlightBlack'>{props?.product?.name}</a>
                    </h3>
                    <div className="product-price">
                        <span className="price">₹{props?.product?.price ? props?.product?.price.toFixed(2) : '0'}</span> 
                    </div>
                </div>
            </div>
            <Modal show={showLoginModal} size="md" animation={false} onHide={handleLoginModalClose}>
                <Modal.Header style={{ fontWeight: 'bold', fontSize: '16px' }}><span>Login</span>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleLoginModalClose}></button>
                </Modal.Header>
                <LoginContainer  onLoginSuccess={handleLoginSuccess}/>
            </Modal>
        </div>
    )
}


