import ShopProducts from "../Component/ShopProducts";
import { connect } from 'react-redux';
import { hederMenu, logout, userDetail } from '../Service/Actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";

const mapStatetoProps = state => ({
    data: state
})

let auth = null;
if (localStorage.getItem('authUser')) {
    auth = JSON.parse(localStorage.getItem('authUser'));
}

const mapDispatchToProps = dispatch => ({
    getProductByCategoryId: async data => {
        try {
        let result = await fetch(environment.SERVER_URL +"/product/filterProductsForCustomer/"+data,{
            method: 'get',
            headers: {
                "Authorization": `OEC` + ' ' + auth.token,
                "Content-Type": "application/json"
            },
        });
        result = await result.json();
        return result;
        } catch (error) {
            console.error("Error fetching user data:", error);
            return error; // Rethrow the error to handle it in the calling code
        }
    }
})

export default connect(mapStatetoProps, mapDispatchToProps)(ShopProducts)